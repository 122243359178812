export default {
  bi: {
    inventario: {
      puntoreorden: {
        title: 'Punto de reorden',
      },
    },
  },
  contabilidad: {
    maestro: {
      actividadEconomica: {
        title: 'Actividades económicas',
      },
      activo: {
        title: 'Activos',
        deleteTitle: 'Eliminar activo',
        deleteMessage: '¿Está seguro de eliminar el activo?',
        createTitle: 'Creación de activo',
        updateTitle: 'Actualización de activo',
        detailTitle1: 'Existencias',
        detailTitle2: 'Imágenes',
        detailTitle3: 'Archivos adjuntos',
      },
      activoExistencia: {
        deleteTitle: 'Eliminar existencia?',
        deleteMessage: '¿Seguro desea eliminar la existencia de este activo?',
      },
      categoriaActivo: {
        title: 'Categorías activos',
        createTitle: 'Creación categoría activo',
        updateTitle: 'Actualización categoría activo',
        deleteTitle: 'Eliminar categoría activo',
        deleteMessage: '¿Seguro desea eliminar la categoría activo?',
      },
      centroCosto: {
        title: 'Centros de costos',
        createTitle: 'Creación centros de costos',
        updateTitle: 'Actualización centros de costos',
        deleteTitle: 'Eliminar centro de costo',
        deleteMessage: '¿Seguro desea eliminar el centro de costo?',
      },
      centroNegocio: {
        title: 'Centros de negocios',
      },
      clasificacionTributariaProducto: {
        title: 'Clasificación tributaria producto',
        createTitle: 'Creación clasificacion Tributaria Producto',
        updateTitle: 'Actualización clasificación tributaria producto',
        deleteTitle: 'Eliminar clasificación tributaria producto',
        deleteMessage: '¿Seguro desea eliminar la clasificación tributaria producto?',
        impuesto: {
          title: 'impuesto',
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
        retencion: {
          title: 'retencion',
          deleteTitle: 'Eliminar retencion',
          deleteMessage: '¿Seguro desea eliminar el retencion?',
        }
      },
      clasificacionTributariaGasto: {
        title: 'Clasificación tributaria gasto',
        createTitle: 'Creación clasificacion Tributaria gasto',
        updateTitle: 'Actualización clasificación tributaria gasto',
        deleteTitle: 'Eliminar clasificación tributaria gasto',
        deleteMessage: '¿Seguro desea eliminar la clasificacrón tributaria gasto?',
        impuesto: {
          title: 'impuesto',
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
        retencion: {
          title: 'retencion',
          deleteTitle: 'Eliminar retencion',
          deleteMessage: '¿Seguro desea eliminar el retencion?',
        }
      },
      clasificacionTributariaServicio: {
        title: 'Clasificación tributaria servicio',
        createTitle: 'Creación clasificacion Tributaria servicio',
        updateTitle: 'Actualización clasificación tributaria servicio',
        deleteTitle: 'Eliminar clasificación tributaria servicio',
        deleteMessage: '¿Seguro desea eliminar la clasificacrón tributaria servicio?',
        impuesto: {
          title: 'impuesto',
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
        retencion: {
          title: 'retencion',
          deleteTitle: 'Eliminar retencion',
          deleteMessage: '¿Seguro desea eliminar el retencion?',
        }
      },
      clasificacionTributariaActivo: {
        title: 'Clasificación tributaria activo',
        createTitle: 'Creación clasificación tributaria activo',
        updateTitle: 'Actualización clasificación tributaria activo',
        deleteTitle: 'Eliminar clasificación tributaria activo',
        deleteMessage: '¿Seguro desea eliminar la clasificación tributaria activo?',
        detailTitle1: 'Retenciones',
        detailTitle2: 'Impuestos',
        retenciones: {
          title: 'Retenciones',
          createTitle: 'Seleccionar retenciones',
          deleteTitle: 'Eliminar retención',
          deleteMessage: '¿Seguro desea eliminar la retención?',
        },
        impuestos: {
          title: 'Impuestos',
          createTitle: 'Seleccionar impuestos',
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
      },
      grupoUnidadNegocio: {
        title: 'Grupos unidades de negocios',
      },
      unidadNegocio: {
        title: 'Unidades de negocios',
      },
      configuracionEstadoFinanciero: {
        title: 'Configuración de estados financieros',
      },
      consecutivoContabilidad: {
        title: 'Consecutivo contabilidad',
      },
      clasificacionCuentaContable: {
        title: 'Clasificación cuenta contable',
      },
      clasificacionesJuridicas: {
        title: 'Clasificaciones jurídicas',
      },
      cuentasContables: {
        title: 'Catálogos de cuentas',
        createTitle: 'Creación de catálogo de cuenta',
        updateTitle: 'Actualización de catálogo de cuenta',
        deleteTitle: 'Eliminar catálogo de cuenta',
        deleteMessage: '¿Está seguro de eliminar este catálogo de cuenta?',
      },
      documentoContabilidad: {
        title: 'Transacciones Contabilidad',
      },
      configuracionContable: {
        configuracion: {
          title: 'Configuración contable empresa',
          upImagen: 'Subir imagen',
          warningTitle: 'No se ha definido el logo',
        },
        facturaDian: {
          title: 'Facturación DIAN',
          createTitle: 'Creación facturación DIAN',
          updateTitle: 'Actualización facturación DIAN',
          deleteTitle: 'Eliminar facturación DIAN',
          deleteMessage: '¿Seguro desea eliminar la facturación DIAN?',
        },
        contribucionParafiscal: {
          title: 'Contribuciones parafiscales',
          createTitle: 'Creación contribución parafiscal',
          updateTitle: 'Actualización contribución parafiscal',
          deleteTitle: 'Eliminar contribución parafiscal',
          deleteMessage: '¿Seguro desea eliminar la contribución parafiscal?',
        },
      },
      responsabilidadTributaria: {
        title: 'Responsabilidades tributarias',
        createTitle: 'Creación responsabilidad tributaria',
        updateTitle: 'Actualización responsabilidad tributaria',
        deleteTitle: 'Eliminar responsabilidad tributaria',
        deleteMessage: '¿Seguro desea eliminar la responsabilidad tributaria?',
        detailTitle1: 'Impuestos',
        detailTitle2: 'Retenciones',
      },
      responsabilidadesFiscales: {
        title: 'Responsabilidades fiscales',
      },
      rubros: {
        title: 'Rubros',
      },
      tipoActivo: {
        title: 'Tipos activos',
        createTitle: 'Creación tipo activo',
        updateTitle: 'Actualización tipo activo',
        deleteTitle: 'Eliminar tipo activo',
        deleteMessage: '¿Seguro desea eliminar el tipo activo?',
      },
      librosContables: {
        title: 'Libros Contables',
        createTitle: 'Creación libro contable',
        updateTitle: 'Actualización libro contable',
        deleteTitle: 'Eliminar libro contable',
        deleteMessage: '¿Seguro desea eliminar el libro contable?',
      },
      Uvt: {
        title: 'Uvt',
        createTitle: 'Creación uvt',
        updateTitle: 'Actualización uvt',
        deleteTitle: 'Eliminar uvt',
        deleteMessage: '¿Seguro desea eliminar la uvt?',
      },
      retenciones: {
        title: 'Retenciones',
        createTitle: 'Creación retención',
        updateTitle: 'Actualización retención',
        deleteTitle: 'Eliminar retención',
        deleteMessage: '¿Seguro desea eliminar la retención?',
        detailTitle1: 'Alcance',
        responsabilidadesTributarias: {
          title: 'Responsabilidades tributarias',
          createTitle: 'Seleccionar responsabilidades tributarias',
          updateTitle: 'Actualización responsabilidad tributaria',
          deleteTitle: 'Eliminar responsabilidad tributaria',
          deleteMessage: '¿Seguro desea eliminar la responsabilidad tributaria?',
        },
      },
      impuestos: {
        title: 'Impuestos',
        createTitle: 'Creación impuesto',
        updateTitle: 'Actualización impuesto',
        deleteTitle: 'Eliminar impuesto',
        deleteMessage: '¿Seguro desea eliminar el impuesto?',
        detailTitle1: 'Alcance',
        responsabilidadesTributarias: {
          title: 'Responsabilidades tributarias',
          createTitle: 'Seleccionar responsabilidades tributarias',
          updateTitle: 'Actualización responsabilidad tributaria',
          deleteTitle: 'Eliminar responsabilidad tributaria',
          deleteMessage: '¿Seguro desea eliminar la responsabilidad tributaria?',
        },
      },
    },
    procesos: {
      movimientoContable: {
        title: 'Movimiento Contable',
        tableTitle: 'Listado de movimiento contable',
        updateTitle: 'Actualización movimiento contable',
        detailTitle1: 'Cuentas',
      },
      ajustesContables: {
        title: 'Ajustes contables',
        createTitle: 'Creación ajuste contable',
        updateTitle: 'Actualización ajuste contable',
        deleteMessage: '¿Seguro desea eliminar el ajuste contable?',
        deleteTitle: 'Eliminar ajuste contable',
      },
      trasladoFondos: {
        title: 'Traslado de fondos',
        createTitle: 'Creación traslado de fondo',
        updateTitle: 'Actualización traslado de fondo',
        deleteMessage: '¿Seguro desea eliminar el traslado de fondo?',
        deleteTitle: 'Eliminar traslado de fondo',
      },
      movimientoContableCuenta: {
        title: 'Movimiento Contable Cuenta',
        tableTitle: 'Listado de las cuentas de los movimientos contables',
        updateTitle: 'Actualización de cuentas del movimiento contable',
      },
      saldoContable: {
        title: 'Saldo Contable',
        tableTitle: 'Listado de saldo contable',
        updateTitle: 'Actualización saldo contable',
      },
      certificadosRetencion: {
        title: 'Certificados retenciones',
        modalTitle: 'Retenciónes',
      },
    },
  },
  crm: {
    maestros: {
      clasificacionCliente: {
        title: 'Clasificaciones clientes',
        createTitle: 'Creación clasificación cliente',
        updateTitle: 'Actualización clasificación cliente',
        deleteTitle: 'Eliminar clasificación cliente',
        deleteMessage: '¿Seguro desea eliminar la clasificación cliente?',
      },
      cliente: {
        title: 'Clientes',
        createTitle: 'Creación cliente',
        updateTitle: 'Actualización cliente',
        deleteTitle: 'Eliminar cliente',
        deleteMessage: '¿Seguro desea eliminar el cliente?',
        titleList: 'Listado clientes',
        detailTitle1: 'Contactos',
        detailTitle2: 'Sucursales',
        detailTitle3: 'Atributos comerciales',
        detailTitle4: 'Actividades económicas',
        detailTitle5: 'Documentos',
        detailTitle6: 'Archivos adjuntos',
        detailTitle7: 'Portal',
        detailTitle8: 'Reglas',
        detailTitle9: 'Conceptos tributarios',
        detailTitle10: 'Notificaciones',
        detailTitle11: 'Habeas Data',
        detailTitle12: 'Consultas',
        detailTitle13: 'Financiero',
        detailTitle14: 'Listas de precios',
        detailTitle15: 'Empleados',
        detailTitle16: 'Impuestos',
        detailTitle17: 'Retenciones',
        detailTitle18: 'Calendario trabajo',
        detalTitleCuentaCorrienteFacturas: 'Facturas',
        detalTitleCuentaCorrienteNotaDebito: 'Notas débito',
        detalTitleCuentaCorrienteNotaCredito: 'Notas crédito',
        detalTitleCuentaCorrienteRemisiones: 'Remisiones',
        detailTitleEstadoCuenta: 'Estado de cuenta',
        detalTitleCuentaCorrienteComprobantes: {
          title: 'Comprobantes contables',
          anticipos: 'Anticipos',
          pagoFacturas: 'Pago facturas',
          otrosIngresos: 'Otros ingresos',
        },
        impuestos: {
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
        retenciones: {
          deleteTitle: 'Eliminar retención',
          deleteMessage: '¿Seguro desea eliminar la retención?',
        },
        calendarioTrabajo: {
          createTitle: 'Creación calendario de trabajo',
          updateTitle: 'Actualización calendario de trabajo',
          deleteTitle: 'Eliminar calendario de trabajo',
          deleteMessage: '¿Seguro desea eliminar el calendario de trabajo?',
        },
      },
      consecutivoCrm: {
        title: 'Consecutivos Crm',
        createTitle: 'Creación consecutivo crm',
        updateTitle: 'Actualización consecutivo crm',
        deleteTitle: 'Eliminar consecutivo crm',
        deleteMessage: '¿Seguro desea eliminar el consecutivo crm?',
      },
      documentoCrm: {
        title: 'Transacciones Crm',
        createTitle: 'Creación transacción crm',
        updateTitle: 'Actualización transacción crm',
        deleteTitle: 'Eliminar transacción crm',
        deleteMessage: '¿Seguro desea eliminar la transacción crm?',
      },
      habeasData: {
        title: 'Habeas data',
        createTitle: 'Creación habeas data',
        updateTitle: 'Actualización habeas data',
        deleteTitle: 'Eliminar habeas data',
        deleteMessage: '¿Seguro desea eliminar el habeas data?',
      },
      tiposCotizaciones: {
        title: 'Tipos cotización',
        createTitle: 'Creación tipo cotización',
        updateTitle: 'Actualización tipo cotización',
        deleteTitle: 'Eliminar tipo cotización',
        deleteMessage: '¿Seguro desea eliminar el tipo cotización?',
      },
      clasificacionCotizacion: {
        title: 'Clasificación cotización',
        createTitle: 'Creación clasificación cotización',
        updateTitle: 'Actualización clasificación cotización',
        deleteTitle: 'Eliminar clasificación cotización',
        deleteMessage: '¿Seguro desea eliminar la clasificación cotización?',
      },
      medioComunicacionCliente: {
        title: 'Medios comunicación cliente',
        createTitle: 'Creación medio comunicación cliente',
        updateTitle: 'Actualización medio comunicación cliente',
        deleteTitle: 'Eliminar medio comunicación cliente',
        deleteMessage: '¿Seguro desea eliminar el medio comunicación cliente?',
      },
      clienteAtributoComercial: {
        title: 'Atributos comerciales',
        tableTitle: 'Formulario de atributos comerciales',
        deleteTitle: 'Eliminar atributo comercial',
        deleteMessage: '¿Seguro desea eliminar el atributo comercial?',
        createTitle: 'Seleccionar atributos comerciales',
        updateTitle: 'Actualizar atributo',
        warningTitle: 'No se han encontrado atributos comerciales para este formulario',
      },
      clienteContacto: {
        title: 'Contactos',
        createTitle: 'Creación contacto',
        updateTitle: 'Actualización contacto',
        deleteTitle: 'Eliminar contacto',
        deleteMessage: '¿Seguro desea eliminar el contacto?',
      },
      clienteDocumento: {
        deleteTitle: 'Eliminar documento',
        deleteMessage: '¿Está seguro de eliminar este registro?',
        createTitle: 'Creación documento',
        updateTitle: 'Actualización documento',
      },
      clienteHabeasData: {
        createTitle: 'Seleccione un ítem de la lista',
        deleteTitle: 'Seleccione un ítem para eliminar.',
        infoTitle: 'No hay nigún ítem seleccionado aún.',
      },
      clienteSucursal: {
        title: 'Sucursales',
        createTitle: 'Creación sucursal',
        updateTitle: 'Actualización sucursal',
        deleteTitle: 'Eliminar sucursal',
        deleteMessage: '¿Seguro desea eliminar la sucursal?',
      },
      clienteEmpleado: {
        title: 'Empleados',
        deleteTitle: 'Eliminar empleado',
        deleteMessage: '¿Seguro desea eliminar el empleado?',
        createTitle: 'Creación empleado',
        updateTitle: 'Actualización empleado',
        createEmpleado: 'Agregar empleado',
      },
      listaPrecios: {
        title: 'Listas de precios',
        createTitle: 'Creación lista de precios',
        deleteTitle: 'Eliminar lista de precios',
        deleteMessage: '¿Seguro desea eliminar la lista de precios?',
        updateTitle: 'Actualización lista de precios',
        duplicateTitle: 'Duplicar lista de precios',
        duplicateMessage: '¿Seguro desea duplicar la lista de precios?',
        changeState: 'Cambio de estado',
        detailTitle1: 'Productos',
        detailTitle2: 'Servicios',
        detailTitle3: 'Productos Compuestos',
        messageInfo: 'La fecha de vigencia ha caducado',
        createListaPrecio: 'Seleccionar listas de precios',
      },
      listasPreciosProductos: {
        title: 'Productos',
        createTitle: 'Seleccionar productos',
        updateTitle: 'Actualización de producto',
        deleteTitle: 'Eliminar producto',
        deleteMessage: '¿Seguro desea eliminar el producto?',
      },
      listasPreciosServicios: {
        title: 'Servicios',
        createTitle: 'Seleccionar servicios',
        updateTitle: 'Actualización de servicio',
        deleteTitle: 'Eliminar servicio',
        deleteMessage: '¿Seguro desea eliminar el servicio?',
      },
      prospectos: {
        title: 'Prospectos',
        createTitle: 'Creación prospecto',
        updateTitle: 'Actualización prospecto',
        deleteTitle: 'Eliminar prospecto',
        deleteMessage: '¿Segura desea eliminar el prospecto?',
      },
      temporada: {
        title: 'Temporadas',
        tableTitle: 'Listado de temporadas',
        createTitle: 'Creación de temporada',
        deleteTitle: 'Eliminar temporada',
        deleteMessage: '¿Está seguro de eliminar esta temporada?',
        updateTitle: 'Actualización de temporada',
      },
      tiposListasPrecios: {
        title: 'Tipos listas de precios',
        createTitle: 'Creación tipo lista de precios',
        updateTitle: 'Actualización tipo lista de precios',
        deleteTitle: 'Eliminar tipo lista de precios',
        deleteMessage: '¿Seguro desea eliminar el tipo lista de precios?',
      },
    },
    procesos: {
      cotizacion: {
        title: 'Cotizaciones',
        tableTitle: 'Listado de cotizaciones',
        createTitle: 'Creación de cotización',
        deleteTitle: 'Eliminar cotización',
        deleteMessage: '¿Está seguro de eliminar la cotización?',
        updateTitle: 'Actualización de cotización',
        tableProductoTitle: 'Listado de productos',
        tableProductoCreate: 'Agregar productos',
        tableProductoUpdate: 'Modificar producto',
        tableProductoDeleteTitle: 'Eliminar producto',
        tableProductoDeleteMessage: '¿Está seguro de eliminar este producto?',
        tableTipoPagoClienteTitle: 'Listado de tipos pagos cliente',
        tableTipoPagoClienteCreate: 'Agregar tipo pago cliente',
        tableTipoPagoClienteUpdate: 'Modificar tipo pago cliente',
        tableTipoPagoClienteDeleteTitle: 'Eliminar tipo pago cliente',
        tableTipoPagoClienteDeleteMessage: '¿Está seguro de eliminar este tipo pago cliente?',
        tableTipoPagoProveedorTitle: 'Listado de tipos pagos proveedor',
        tableTipoPagoProveedorCreate: 'Agregar tipo pago proveedor',
        tableTipoPagoProveedorUpdate: 'Modificar tipo pago proveedor',
        tableTipoPagoProveedorDeleteTitle: 'Eliminar tipo pago proveedor',
        tableTipoPagoProveedorDeleteMessage: '¿Está seguro de eliminar este tipo pago proveedor?',
        detailTitle1: 'Productos',
        detailTitle2: 'Productos nuevos',
        detailTitle3: 'Archivos adjuntos',
        detailTitle4: 'Tipo pago cliente',
        detailTitle5: 'Tipo pago proveedor',
        servicios: {
          tabTitle: 'Servicios',
          createTitle: 'Seleccionar servicios',
          updateTitle: 'Actualizar servicio',
          deleteTitle: 'Eliminar servicio',
          deleteMessage: '¿Seguro desea eliminar el servicio?',
        }
      },
      cotizacionComercioExterior: {
        title: 'Cotización comercio exterior',
        tableTitle: 'Listado de cotizaciones comercio exterior',
        updateTitle: 'Actualización de cotización comercio exterior',
        detailTitle1: 'Productos',
        detailTitle2: 'Gastos',
        detailTitle3: 'Tipo pago cliente',
        detailTitle4: 'Tipo pago proveedor',
      },
      gastoCotizacionComercioExt: {
        title: 'Gastos',
        createTitle: 'Creación de gasto',
        updateTitle: 'Actualización de gasto',
        deleteTitle: 'Eliminar gasto',
        deleteMessage: '¿Está seguro de eliminar este gasto?',
      },
    },
  },
  general: {
    calendarioTrabajo: {
      createTitle: 'Creación calendario de trabajo',
      updateTitle: 'Actualización calendario de trabajo',
      deleteTitle: 'Eliminar calendario de trabajo',
      deleteMessage: '¿Seguro desea eliminar el calendario de trabajo?',
    },
    sedesEmpresa: {
      title: 'Sedes empresa',
      createTitle: 'Creación sede empresa',
      updateTitle: 'Actualización sede empresa',
      deleteTitle: 'Eliminar sede empresa',
      deleteMessage: '¿Seguro desea eliminar la sede empresa?',
    },
    configuracion: {
      configuracion: {
        title: 'Configuración Empresa',
        upImagen: 'Subir imagen',
        warningTitle: 'No se ha definido el logo',
      },
      sedesEmpresa: {
        title: 'Sedes empresa',
        createTitle: 'Creación sede empresa',
        updateTitle: 'Actualización sede empresa',
        deleteTitle: 'Eliminar sede empresa',
        deleteMessage: '¿Seguro desea eliminar la sede empresa?',
        calendario: {
          title: 'Calendario',
          createTitle: 'Creación calendario',
          deleteTitle: 'Eliminar calendario',
          deleteMessage: '¿Seguro desea eliminar el calendario?',
        },
      },
    },
    maestros: {
      atributos: {
        atributosGenerales: {
          atributos: {
            title: 'Atributos generales',
            tableTitle: 'Listado de atributos generales',
            createTitle: 'Creación atributo general',
            deleteTitle: 'Eliminar modelo',
            deleteMessage: '¿Seguro desea eliminar el modelo?',
            updateTitle: 'Actualización atributo general',
            messageInfo: 'Para continuar con este proceso, se debe tener al menos un modelo relacionado.',
          },
          etiquetas: {
            title: 'Etiquetas',
            createTitle: 'Creación etiqueta',
            updateTitle: 'Actualización etiqueta',
            deleteTitle: 'Eliminar etiqueta',
            deleteMessage: '¿Seguro desea eliminar la etiqueta?',
          },
        },
      },
      divisionPolitica: {
        ciudad: {
          title: 'Ciudades',
          createTitle: 'Creación ciudad',
          updateTitle: 'Actualización ciudad',
          deleteTitle: 'Eliminar ciudad',
          deleteMessage: '¿Seguro desea eliminar la ciudad?',
        },
        departamento: {
          title: 'Ciudades',
          createTitle: 'Creación ciudad',
          updateTitle: 'Actualización ciudad',
          deleteTitle: 'Eliminar ciudad',
          deleteMessage: '¿Seguro desea eliminar la ciudad?',
        },
        pais: {
          title: 'Países',
          createTitle: 'Creación país',
          updateTitle: 'Actualización país',
          deleteTitle: 'Eliminar país',
          deleteMessage: '¿Seguro desea eliminar el país?',
        },
      },
      documentosElectronicos: {
        title: 'Documentos electrónicos de intercambio',
      },
      encuestas: {
        title: 'Encuestas',
        tableTitle: 'Listado encuestas',
        createTitle: 'Creación encuesta',
        deleteTitle: 'Eliminar encuesta',
        deleteMessage: '¿Seguro desea eliminar la encuesta?',
        updateTitle: 'Actualización encuesta',
        detailTitle1: 'Preguntas',
        messageInfo: 'Se debe tener al menos un MODELO relacionado. Para continuar con este proceso.',
      },
      encuestasPreguntas: {
        title: 'Preguntas',
        tableTitle: 'Listado preguntas',
        createTitle: 'Creación pregunta',
        updateTitle: 'Actualización pregunta',
        deleteTitle: 'Eliminar pregunta',
        deleteMessage: '¿Seguro desea eliminar la pregunta?',
      },
      notificaciones: {
        title: 'Notificaciones procesos',
        tableTitle: 'Listado de notificaciones procesos',
        createTitle: 'Creación notificación proceso',
        deleteTitle: 'Eliminar notificación proceso',
        deleteMessage: '¿Seguro desea eliminar la notificación proceso?',
        updateTitle: 'Actualización notificación proceso',
        detailTitle1: 'Usuarios',
        detailTitle2: 'Roles',
      },
      emailNotifications: {
        title: 'Correos notificaciones',
        tableTitle: 'Listado correos de notificación',
        createTitle: 'Creación correo de notificación',
        deleteTitle: 'Eliminar correo de notificación',
        deleteMessage: '¿Seguro desea eliminar el correo de notificación?',
        updateTitle: 'Actualización correo de notificación',
      },
      imprimibles: {
        title: 'Imprimibles',
        createTitle: 'Creación imprimibles',
        updateTitle: 'Actualización imprimibles',
        deleteTitle: 'Eliminar imprimible',
        deleteMessage: '¿Seguro desea eliminar el imprimible?',
      },
      nomenclatura: {
        title: 'Nomenclaturas',
        createTitle: 'Creación nomenclatura',
        updateTitle: 'Actualización nomenclatura',
        deleteTitle: 'Eliminar nomenclatura',
        deleteMessage: '¿Seguro desea eliminar la nomenclatura?',
      },
      terceroActividadEconomica: {
        deleteDetailTitle: 'Eliminar actividad económica',
        deleteDetailMessage: '¿Seguro desea eliminar la actividad económica?',
        selectedActividades: 'Seleccionar actividades económicas',
      },
      tipoDocumento: {
        title: 'Tipos adjuntos',
        tableTitle: 'Creación tipo adjunto',
        deleteTitle: 'Eliminar tipo adjunto',
        deleteMessage: '¿Seguro desea eliminar el tipo adjunto?',
        deleteTitleModel: 'Eliminar modelo',
        deleteMessageModel: '¿Seguro desea eliminar el modelo?',
        createTitle: 'Creación tipo adjunto',
        updateTitle: 'Actualización tipo adjunto',
        createModel: 'Seleccionar modelos',
        detailTitle1: 'Modelos',
        messageInfo: 'Para continuar con este proceso, se debe tener al menos un modelo relacionado.',
        createTitleModelo: 'Selección de modelos',
      },
      tipoIdentificacion: {
        title: 'Tipos de identificación',
        createTitle: 'Creación tipo de identificación',
        updateTitle: 'Actualización tipo de identificación',
        deleteTitle: 'Eliminar tipo de identificación',
        deleteMessage: '¿Seguro desea eliminar el tipo de identificación?',
      },
      trazabilidad: {
        puntoControl: {
          title: 'Punto de Control',
          tableTitle: 'Listado de puntos de control',
          createTitle: 'Creación de punto de control',
          deleteTitle: 'Eliminar punto de control',
          deleteMessage: '¿Está seguro de eliminar el punto de control?',
          updateTitle: 'Actualización de punto de control',
        },
        masterTrazabilidad: {
          title: 'Master de Trazabilidad',
          tableTitle: 'Listado de master de trazabilidad',
          createTitle: 'Creación de master de trazabilidad',
          deleteTitle: 'Eliminar master de trazabilidad',
          deleteMessage: '¿Está seguro de eliminar el master de trazabilidad?',
          updateTitle: 'Actualización de master de trazabilidad',
        },
      },
      tributarios: {
        clasificacionTributariaItem: {
          title: 'Clasificaciones tributarias por item',
          tableTitle: 'Listado de clasificaciones tributarias por item',
          createTitle: 'Creación de clasificación tributaria de item',
          deleteTitle: 'Eliminar clasificación tributaria item',
          deleteMessage: '¿Está seguro de eliminar esta clasificación?',
          updateTitle: 'Actualización clasificaciones tributarias item',
          tableDetailTitle: 'Listado de conceptos tributarios',
          tableDetailCreate: 'Agregar conceptos tributarios',
          tableDetailUpdate: 'Modificar concepto tributario',
          tableDetailDeleteTitle: 'Eliminar concepto tributario',
          tableDetailDeleteMessage: '¿Está seguro de eliminar este concepto?',
        },
        clasificacionTributariaTercero: {
          title: 'Clasificaciones tributarias',
          tableTitle: 'Listado de clasificaciones tributarias',
          createTitle: 'Creación de clasificación tributaria',
          deleteTitle: 'Eliminar clasificación tributaria',
          deleteMessage: '¿Está seguro de eliminar esta clasificación?',
          updateTitle: 'Actualización de clasificaciones tributarias',
          tableDetailTitle: 'Listado de conceptos tributarios',
          tableDetailCreate: 'Agregar conceptos tributarios',
          tableDetailUpdate: 'Modificar concepto tributario',
          tableDetailDeleteTitle: 'Eliminar concepto tributario',
          tableDetailDeleteMessage: '¿Está seguro de eliminar este concepto?',
        },
        conceptosTributarios: {
          title: 'Conceptos tributarios',
        },
        tramitesConceptosTributarios: {
          title: 'Trámites conceptos tributarios',
          createTitle: 'Creación de registro',
          updateTitle: 'Actualización de registro',
          deleteTitle: 'Eliminar de registro',
          deleteMessage: '¿Está seguro de eliminar este registro?',
        },
      },
    },
    estados: {
      changeTitle: 'Cambio de estado',
      changeMessage: '¿Seguro desea cambiar el estado?',
    },
  },
  hrm: {
    nomina: {
      maestros: {
        prestacionesSociales: {
          title: 'Prestaciones sociales',
          createTitle: 'Creación prestación social',
          updateTitle: 'Actualización prestación social',
          deleteTitle: 'Eliminar prestación social',
          deleteMessage: '¿Seguro desea eliminar la prestación social?',
        },
        conceptosNomina: {
          title: 'Conceptos nómina',
          createTitle: 'Creación concepto nómina',
          updateTitle: 'Actualización concepto nómina',
          deleteTitle: 'Eliminar concepto nómina',
          deleteMessage: '¿Seguro desea eliminar el concepto nómina?',
        },
        salariosMinimosVigentes: {
          title: 'SMLV - AUXILIO DE TRANSPORTE',
          createTitle: 'Creación SMLV - AUXILIO DE TRANSPORTE',
          updateTitle: 'Actualización SMLV - AUXILIO DE TRANSPORTE',
          deleteTitle: 'Eliminar SMLV - AUXILIO DE TRANSPORTE',
          deleteMessage: '¿Seguro desea eliminar el SMLV - AUXILIO DE TRANSPORTE?',
        },
      },
      procesos: {
        liquidacionVacaciones: {
          title: 'Liquidación Vacaciones',
          createTitle: 'Creación liquidación vacaciones',
          updateTitle: 'Actualización liquidación vacaciones',
          deleteTitle: 'Eliminar liquidación vacaciones',
          deleteMessage: '¿seguro desea eliminar la liquidación vacaciones?',
        },
        liquidacionAuxCesantia: {
          title: 'Liquidación auxilio cesantías',
          createTitle: 'Creación liquidación auxilio cesantías',
          updateTitle: 'Actualización liquidación auxilio cesantías',
          deleteTitle: 'Eliminar liquidación auxilio cesantías',
          deleteMessage: '¿seguro desea eliminar la liquidación auxilio cesantías?',
        },
        liquidacionInteresCesantia: {
          title: 'Liquidación interés cesantías',
          createTitle: 'Creación liquidación interés cesantías',
          updateTitle: 'Actualización liquidación interés cesantías',
          deleteTitle: 'Eliminar liquidación interés cesantías',
          deleteMessage: '¿Seguro desea eliminar la liquidación interés cesantías?',
        },
        comprobanteNomina: {
          title: 'Comprobantes nómina',
          colillas: 'Colillas de pago',
          createTitle: 'Creación comprobante de nómina',
          updateTitle: 'Actualización comprobante de nómina',
          deleteTitle: 'Eliminar comprobante de nómina',
          deleteMessage: '¿seguro desea eliminar el comprobante de nómina?',
          preloadDataTitle: 'Se encontró información relacionada a este empleado',
          preloadDataMessage: 'Se encontrarón novedades, en el período laboral indicado. ¿Desea agregarlas?',
          deleteConceptoTitle: 'Eliminar concepto de nomina',
          deleteConceptoMessage: '¿seguro desea eliminar el conepto del comprobante de nomina?',
          deleteDeduccionTitle: 'Eliminar deducción',
          deleteDeduccionMessage: '¿Seguro desea eliminar la deducción del comprobante de nómina?',
          deleteIngresoTitle: 'Eliminar ingreso adicional',
          deleteIngresoMessage: '¿Seguro desea eliminar el ingreso adicional del comprobante de nómina?',
        },
      },
    },
    procesos: {
      gestionTalento: {
        cargos: {
          title: 'Cargos',
          createTitle: 'Creación de cargo',
          updateTitle: 'Actualización de cargo',
          deleteTitle: 'Eliminar cargo',
          deleteMessage: '¿Seguro desea eliminar el cargo?',
          detailTitle1: 'Requisitos de cargo',
          detailTitle2: 'Roles',
          detailTitle3: 'Pruebas selección',
          detailTitle4: 'Exámenes médicos',
          detailTitle5: 'Entrevistas',
          detailTitle6: 'Catálogo de competencias',
          detailTitle7: 'Catálogo de habilidades',
          detailTitle8: 'Manual de funciones',
          detailTitle9: 'Archivos adjuntos',
          requisitoCargo: {
            deleteTitle: 'Eliminar requisito cargo',
            deleteMessage: '¿Seguro desea eliminar el requisito cargo?',
          },
          roles: {
            deleteTitle: 'Eliminar rol',
            deleteMessage: '¿Seguro desea eliminar el rol?',
          },
          pruebasSeleccion: {
            deleteTitle: 'Eliminar prueba selección',
            deleteMessage: '¿Seguro desea eliminar la prueba de selección?',
          },
          examenesMedicos: {
            deleteTitle: 'Eliminar examen médico',
            deleteMessage: '¿Seguro desea eliminar el examen médico?',
          },
          entrevistas: {
            deleteTitle: 'Eliminar entrevista',
            deleteMessage: '¿Seguro desea eliminar la entrevista?',
          },
          catalogoCompetencias: {
            deleteTitle: 'Eliminar catálogo competencia',
            deleteMessage: '¿Seguro desea eliminar el catálogo competencia?',
          },
          catalogoHabilidades: {
            deleteTitle: 'Eliminar catálogo habilidad',
            deleteMessage: '¿Seguro desea eliminar el catálogo habilidad?',
          },
          manualFunciones: {
            createTitle: 'Creación manual de funciones',
            updateTitle: 'Actualización manual de funciones',
            deleteTitle: 'Eliminar manual de funciones',
            deleteMessage: '¿Seguro desea eliminar el manual de funciones?',
          },
        },
        solicitudRecursosHumanos: {
          title: 'Solicitud recursos humanos',
          createTitle: 'Creación solicitud recursos humanos',
          updateTitle: 'Actualización solicitud recursos humanos',
          deleteTitle: 'Eliminar solicitud de recursos humanos',
          deleteMessage: '¿Seguro desea eliminar la solicitud de recursos humanos?',
          detailTitle1: 'Pruebas selección',
          detailTitle2: 'Exámenes médicos',
          detailTitle3: 'Requisitos cargos',
          detailTitle4: 'Catálogo habilidades',
          detailTitle5: 'Catálogo competencia',
          detailTitle6: 'Entrevistas',
          detailTitle7: 'Archivos adjuntos',
          pruebasSeleccion: {
            deleteTitle: 'Eliminar prueba selección',
            deleteMessage: '¿Seguro desea eliminar la prueba de selección?',
          },
          examanesMedicos: {
            deleteTitle: 'Eliminar examen médico',
            deleteMessage: '¿Seguro desea eliminar el examen médico?',
          },
          requisitoCargo: {
            deleteTitle: 'Eliminar requisito cargo',
            deleteMessage: '¿Seguro desea eliminar el requisito de cargo?',
          },
          catalogoHabilidades: {
            deleteTitle: 'Eliminar catálogo habilidades',
            deleteMessage: '¿Seguro desea eliminar el catálogo habilidades?',
          },
          catalogoCompetencias: {
            deleteTitle: 'Eliminar catálogo competencias',
            deleteMessage: '¿Seguro desea eliminar el catálogo de competencias?',
          },
          entrevista: {
            deleteTitle: 'Eliminar entrevista',
            deleteMessage: '¿Seguro desea eliminar la entrevista?',
          },
        },
      },
      liquidacionPrimaServicio: {
        title: 'Liquidación prima de servicios',
        createTitle: 'Creación liquidación prima de servicios',
        updateTitle: 'Actualización liquidación prima de servicios',
        deleteTitle: 'Eliminar liquidación prima de servicios',
        deleteMessage: '¿Seguro desea eliminar la liquidación prima de servicios?',
      },
      programacionTurnos: {
        title: 'Programación turnos',
        createTitle: 'Creación programación turno',
        updateTitle: 'Actualización programación turno',
        deleteTitle: 'Eliminar programación turno',
        deleteMessage: '¿Seguro desea eliminar la programación turno?',
        detailTitle1: 'Restricciones',
        detailTitle2: 'Empleados',
        detailTitle3: 'Turnos',
        detailTitle4: 'Demanda',
        demanda: {
          deleteTitle: 'Eliminar demanda',
          deleteMessage: '¿Seguro desea eliminar la demanda?',
        },
        empleados: {
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
        },
        restricciones: {
          deleteTitle: 'Eliminar restricción',
          deleteMessage: '¿Seguro desea eliminar la restricción?',
        },
        turnos: {
          deleteTitle: 'Eliminar turno',
          deleteMessage: '¿Seguro desea eliminar el turno?',
        },
      },
      reclutamiento: {
        vacantesEmpleos: {
          title: 'Vacantes de empleo',
        },
        programacionEntrevistas: {
          title: 'Programaciones gestión del talento',
          createTitle: 'Creación programación gestión del talento',
          updateTitle: 'Actualización programación gestión del talento',
          deleteTitle: 'Eliminar programación gestión del talento',
          deleteMessage: '¿Seguro desea eliminar la programación gestión del talento?',
        },
      },
      seguimientoYretencion: {
        evaluacionDesempeno: {
          title: 'Evaluación desempeño',
          detailTitle1: 'Habilidad',
          detailTitle2: 'Competencia',
          detailTitle3: 'Desempeño',
          detailTitle4: 'Ambiente laboral',
          detailTitle5: 'Plan de desempeño individual',
        },
        capacitaciones: {
          title: 'Programar capacitaciones',
          createTitle: 'Creación programación de capacitación',
          updateTitle: 'Actualización programación de capacitación',
          deleteTitle: 'Eliminar programación de capacitación',
          deleteMessage: '¿Seguro desea eliminar la programación de capacitación?',
          detailTitle1: 'Capacitadores',
          detailTitle2: 'Asistentes',
          capacitadores: {
            deleteTitle: 'Eliminar capacitador',
            deleteMessage: '¿Seguro desea eliminar el capacitador?',
          },
          asistentes: {
            deleteTitle: 'Eliminar asistente',
            deleteMessage: '¿Seguro desea eliminar el asistente?',
          },
        },
        evaluarCapacitaciones: {
          title: 'Evaluar capacitaciones',
          updateTitle: 'Actualización programación de capacitación',
          detailTitle1: 'Evaluación capacitador',
          detailTitle2: 'Evaluación asistentes',
          detailTitle3: 'Evaluación conocimientos',
        },
        descargos: {
          title: 'Descargos',
          createTitle: 'Creación descargo',
          updateTitle: 'Actualización descargo',
          deleteTitle: 'Eliminar descargo',
          deleteMessage: '¿Seguro desea eliminar el descargo?',
          falta: {
            title: 'Faltas',
            createTitle: 'Seleccionar  faltas',
            updateTitle: 'Actualización falta',
            deleteTitle: 'Eliminar falta',
            deleteMessage: '¿Seguro desea eliminar la falta?',
            deleteFileTitle: 'Eliminar archivo',
            deleteFileMessage: '¿Seguro desea eliminar el archivo?'
          }
        },
        llamadosAtencion: {
          title: 'Llamados de atención',
          createTitle: 'Creación llamado de atención',
          updateTitle: 'Actualización llamado de atención',
          deleteTitle: 'Eliminar llamado de atención',
          deleteMessage: '¿Seguro desea eliminar el llamado de atención?',
          falta: {
            title: 'Faltas',
            createTitle: 'Seleccione las faltas',
            updateTitle: 'Actualización falta',
            deleteTitle: 'Eliminar falta',
            deleteMessage: '¿Seguro desea eliminar la falta?',
            deleteFileTitle: 'Eliminar archivo',
            deleteFileMessage: '¿Seguro desea eliminar el archivo?'
          }
        },
        ordenAprovicionamiento: {
          title: 'Orden aprovisionamiento',
          createTitle: 'Creación orden aprovisionamiento',
          updateTitle: 'Actualización orden aprovisionamiento',
          deleteTitle: 'Eliminar orden aprovisionamiento',
          deleteMessage: '¿Seguro desea eliminar la orden de aprovisionamiento?',
          requisito: {
            title: 'requisito aprovisionamiento',
            deleteTitle: 'Eliminar requisito de aprovisionamiento',
            deleteMessage: '¿Seguro desea eliminar el requisito de aprovisionamiento?',
          },
        },
        procesoDisciplinario: {
          title: 'Proceso disciplinario',
          createTitle: 'Creación proceso disciplinario',
          updateTitle: 'Actualización proceso disciplinario',
          deleteTitle: 'Eliminar proceso disciplinario',
          deleteMessage: '¿Seguro desea eliminar el proceso disciplinario?',
          falta: {
            title: 'Faltas',
          },
          adjuntos: {
            title: 'Documentación proceso disciplinario',
          },
          llamadosAtencion: {
            title: 'Llamado de atención',
            createTitle: 'Creación llamado de atención',
            updateTitle: 'Actualización llamado de atención',
            deleteTitle: 'Eliminar llamado de atención',
            deleteMessage: '¿Seguro desea eliminar el llamado de atención?',
          },
          descargos: {
            title: 'Descargos',
            createTitle: 'Creación de descargo',
            updateTitle: 'Actualización de descargo',
            deleteTitle: 'Eliminar descargo',
            deleteMessage: '¿Seguro desea eliminar el descargo?',
            asistentes: {
              title: 'Asistentes',
              deleteTitle: 'Eliminar asistente',
              deleteMessage: '¿Seguro desea eliminar el asistente?',
            },
            documentos: {
              title: 'Documentacion descargo',
            },
            pruebaEmpleado: {
              title: 'Pruebas empleado',
            },
          },
          suspenciones: {
            title: 'Suspenciones',
            createTitle: 'Creación de suspención',
            updateTitle: 'Actualización de suspención',
            deleteTitle: 'Eliminar suspención',
            deleteMessage: '¿Seguro desea eliminar el suspención?',
          },
        },
        programacionEvaluacion: {
          title: 'Programación evaluación desempeño',
          createTitle: 'Creación programación evaluación desempeño',
          updateTitle: 'Actualización programación evaluación desempeño',
          deleteTitle: 'Eliminar programación evaluación desempeño',
          deleteMessage: '¿Seguro desea eliminar la programación evaluación desempeño?',
          habilidades: {
            title: 'Habilidades',
            createTitle: 'Agregar habilidades',
            deleteTitle: 'Eliminar habilidad',
            deleteMessage: '¿Seguro desea eliminar la habilidad?',
          },
          competencia: {
            title: 'competencias',
            createTitle: 'Agregar competencias',
            deleteTitle: 'Eliminar competencia',
            deleteMessage: '¿Seguro desea eliminar la competencia?',
          },
          desempeno: {
            title: 'Desempeño',
            createTitle: 'Creación desempeño',
            updateTitle: 'Actualización desempeño',
            deleteTitle: 'Eliminar desempeño',
            deleteMessage: '¿Seguro desea eliminar el desempeño?',
          },
          ambiente: {
            title: 'Ambiente laboral',
            createTitle: 'Creación ambiente laboral',
            updateTitle: 'Actualización ambiente laboral',
            deleteTitle: 'Eliminar ambiente laboral',
            deleteMessage: '¿Seguro desea eliminar el ambiente laboral?',
          },
        },
        planDesempeno: {
          title: 'Plan de desempeño individual',
          createTitle: 'Creación plan de desempeño individual',
          updateTitle: 'Actualización plan de desempeño individual',
          deleteTitle: 'Eliminar plan desempeño individual',
          deleteMessage: '¿Seguro desea eliminar el plan de desempeño individual?'
        },
        suspencion: {
          title: 'Suspenciones',
          createTitle: 'Creación suspención',
          updateTitle: 'Actualización suspención',
          deleteTitle: 'Eliminar suspención',
          deleteMessage: '¿Seguro desea eliminar la suspención?',
          trazabilidad: {
            title: 'trazabilidad',
            createTitle: 'Agregar faltas',
            updateTitle: 'Actualizar trazabilidad',
            deleteTitle: 'Eliminar Trazabilidad',
            deleteMessage: '¿Seguro desea eliminar la trazabilidad?',
          }
        },
      },
      gestionTiempo: {
        tareas: {
          asignacionTareas: {
            title: 'Asignación de tareas',
            createTitle: 'Creación asignación de tarea',
            updateTitle: 'Actualización asignación de tarea',
            deleteTitle: 'Eliminar asignación de tarea',
            deleteMessage: '¿Seguro desea eliminar la asignación de tarea?',
            detailTitle1: 'Archivos asignación tarea',
            detailTitle2: 'Archivos gestión tarea',
            detailTitle3: 'Equipo de trabajo',
          },
          gestionTareas: {
            title: 'Gestión de tareas'
          },
        },
        gestionDeTiempo: {
          reporteTiempo: {
            title: 'Reporte de tiempo',
            createTitle: 'Creación reporte de tiempo',
            updateTitle: 'Actualización reporte de tiempo',
            deleteTitle: 'Eliminar reporte de tiempo',
            deleteMessage: '¿Seguro desea eliminar el reporte de tiempo?',
            detailTitle1: 'Archivos adjuntos',
          },
          solicitudModalidad: {
            title: 'Solicitud modalidad de trabajo',
            createTitle: 'Creación solicitud modalidad de trabajo',
            updateTitle: 'Actualización solicitud modalidad de trabajo',
            deleteTitle: 'Eliminar solicitud modalidad de trabajo',
            deleteMessage: '¿Seguro desea eliminar la solicitud modalidad de trabajo?',
          },
        },
      },
      solicitudesPermisos: {
        title: 'Solicitudes permisos',
        createTitle: 'Creación solicitud permiso',
        updateTitle: 'Actualización solicitud permiso',
        deleteTitle: 'Eliminar solicitud permiso',
        deleteMessage: '¿Seguro desea eliminar la solicitud de permiso?',
        detailTitle1: 'Archivos adjuntos',
        detailTitle2: 'Histórico solicitudes permisos',
      },
      informacionReal: {
        title: 'Información real de la solicitud',
      },
      solicitudesVacaciones: {
        title: 'Solicitudes vacaciones',
        createTitle: 'Creación solicitud vacaciones',
        updateTitle: 'Actualización solicitud vacaciones',
        deleteTitle: 'Eliminar solicitud vacaciones',
        deleteMessage: '¿Seguro desea eliminar la solicitud de vacaciones?',
        detailTitle1: 'Archivos adjuntos',
      },
      incapacidadesMedicas: {
        title: 'Incapacidades médicas',
        createTitle: 'Creación incapacidad médica',
        updateTitle: 'Actualización incapacidad médica',
        deleteTitle: 'Eliminar incapacidad médica',
        deleteMessage: '¿Seguro desea eliminar la incapacidad médica?',
        detailTitle1: 'Archivos adjuntos',
      },
      registrosLicencias: {
        title: 'Registros licencias',
        createTitle: 'Creación registro licencia',
        updateTitle: 'Actualización registro licencia',
        deleteTitle: 'Eliminar registro licencia',
        deleteMessage: '¿Seguro desea eliminar el registro licencia?',
      },
      turnos: {
        title: 'Turnos',
        createTitle: 'Creación turno',
        updateTitle: 'Actualización turno',
        deleteTitle: 'Eliminar turno',
        deleteMessage: '¿Seguro desea eliminar el turno?',
      },
      turnosRecesos: {
        title: 'Recesos',
        createTitle: 'Seleccionar recesos',
        updateTitle: 'Actualización de receso',
        deleteTitle: 'Eliminar receso',
        deleteMessage: '¿Seguro desea eliminar el receso?',
      },
      reporteHorasExtras: {
        title: 'Reporte horas extras',
        createTitle: 'Creación reporte hora extra',
        updateTitle: 'Actualización reporte hora extra',
        deleteTitle: 'Eliminar reporte hora extra',
        deleteMessage: '¿Seguro desea eliminar el reporte de hora extra?',
        detailTitle1: 'Comentarios',
        detailTitle2: 'Imágenes',
        detailTitle3: 'Archivos adjuntos',
      },
      beneficios: {
        title: 'Beneficios',
        createTitle: 'Creación beneficio',
        updateTitle: 'Actualización beneficio',
        deleteTitle: 'Eliminar beneficio',
        deleteMessage: '¿Seguro desea eliminar el beneficio?',
      },
      solicitudBeneficios: {
        title: 'Solicitudes beneficios',
        createTitle: 'Creación solicitud beneficio',
        updateTitle: 'Actualización solicitud beneficio',
        deleteTitle: 'Eliminar solicitud beneficio',
        deleteMessage: '¿Seguro desea eliminar la solicitud beneficio?',
        detailTitle1: 'Autorización deducción',
        detailTitle2: 'Encuesta',
        autorizacionDeduccion: {
          deleteTitle: 'Eliminar autorización deducción',
          deleteMessage: '¿Seguro desea eliminar la autorización deducción?',
          deleteFileTitle: 'Eliminar documento',
          deleteFileMessage: '¿Seguro desea eliminar el documento?',
        },
      },
      bonificaciones: {
        title: 'Bonificaciones',
        createTitle: 'Creación Bonificación',
        updateTitle: 'Actualización Bonificación',
        deleteTitle: 'Eliminar Bonificación',
        deleteMessage: '¿Seguro desea eliminar la Bonificación?',
      },
      desvinculacionLaboral: {
        desvinculacionLaboral: {
          title: 'Desvinculación laboral',
          createTitle: 'Creación desvinculación laboral',
          updateTitle: 'Actualización desvinculación laboral',
          deleteTitle: 'Eliminar desvinculación laboral',
          deleteMessage: '¿Seguro desea eliminar la desvinculación laboral?',
          tabTitle1: 'Activos asignados',
          tabTitle2: 'Dotación',
          requisito: {
            title: 'requisito aprovisionamiento',
            deleteTitle: 'Eliminar requisito de aprovisionamiento',
            deleteMessage: '¿Seguro desea eliminar el requisito de aprovisionamiento?',
          },
        },
      },
    },
    maestros: {
      consecutivos: {
        title: 'Consecutivos rrhh',
        createTitle: 'Creación consecutivo rrhh',
        updateTitle: 'Actualización consecutivo rrhh',
        deleteTitle: 'Eliminar consecutivo rrhh',
        deleteMessage: '¿Seguro desea eliminar el consecutivo rrhh?',
      },
      evaluacionCapacitacionEncuesta: {
        title: 'Encuesta de evaluación de capacitación',
        createTitle: 'Creación encuesta de evaluación de capacitación',
        updateTitle: 'Actualización encuesta de evaluación de capacitación',
        deleteTitle: 'Eliminar encuesta de evaluación de capacitación',
        deleteMessage: '¿Seguro desea eliminar la encuesta de evaluación de capacitación?',
        detailTitle1: 'Preguntas',
      },
      documentos: {
        title: 'Transacciones RRHH',
        createTitle: 'Creación transacción rrhh',
        updateTitle: 'Actualización transacción rrhh',
        deleteTitle: 'Eliminar transacción rrhh',
        deleteMessage: '¿Seguro desea eliminar la transacción rrhh?',
      },
      empleados: {
        title: 'Empleados',
        createTitle: 'Creación empleado',
        updateTitle: 'Actualización empleados',
        deleteTitle: 'Eliminar empleado',
        deleteMessage: '¿Seguro desea eliminar el empleado?',
        titleList: 'Listado de empleados',
        detailTitle1: 'Contactos',
        detailTitle2: 'Exclusión clientes',
        detailTitle3: 'Archivos adjuntos',
        detailTitle4: 'Histórico cargos',
        detailTitle5: 'Maestros',
        detailTitle6: 'Exclusión cirujanos',
        detailTitle7: 'Almacenes',
        detailTitle8: 'Soportes cirugías',
        detailTitle9: 'Firmas',
        detailTitle10: 'Información laboral',
        detailTitle11: 'Información de pago',
        detailTitle12: 'Activos asignados',
        detailTitle13: 'Dotación',
        detailTitle14: 'Procesos disciplinarios',
        detailTitle15: 'Llamados de atención',
        detailTitle16: 'Descargos',
        detailTitle17: 'Suspenciones',
        tabClienteCreate: 'Seleccionar clientes',
        tabClienteUpdate: 'Actualización cliente',
        tabClienteDeleteTitle: 'Eliminar cliente',
        tabClienteDeleteMessage: '¿Seguro desea eliminar el cliente?',
        calendarioTrabajo: {
          title: 'Calendario trabajo',
          deleteTitle: 'Eliminar calendario de trabajo',
          deleteMessage: '¿Seguro desea eliminar el calendario de trabajo?',
        },
        dotacion: {
          tile: 'Dotación',
          deleteTitle: 'Eliminar dotación',
          deleteMessage: '¿Seguro desea eliminar esta dotación?',
          deleteFileTitle: 'Eliminar documento',
          deleteFileMessage: '¿Seguro desea eliminar este documento?',
        },
        deducciones: {
          title: 'Deducciones',
          createTitle: 'Seleccionar deducciones',
          deleteTitle: 'Eliminar deducción',
          deleteMessage: '¿Seguro desea eliminar la deducción?',
        },
        sedes: {
          title: 'Sedes',
          deleteTitle: 'Eliminar sede',
          deleteMessage: '¿Seguro desea eliminar la sede?',
        },
      },
      empleadosAlmacenes: {
        title: 'Almacenes',
        deleteTitle: 'Eliminar almacén',
        deleteMessage: '¿Seguro desea elimar el almacén?',
      },
      empleadoContacto: {
        title: 'Contactos',
        createTitle: 'Creación contacto',
        updateTitle: 'Actualización contacto',
        deleteTitle: 'Eliminar contacto',
        deleteMessage: '¿Seguro desea eliminar el contacto?',
      },
      empleadoCirujano: {
        title: 'Cirujanos',
        tabCirujanoCreate: 'Seleccionar cirujanos',
        tabCirujanoUpdate: 'Modificar cirujano',
        tabCirujanoDeleteTitle: 'Eliminar cirujano',
        tabCirujanoDeleteMessage: '¿Seguro desea eliminar el cirujano?',
      },
      empleadoInformacionLaboral: {
        title: 'Información laboral',
        tabTitleCreate: 'Creación información laboral',
        tabTitleUpdate: 'Actualización información laboral',
        tabTitleDelete: 'Eliminar información laboral',
        tabMessageDelete: '¿Seguro desea eliminar la información laboral?',
      },
      empleadoMetodoPago: {
        title: 'Información pago',
        tabTitleCreate: 'Creación información pago',
        tabTitleUpdate: 'Actualización información pago',
        tabTitleDelete: 'Eliminar información pago',
        tabMessageDelete: '¿Seguro desea eliminar la información de pago?',
      },
      cargo: {
        title: 'Cargos',
        createTitle: 'Creación de cargo',
        updateTitle: 'Actualización de cargo',
        deleteTitle: 'Eliminar cargo',
        deleteMessage: '¿Está seguro de eliminar este cargo?',
      },
      clasesRiesgos: {
        title: 'Clases de Riesgo ARL',
        createTitle: 'Creación clase de riesgo ARL',
        updateTitle: 'Actualización clase de riesgo ARL',
        deleteTitle: 'Eliminar clase de riesgo ARL',
        deleteMessage: '¿Seguro desea eliminar la clase de riesgo ARL?',
      },
      altosRiesgos: {
        title: 'Alto riesgo',
        createTitle: 'Creación alto riesgo',
        updateTitle: 'Actualización alto riesgo',
        deleteTitle: 'Eliminar alto riesgo',
        deleteMessage: '¿Seguro desea eliminar el alto riesgo?',
      },
      frecuenciasPagos: {
        title: 'Frecuencia de pagos',
        createTitle: 'Creación frecuencia de pago',
        updateTitle: 'Actualización frecuencia de pago',
        deleteTitle: 'Eliminar frecuencia de pago',
        deleteMessage: '¿Seguro desea eliminar la frecuencia de pago?',
      },
      disciplinario: {
        title: 'Disciplinarios',
        createTitle: 'Creación disciplinario',
        updateTitle: 'Actualización disciplinario',
        deleteTitle: 'Eliminar disciplinario',
        deleteMessage: '¿Seguro desea eliminar el disciplinario?',
        faltas: {
          title: 'faltas',
          deleteTitle: 'Eliminar falta',
          deleteMessage: '¿Seguro desea eliminar la falta?',
        }
      },
      subTiposEmpleados: {
        title: 'Sub Tipo Empleado',
        createTitle: 'Creación sub tipo empleado',
        updateTitle: 'Actualización sub tipo empleado',
        deleteTitle: 'Eliminar sub tipo empleado',
        deleteMessage: '¿Seguro desea eliminar el sub tipo empleado?',
      },
      clasesAportantes: {
        title: 'Clases aportantes',
        createTitle: 'Creación clase aportante',
        updateTitle: 'Actualización clase aportante',
        deleteTitle: 'Eliminar clase aportante',
        deleteMessage: '¿Seguro desea eliminar la clase de aportante?',
      },
      tiposEmpleados: {
        title: 'Tipos Empleados',
        createTitle: 'Creación tipo empleado',
        updateTitle: 'Actualización tipo empleado',
        deleteTitle: 'Eliminar tipo empleado',
        deleteMessage: '¿Seguro desea eliminar el tipo empleado?',
      },
      tiposContratos: {
        title: 'Tipos de contratos',
        createTitle: 'Creación tipo de contrato',
        updateTitle: 'Actualización tipo de contrato',
        deleteTitle: 'Eliminar tipo de contrato',
        deleteMessage: '¿Seguro desea eliminar el tipo de contrato?',
        detailTitle1: 'Prestaciones sociales',
        detailTitle2: 'Retenciones',
      },
      tiposContratosPrestacionesSociales: {
        title: 'Prestaciones sociales',
        createTitle: 'Seleccionar prestaciones sociales',
        deleteTitle: 'Eliminar prestación social',
        deleteMessage: '¿Seguro desea eliminar la prestación social?',
      },
      tiposContratosRetenciones: {
        title: 'Retenciones',
        createTitle: 'Seleccionar retenciones',
        deleteTitle: 'Eliminar retención',
        deleteMessage: '¿Seguro desea eliminar la retención?',
      },
      tiposCotizantes: {
        title: 'Tipos de cotizantes',
        createTitle: 'Creación tipo de cotizante',
        updateTitle: 'Actualización tipo de cotizante',
        deleteTitle: 'Eliminar tipo de cotizante',
        deleteMessage: '¿Seguro desea eliminar el tipo de cotizante?',
      },
      tiposPlanillas: {
        title: 'Tipos de planillas',
        createTitle: 'Creación tipo de planilla',
        updateTitle: 'Actualización tipo de planilla',
        deleteTitle: 'Eliminar tipo de planilla',
        deleteMessage: '¿Seguro desea eliminar el tipo de planilla?',
      },
      responsabilidades: {
        title: 'Responsabilidades',
        createTitle: 'Creación responsabilidad',
        updateTitle: 'Actualización responsabilidad',
        deleteTitle: 'Eliminar responsabilidad',
        deleteMessage: '¿Seguro desea eliminar la responsabilidad?',
      },
      requisitosContratacion: {
        title: 'Requisitos contratación rrhh',
        createTitle: 'Creación requisito contratación rrhh',
        updateTitle: 'Actualización requisito contratación rrhh',
        deleteTitle: 'Eliminar requisito contratación rrhh',
        deleteMessage: '¿Seguro desea eliminar el requisito contratación rrhh?',
      },
      tiposHorasExtras: {
        title: 'Tipo de hora extra',
        createTitle: 'Creación tipo de hora extra',
        updateTitle: 'Actualización tipo de hora extra',
        deleteTitle: 'Eliminar tipo de hora extra',
        deleteMessage: '¿Seguro desea eliminar el tipo de hora extra?',
      },
      tiposPermisos: {
        title: 'Tipos permisos',
        createTitle: 'Creación tipo permiso',
        updateTitle: 'Actualización tipo permiso',
        deleteTitle: 'Eliminar tipo permiso',
        deleteMessage: '¿Seguro desea eliminar el tipo permiso?',
      },
      tiposDeducciones: {
        title: 'Tipos deducciones',
        createTitle: 'Creación tipo deducción',
        updateTitle: 'Actualización tipo deducción',
        deleteTitle: 'Eliminar tipo deducción',
        deleteMessage: '¿Seguro desea eliminar el tipo deducción?',
      },
      tiposBeneficios: {
        title: 'Tipos beneficios',
        createTitle: 'Creación tipo beneficio',
        updateTitle: 'Actualización tipo beneficio',
        deleteTitle: 'Eliminar tipo beneficio',
        deleteMessage: '¿Seguro desea eliminar el tipo beneficio?',
      },
      tiposIncapacidades: {
        title: 'Tipo de incapacidad',
        createTitle: 'Creación tipo de incapacidad',
        updateTitle: 'Actualización tipo de incapacidad',
        deleteTitle: 'Eliminar tipo de incapacidad',
        deleteMessage: '¿Seguro desea eliminar el tipo de incapacidad?',
      },
      tiposBonificaciones: {
        title: 'Tipo de bonificación',
        createTitle: 'Creación tipo de bonificación',
        updateTitle: 'Actualización tipo de bonificación',
        deleteTitle: 'Eliminar tipo de bonificación',
        deleteMessage: '¿Seguro desea eliminar el tipo de bonificación?',
      },
      categoriasBeneficios: {
        title: 'Categorías beneficios',
        createTitle: 'Creación categoría beneficio',
        updateTitle: 'Actualización categoría beneficio',
        deleteTitle: 'Eliminar categoría beneficio',
        deleteMessage: '¿Seguro desea eliminar la categoría beneficio?',
      },
      recesos: {
        title: 'Recesos',
        createTitle: 'Creación receso',
        updateTitle: 'Actualización receso',
        deleteTitle: 'Eliminar receso',
        deleteMessage: '¿Seguro desea eliminar el receso?',
      },
      deducciones: {
        title: 'Deducciones',
        createTitle: 'Creación deducción',
        updateTitle: 'Actualización deducción',
        deleteTitle: 'Eliminar deducción',
        deleteMessage: '¿Seguro desea eliminar la deducción?',
      },
      horasExtras: {
        title: 'Horas extras',
        createTitle: 'Creación hora extra',
        updateTitle: 'Actualización hora extra',
        deleteTitle: 'Eliminar hora extra',
        deleteMessage: '¿Seguro desea eliminar la hora extra?',
      },
      reclutamiento: {
        candidatos: {
          title: 'Candidatos',
          createTitle: 'Creación candidato',
          updateTitle: 'Actualización candidato',
          deleteTitle: 'Eliminar candidato',
          deleteMessage: '¿Seguro desea eliminar el candidato?',
          detailTitle1: 'Hoja de vida',
          detailTitle2: 'Prueba selección',
          detailTitle3: 'Examen médico',
          detailTitle4: 'Requisito cargo',
          detailTitle5: 'Catálogo habilidad',
          detailTitle6: 'Catálogo competencia',
          detailTitle7: 'Entrevista',
        },
        pruebaSeleccion: {
          title: 'Pruebas de selección',
          createTitle: 'Creación prueba de selección',
          updateTitle: 'Actualización prueba de selección',
          deleteTitle: 'Eliminar prueba de selección',
          deleteMessage: '¿Seguro desea eliminar la prueba de selección?',
        },
        examenesMedicos: {
          title: 'Exámenes médicos',
          createTitle: 'Creación examen médico',
          updateTitle: 'Actualización examen médico',
          deleteTitle: 'Eliminar examen médico',
          deleteMessage: '¿Seguro desea eliminar el examen médico?',
        },
        requisitosCargos: {
          title: 'Requisitos cargos',
          createTitle: 'Creación requisito cargo',
          updateTitle: 'Actualización requisito cargo',
          deleteTitle: 'Eliminar requisito cargo',
          deleteMessage: '¿Seguro desea eliminar el requisito cargo?',
        },
        catalogoHabilidades: {
          title: 'Catálogo de habilidades',
          createTitle: 'Creación catálogo de habilidad',
          updateTitle: 'Actualización catálogo de habilidad',
          deleteTitle: 'Eliminar catálogo de habilidad',
          deleteMessage: '¿Seguro desea eliminar el catálogo de habilidad?',
        },
        catalogoCompetencias: {
          title: 'Catálogo de competencias',
          createTitle: 'Creación catálogo de competencia',
          updateTitle: 'Actualización catálogo de competencia',
          deleteTitle: 'Eliminar catálogo de competencia',
          deleteMessage: '¿Seguro desea eliminar el catálogo de competencia?',
        },
        entrevistas: {
          title: 'Entrevistas',
          createTitle: 'Creación entrevista',
          updateTitle: 'Actualización entrevista',
          deleteTitle: 'Eliminar entrevista',
          deleteMessage: '¿Seguro desea eliminar la entrevista?',
        },
      },
      seguimientoYretencion: {
        tiposCapacitaciones: {
          title: 'Tipos capacitaciones',
          createTitle: 'Creación tipo capacitación',
          updateTitle: 'Actualización tipo capacitación',
          deleteTitle: 'Eliminar tipo capacitación',
          deleteMessage: '¿Seguro desea eliminar el tipo capacitación?',
        },
        temasCapacitaciones: {
          title: 'Temas capacitaciones',
          createTitle: 'Creación tema capacitación',
          updateTitle: 'Actualización tema capacitación',
          deleteTitle: 'Eliminar tema capacitación',
          deleteMessage: '¿Seguro desea eliminar el tema de capacitación?',
        },
      },
      gestionTiempo: {
        tareas: {
          equiposTrabajo: {
            title: 'Equipos de trabajo',
            createTitle: 'Creación equipo de trabajo',
            updateTitle: 'Actualización equipo de trabajo',
            deleteTitle: 'Eliminar equipo de trabajo',
            deleteMessage: '¿Seguro desea eliminar el equipo de trabajo?',
            detailTitle1: 'Empleados',
            detailTitle2: 'Archivos adjuntos',
            empleados: {
              tableTitle: 'Empleados',
              tableHelpMessage: 'Agregar a los empleados, que formarán parte de este equipo de trabajo.',
              deleteTitle: 'Eliminar empleado',
              deleteMessage: '¿Seguro desea eliminar el empleado?',
            },
          },
          hitos: {
            title: 'Hitos',
            createTitle: 'Creación hito',
            updateTitle: 'Actualización hito',
            deleteTitle: 'Eliminar hito',
            deleteMessage: '¿Seguro desea eliminar el hito?',
          },
          proyectos: {
            title: 'Proyectos',
            createTitle: 'Creación proyecto',
            updateTitle: 'Actualización proyecto',
            deleteTitle: 'Eliminar proyecto',
            deleteMessage: '¿Seguro desea eliminar el proyecto?',
            detailTitle1: 'Miembros',
            miembros: {
              tableTitle: 'Miembros',
              tableHelpMessage: 'Agregar a los empleados, que formarán parte de este proyecto.',
              deleteTitle: 'Eliminar miembro',
              deleteMessage: '¿Seguro desea eliminar el miembro?',
            },
          },
        },
        gestionDeTiempo: {
          tiposTurnos: {
            title: 'Tipos turnos',
            createTitle: 'Creación tipo de turno',
            updateTitle: 'Actualización tipo de turno',
            deleteTitle: 'Eliminar tipo de turno',
            deleteMessage: '¿Seguro desea eliminar el tipo de turno?',
          },
          unidadesTiempo: {
            title: 'Unidades de tiempo',
            createTitle: 'Creación unidad de tiempo',
            updateTitle: 'Actualización unidad de tiempo',
            deleteTitle: 'Eliminar unidad de tiempo',
            deleteMessage: '¿Seguro desea eliminar la unidad de tiempo?',
          },
          restriccionesLaborales: {
            title: 'Restricciones laborales',
            createTitle: 'Creación restricción laboral',
            updateTitle: 'Actualización restricción laboral',
            deleteTitle: 'Eliminar restricción laboral',
            deleteMessage: '¿Seguro desea eliminar la restricción laboral?',
          },
          tipoModalidadTrabajo: {
            title: 'Tipos modalidades de trabajo',
            createTitle: 'Creación tipo modalidad de trabajo',
            updateTitle: 'Actualización tipo modalidad de trabajo',
            deleteTitle: 'Eliminar tipo modalidad de trabajo',
            deleteMessage: '¿Seguro desea eliminar el tipo modalidad de trabajo?',
          },
          modalidadTrabajo: {
            title: 'Modalidades de trabajo',
            createTitle: 'Creación modalidad de trabajo',
            updateTitle: 'Actualización modalidad de trabajo',
            deleteTitle: 'Eliminar modalidad de trabajo',
            deleteMessage: '¿Seguro desea eliminar la modalidad de trabajo?',
            detailTitle1: 'Activos',
          },
        },
        ausencias: {
          licencias: {
            title: 'Licencias',
            createTitle: 'Creación licencia',
            updateTitle: 'Actualización licencia',
            deleteTitle: 'Eliminar licencia',
            deleteMessage: '¿Seguro desea eliminar la licencia?',
          },
        },
      },
      desvinculacionLaboral: {
        requisitosPazYSalvo: {
          title: 'Requisitos paz y salvo',
          createTitle: 'Creación requisito paz y salvo',
          updateTitle: 'Actualización requisito paz y salvo',
          deleteTitle: 'Eliminar requisito paz y salvo',
          deleteMessage: '¿Seguro desea eliminar el requisito paz y salvo?',
        },
        pazYSalvo: {
          title: 'Paz y salvo',
          createTitle: 'Creación paz y salvo',
          updateTitle: 'Actualización paz y salvo',
          deleteTitle: 'Eliminar paz y salvo',
          deleteMessage: '¿Seguro desea eliminar el paz y salvo?',
          detailTitle1: 'Requisitos paz y salvo',
          detailTitle2: 'Activos',
          requisitosPazYSalvo: {
            title: 'Requisitos paz y salvo',
            createTitle: 'Seleccionar requisitos paz y salvo',
            deleteTitle: 'Eliminar requisito paz y salvo',
            deleteMessage: '¿Seguro desea eliminar el requisito paz y salvo?',
            deleteFileTitle: 'Eliminar archivo',
            deleteFileMessage: '¿Seguro desea eliminar el archivo?'
          },
        },
      },
      gestionTalento: {
        nivelesEducativos: {
          title: 'Niveles educativos',
          createTitle: 'Creación nivel educativo',
          updateTitle: 'Actualización nivel educativo',
          deleteTitle: 'Eliminar nivel educativo',
          deleteMessage: '¿Seguro desea eliminar el nivel educativo?',
        },
        tiposRestricciones: {
          title: 'Tipos restricciones',
          createTitle: 'Creación tipo de restricción',
          updateTitle: 'Actualización tipo de restricción',
          deleteTitle: 'Eliminar tipo de restricción',
          deleteMessage: '¿Seguro desea eliminar el tipo de restricción?',
        },
        filtroGestionTalento: {
          title: 'Filtros gestión del talento',
          createTitle: 'Creación filtro gestión del talento',
          updateTitle: 'Actualización filtro gestión del talento',
          deleteTitle: 'Eliminar filtro gestión del talento',
          deleteMessage: '¿Seguro desea eliminar el filtro gestión del talento?',
        },
      },
      eps: {
        title: 'Eps',
        createTitle: 'Creación eps',
        updateTitle: 'Actualización eps',
        deleteTitle: 'Eliminar eps',
        deleteMessage: '¿Seguro desea eliminar la eps?',
      },
      tipoAfiliacionEps: {
        title: 'Tipos de afiliación',
        createTitle: 'Creación tipo de afiliación',
        updateTitle: 'Actualización tipo de afiliación',
        deleteTitle: 'Eliminar tipo de afiliación',
        deleteMessage: '¿Seguro desea eliminar el tipo de afiliación?',
      },
    },
  },
  sst: {
    maestros: {
      consecutivos: {
        title: 'Consecutivos SST',
        createTitle: 'Creación consecutivo SST',
        updateTitle: 'Actualización consecutivo SST',
        deleteTitle: 'Eliminar consecutivo SST',
        deleteMessage: '¿Seguro desea eliminar el consecutivo SST?',
      },
      documentos: {
        title: 'Transacciones SST',
        createTitle: 'Creación transacción SST',
        updateTitle: 'Actualización transacción SST',
        deleteTitle: 'Eliminar transacción SST',
        deleteMessage: '¿Seguro desea eliminar la transacción SST?',
      },
      tipoRiesgo: {
        title: 'Tipo de riesgos',
        createTitle: 'Creación tipo de riesgo',
        updateTitle: 'Actualización tipo de riesgo',
        deleteTitle: 'Eliminar tipo de riesgo',
        deleteMessage: '¿Seguro desea eliminar el tipo de riesgo?',
      },
      clasificacionRiesgo: {
        title: 'Clasificación de riesgos',
        createTitle: 'Creación clasificación de riesgo',
        updateTitle: 'Actualización clasificación de riesgo',
        deleteTitle: 'Eliminar clasificación de riesgo',
        deleteMessage: '¿Seguro desea eliminar la clasificación de riesgo?',
      },
      probabilidadRiesgo: {
        title: 'Probabilidad de riesgos',
        createTitle: 'Creación probabilidad de riesgo',
        updateTitle: 'Actualización probabilidad de riesgo',
        deleteTitle: 'Eliminar probabilidad de riesgo',
        deleteMessage: '¿Seguro desea eliminar la probabilidad de riesgo?',
      },
      impactoRiesgo: {
        title: 'Impacto de riesgos',
        createTitle: 'Creación impacto de riesgo',
        updateTitle: 'Actualización impacto de riesgo',
        deleteTitle: 'Eliminar impacto de riesgo',
        deleteMessage: '¿Seguro desea eliminar el impacto de riesgo?',
      },
      tipoEquipoTrabajo: {
        title: 'Tipo equipo trabajo',
        createTitle: 'Creación tipo equipo trabajo',
        updateTitle: 'Actualización tipo equipo trabajo',
        deleteTitle: 'Eliminar tipo equipo trabajo',
        deleteMessage: '¿Seguro desea eliminar el tipo equipo trabajo?',
      },
      elementosProteccionPersonal: {
        title: 'Elementos de protección personal',
        createTitle: 'Creación elemento de protección personal',
        updateTitle: 'Actualización elemento de protección personal',
        deleteTitle: 'Eliminar elemento de protección personal',
        deleteMessage: '¿Seguro desea eliminar el elemento de protección personal?',
        detailTitle1: 'Cargos',
        detailTitle2: 'Roles',
        detailTitle3: 'Empleados',
        cargos: {
          deleteTitle: 'Eliminar cargo',
          deleteMessage: '¿Seguro desea eliminar el cargo?',
        },
        roles: {
          deleteTitle: 'Eliminar rol',
          deleteMessage: '¿Seguro desea eliminar el rol?',
        },
        empleados: {
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
        },
      },
    },
    procesos: {
      identEvalRiesgos: {
        title: 'Identificación - Evaluación riesgos',
        createTitle: 'Creación identificación - evaluación riesgo',
        updateTitle: 'Actualización identificación - evaluación riesgo',
        deleteTitle: 'Eliminar identificación - evaluación riesgo',
        deleteMessage: '¿Seguro desea eliminar la identificación - evaluación riesgo?',
        detailTitle1: 'Evaluaciones',
        detailTitle2: 'Archivos adjuntos',
        detailTitle3: 'Lecciones aprendidas',
        evaluaciones: {
          createTitle: 'Creación evaluación',
          updateTitle: 'Actualización evaluación',
        },
      },
      regAccidenteLab: {
        title: 'Registro de accidentes laborales',
        createTitle: 'Creación registro de accidente laboral',
        updateTitle: 'Actualización registro de accidente laboral',
        deleteTitle: 'Eliminar registro de accidente laboral',
        deleteMessage: '¿Seguro desea eliminar el registro de accidente laboral?',
        detailTitle1: 'Testigos',
        testigos: {
          createTitle: 'Seleccionar testigos',
          updateTitle: 'Actualización testigo',
          deleteTitle: 'Eliminar testigo',
          deleteMessage: '¿Seguro desea eliminar el testigo?',
        },
      },
    },
  },
  plm: {
    maestros: {
      clasificacionProceso: {
        title: 'Clasificación procesos',
      },
      clasificacionRecurso: {
        title: 'Clasificación recurso',
      },
      consecutivo: {
        title: 'Consecutivo PLM',
      },
      documento: {
        title: 'Transacciones PLM',
      },
      operacion: {
        title: 'Operación',
        createTitle: 'Creación de operación',
        updateTitle: 'Actualización de operación',
        deleteTitle: 'Eliminar operación',
        deleteMessage: '¿Está seguro de eliminar este registro?',
      },
      proceso: {
        title: 'Procesos PLM',
      },
      producto: {
        PT: {
          label1: 'Configuración',
          label2: 'Distribución (WMS)',
          label3: 'Producto terminado',
          title: 'Productos terminados',
          tableTitle: 'Listado productos terminados',
          deleteTitle: 'Eliminar producto terminado',
          deleteMessage: '¿Seguro desea eliminar el producto terminado?',
          createTitle: 'Creación producto terminado',
          updateTitle: 'Actualización producto terminado',
          messageInfo: 'Recuerde completar los ATRIBUTOS DE CONTROL antes de salir de este formulario',
          duplicateTitle: 'Duplicar producto terminado',
          duplicateMessage: '¿Seguro desea duplicar el producto terminado?',
        },
        MP: {
          label1: 'Configuración',
          label2: 'Distribución (WMS)',
          label3: 'Materia prima',
          title: 'Productos',
          tableTitle: 'Listado de productos',
          deleteTitle: 'Eliminar producto materia prima',
          deleteMessage: 'Seguro desea eliminar el producto materia prima?',
          createTitle: 'Creación producto',
          updateTitle: 'Actualización producto',
          messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
        },
        IG: {
          label1: 'Configuración',
          label2: 'Distribución (WMS)',
          label3: 'Inventario general',
          title: 'Productos',
          tableTitle: 'Listado productos de inventario general',
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Seguro desea eliminar el producto?',
          createTitle: 'Creación producto',
          updateTitle: 'Actualización producto',
          messageInfo: 'Recuerde completar los ATRIBUTOS DE CONTROL antes de salir de este formulario',
          duplicateTitle: 'Duplicar producto',
          duplicateMessage: '¿Seguro desea duplicar el producto?',
        },
        tableVariantsTitle: 'Listado de variantes',
        tableVariantsCreate: '',
        tableVariantsUpdate: 'actualizar variante',
        tableVariantsDeleteTitle: 'Eliminar variante',
        tableVariantsDeleteMessage: '¿Está seguro de eliminar esta variante?',
        codigoBarrasFormCreate: 'Crear código de barras',
        codigoBarrasFormUpdate: 'Actualizar código de barras',
        deleteCodigoEanTitle: 'Eliminar código EAN',
        deleteCodigoEanMessage: '¿Está seguro de eliminar este código EAN?',
        createControlAtributos: 'Listado de atributos',
        deleteControlAtributos: 'Eliminar atributo de control',
        deleteControlAtributosMessage: '¿Está seguro de eliminar el este atributo de control?',
        formPerfilLogistico: 'Listado de perfiles',
        createPerfilLogistico: 'Creación de perfil',
        deletePerfilLogisticoTitle: 'Eliminar perfil logístico',
        deletePerfilLogisticoMessage: '¿Está seguro de eliminar este perfil logístico?',
        detailTitle1: 'Histórico costo promedio',
        detailTitle2: 'Proveedores',
        detailTitle3: 'Atributos comerciales',
        detailTitle4: 'Variantes',
        detailTitle5: 'Perfil logístico',
        detailTitle6: 'Atributos de control',
        detailTitle7: 'Atributos descriptivos',
        detailTitle8: 'Imágenes',
        detailTitle9: 'Archivos adjuntos',
        detailTitle10: 'Composiciones',
        detailTitle11: 'Registro invima',
        detailTitle12: 'Retenciones',
        detailTitle13: 'Impuestos',
        detailTitle14: 'Compra',
        detailTitle15: 'Venta',
        detailTitle16: 'Mantenimientos',
      },
      // Tabs producto
      atributosDescripcion: {
        title: 'Atributos de descripción',
        tableTitle: 'Formulario de atributos descriptivos',
        deleteTitle: 'Eliminar atributo descriptivo',
        deleteMessage: '¿Seguro desea eliminar el atributo descriptivo?',
        createTitle: 'Seleccionar atributos descriptivos',
        updateTitle: 'Actualización producto terminado',
        warningTitle: 'No se han encontrado atributos descriptivos para este formulario',
      },
      atributosControl: {
        title: 'Atributos de control',
        tableTitle: 'Formulario de atributos de control',
        deleteTitle: 'Eliminar atributo de control',
        deleteMessage: '¿Seguro desea eliminar el atributo de control?',
        createTitle: 'Seleccionar atributos de control',
        updateTitle: 'Actualización producto terminado',
        warningTitle: 'No se han encontrado atributos de control para este formulario',
      },
      proveedor: {
        title: 'Proveedores',
        createTitle: 'Creación proveedor',
        updateTitle: 'Actualización proveedor',
        deleteTitle: 'Eliminar proveedor',
        deleteMessage: '¿Seguro desea eliminar el proveedor?',
        createProveedor: 'Seleccionar proveedores',
      },
      productoImpuesto: {
        deleteTitle: 'Eliminar impuesto',
        deleteMessage: '¿Seguro desea eliminar el impuesto?',
        createImpuesto: 'Seleccionar impuestos',
      },
      productosRetenciones: {
        deleteTitle: 'Eliminar retención',
        deleteMessage: '¿Seguro desea eliminar la retención?',
        createRetencion: 'Seleccionar retenciones',
      },

    },
    procesos: {
      ordenProduccion: {
        title: 'Ordenes de producción',
        tableTitle: 'Listado ordenes de producción',
        createTitle: 'Creación ordenes de  producción',
        updateTitle: 'Actualización orden de producción',
        deleteTitle: 'Eliminar orden de producción',
        deleteMessage: '¿Está seguro de eliminar esta orden de producción?',
        detailTitle1: 'Materiales',
      },
      ordenTrabajoMateriales: {
        title: 'Materiales',
        createTitle: 'Creación de material',
        updateTitle: 'Actualización de material',
        deleteTitle: 'Eliminar material',
        deleteMessage: '¿Está seguro de eliminar este material?',
      },
      cirugia: {
        title: 'Orden de trabajo',
        tableTitle: 'Listado de ordenes de trabajo',
        createTitle: 'Creación de orden de trabajo',
        deleteTitle: 'Eliminar orden de trabajo',
        deleteMessage: '¿Está seguro de eliminar esta orden de trabajo?',
        updateTitle: 'Actualización orden de trabajo',
        detailTitle1: 'Pedidos',
        detailTitle2: 'Agrupaciones',
        detailTitle3: 'Productos',
        detailTitle4: 'Materia prima',
        detailTitle5: 'Producto extra',
      },
      fichaTecnica: {
        title: 'Ficha técnica',
        tableTitle: 'Listado de fichas técnicas',
        createTitle: 'Creación de fichas técnicas',
        deleteTitle: 'Eliminar ficha tecnica',
        deleteMessage: '¿Está seguro de eliminar esta ficha tecnica?',
        updateTitle: 'Actualización ficha tecnica',
        detailTitle1: 'Flujo de producción',
        detailTitle2: 'Operaciones',
        detailTitle3: 'Consumos',
        detailTitle4: 'Sustituto materia prima',
        detailTitle5: 'Sustituto producto terminado',
      },
      fichaTecnicaFlujoProduccion: {
        title: 'Flujo producción',
        createTitle: 'Creación de flujos de producción',
        updateTitle: 'Actualización de flujos de producción',
        deleteTitle: 'Eliminar flujo de producción',
        deleteMessage: '¿Está seguro de eliminar este flujo de producción?',
      },
      fichaTecnicaConsumos: {
        title: 'Consumos',
        createTitle: 'Creación de consumo',
        updateTitle: 'Actualización de consumo',
        deleteTitle: 'Eliminar consumo',
        deleteMessage: '¿Está seguro de eliminar esta consumo?',
      },
      fichaTecnicaOperaciones: {
        title: 'Operaciones',
        createTitle: 'Creación de operación',
        updateTitle: 'Actualización de operación',
        deleteTitle: 'Eliminar operación',
        deleteMessage: '¿Está seguro de eliminar esta operación?',
      },
      fichaTecnicaSustituto: {
        title: 'Sustituto',
        createTitle: 'Creación de sustituto',
        updateTitle: 'Actualización de sustituto',
        deleteTitle: 'Eliminar sustituto',
        deleteMessage: '¿Está seguro de eliminar esta sustituto?',
      },
      ordenTrabajoProducto: {
        title: 'Productos',
        createTitle: 'Creación de producto',
        updateTitle: 'Actualización de producto',
        deleteTitle: 'Eliminar producto',
        deleteMessage: '¿Está seguro de eliminar este producto?',
      },
      ordenTrabajoAgrupaciones: {
        title: 'Agrupaciones',
        createTitle: 'Creación de agrupaciones',
        updateTitle: 'Actualización de agrupaciones',
        deleteTitle: 'Eliminar agrupaciones',
        deleteMessage: '¿Está seguro de eliminar este agrupaciones?',
      },
      ordenTrabajoProductos: {
        title: 'Productos',
        createTitle: 'Creación de productos',
        updateTitle: 'Actualización de productos',
        deleteTitle: 'Eliminar productos',
        deleteMessage: '¿Está seguro de eliminar este productos?',
      },
    },
  },
  pqrsf: {
    maestros: {
      consecutivo: {
        title: 'Consecutivos calidad',
        createTitle: 'Creación consecutivo calidad',
        updateTitle: 'Actualización consecutivo calidad',
        deleteTitle: 'Eliminar consecutivo calidad',
        deleteMessage: '¿Seguro desea eliminar el consecutivo calidad?',
      },
      documento: {
        title: 'Transacciones calidad',
        createTitle: 'Creación transacción calidad',
        updateTitle: 'Actualización transacción calidad',
        deleteTitle: 'Eliminar transacción calidad',
        deleteMessage: '¿Seguro desea eliminar la transacción calidad?',
      },
      tipoSolicitud: {
        title: 'Categorías solicitud',
        createTitle: 'Creación categoría solicitud',
        updateTitle: 'Actualización categoría solicitud',
        deleteTitle: 'Eliminar categoría solicitud',
        deleteMessage: '¿Seguro desea eliminar la categoría solicitud?',
        disableMessage: 'El registro está en uso por lo cual se inactiva',
      },
    },
    procesos: {
      solicitud: {
        title: 'Gestión solicitudes - PQRSF',
        tableTitle: 'Listado de gestión solicitudes - PQRSF',
        createTitle: 'Creación gestión solicitud - PQRSF',
        deleteTitle: 'Eliminar gestión solicitud - PQRSF',
        deleteMessage: '¿Seguro desea eliminar la gestión solicitud - PQRSF?',
        updateTitle: 'Actualización gestión solicitud - PQRSF',
        changeEstado: 'Cambio de estado',
        detailTitle1: 'Comentarios',
        detailTitle2: 'Archivos adjuntos',
        detailTitle3: 'Histórico estados',
      },
    },
  },
  registroAcceso: {
    accesoEmpleado: {
      title: 'Accesos empleados',
    },
  },
  tesoreria: {
    maestros: {
      bancos: {
        title: 'Bancos',
        tableTitle: 'Listado de bancos',
        createTitle: 'Creación de bancos',
        deleteTitle: 'Eliminar banco',
        deleteMessage: '¿Está seguro de eliminar este banco?',
        updateTitle: 'Actualización de bancos',
        conceptosTesoreria: {
          detailTitle: 'Conceptos tesorería',
          createTitle: 'Agregar conceptos de tesorería',
          deleteTitle: 'Eliminar conceptos de tesorería',
          deleteMessage: '¿Seguro desea eliminar el concepto de tesorería?',
          updateTitle: 'Actualización de concepto de tesorería',
        },
        impuestos: {
          detailTitle: 'Impuestos',
          createTitle: 'Agregar impuestos',
          deleteTitle: 'Eliminar impuestos',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
          updateTitle: 'Actualización de impuesto',
        },
        retenciones: {
          detailTitle: 'Retenciones',
          createTitle: 'Agregar retenciones',
          deleteTitle: 'Eliminar retenciones',
          deleteMessage: '¿Seguro desea eliminar la retención?',
          updateTitle: 'Actualización de retención',
        },
      },
      cajas: {
        title: 'Cajas',
        tableTitle: 'Listado de cajas',
        createTitle: 'Creación cajas',
        deleteTitle: 'Eliminar caja',
        deleteMessage: '¿Seguro desea eliminar la caja?',
        updateTitle: 'Actualización de cajas',
        conceptosTesoreria: {
          detailTitle: 'Conceptos tesorería',
          createTitle: 'Agregar conceptos de tesorería',
          deleteTitle: 'Eliminar conceptos de tesorería',
          deleteMessage: '¿Seguro desea eliminar el concepto de tesorería?',
          updateTitle: 'Actualización concepto de tesorería',
        },
      },
      categorizacionGastos: {
        title: 'Categorización gastos',
        createTitle: 'Creación categorización gastos',
        updateTitle: 'Actualización categorización gastos',
        deleteTitle: 'Eliminar categorización gastos',
        deleteMessage: '¿Seguro desea eliminar la categorización gasto?',
      },
      conceptoTesoreria: {
        title: 'Conceptos tesorería',
        tableTitle: 'Listado de conceptos de tesorería',
        createTitle: 'Creación de concepto de tesorería',
        deleteTitle: 'Eliminar concepto de tesorería',
        deleteMessage: '¿Seguro desea eliminar el concepto de tesorería?',
        updateTitle: 'Actualización de concepto de tesorería',
        categorizacionCuenta: {
          detailTitle: 'Categorización cuentas contables',
          createTitle: 'Creación de categorización de cuentas contable',
          deleteTitle: 'Eliminar categorización de cuenta contable',
          deleteMessage: '¿Seguro desea eliminar la categorización?',
          updateTitle: 'Actualización de concepto de categorización',
          distribucion: {
            title: 'Distribución de conceptos',
            update: 'Actualizar Distribución de conceptos',
            create: 'Crear Distribución de conceptos',
            deleteTitle: 'Eliminar distribución de conceptos',
            deleteMessage: '¿Seguro desea eliminar la distribución de concepto?',
          },
        }
      },
      clasificacionBanco: {
        title: 'Clasificación de banco',
      },
      clasificacionGastos: {
        title: 'Clasificación de gastos',
      },
      consecutivo: {
        title: 'Consecutivos de tesorería',
      },
      cuentasBancarias: {
        title: 'Cuentas bancarias',
        tableTitle: 'Listado de cuentas bancarias',
        createTitle: 'Creación de cuentas bancarias',
        deleteTitle: 'Eliminar cuenta bancaria',
        deleteMessage: '¿Seguro desea eliminar la cuenta bancaria?',
        updateTitle: 'Actualización de cuenta bancaria',
        conceptosTesoreria: {
          detailTitle: 'Conceptos tesorería',
          createTitle: 'Agregar conceptos de tesorería',
          deleteTitle: 'Eliminar conceptos de tesorería',
          deleteMessage: '¿Está seguro de eliminar el concepto de tesorería?',
          updateTitle: 'Actualización de concepto de tesorería',
        },
      },
      documentosTesoreria: {
        title: 'Transacción de tesorería',
        tableTitle: 'Listado de transacciónes de tesorería',
        createTitle: 'Creación de transacción de tesorería',
        deleteTitle: 'Eliminar transacción de tesorería',
        deleteMessage: '¿Está seguro de eliminar esta transacción de tesorería?',
        updateTitle: 'Actualización de transacción de tesorería',
        roles: {
          deleteTitle: 'Eliminar rol',
          deleteMessage: '¿Seguro desea eliminar el rol?',
        },
      },
      formaPago: {
        title: 'Formas pago',
        createTitle: 'Creación de forma de pago',
        updateTitle: 'Actualización forma de pago',
        deleteTitle: 'Eliminar forma de pago',
        deleteMessage: '¿Está seguro de eliminar esta forma de pago?',
      },
      grupoConceptoTesoreria: {
        title: 'Grupo conceptos de tesorería',
        createTitle: 'Creación grupo conceptos de tesorería',
        updateTitle: 'Actualización grupo conceptos de tesorería',
        deleteTitle: 'Eliminar grupo conceptos de tesorería',
        deleteMessage: 'Seguro desea eliminar el grupo conceptos de tesorería?',
      },
      mediosPago: {
        title: 'Medios pago',
        updateTitle: 'Actualización medio de pago',
        createTitle: 'Creación de medio de pago',
        deleteTitle: 'Eliminar medio de pago',
        deleteMessage: '¿Está seguro de eliminar esta medio de pago?',
      },
      moneda: {
        title: 'Monedas',
        tableTitle: 'Listado de monedas',
        createTitle: 'Creación de monedas',
        deleteTitle: 'Eliminar monedas',
        deleteMessage: '¿Está seguro de eliminar esta moneda?',
        updateTitle: 'Actualización de moneda',
      },
      tarjetasCredito: {
        title: 'Tarjetas de crédito',
        createTitle: 'Creación tarjeta de crédito',
        updateTitle: 'Actualización tarjeta de crédito',
        deleteTitle: 'Eliminar tarjeta de crédito',
        deleteMessage: '¿Seguro desea eliminar la tarjeta de crédito?',
        conceptoTesoreria: {
          title: 'Conceptos tesorería',
          createTitle: 'Seleccionar conceptos tesorería',
          updateTitle: 'Actualización concepto de tesorería',
          deleteTitle: 'Eliminar concepto de tesorería',
          deleteMessage: '¿Seguro desea eliminar el concepto de tesorería?',
        },
      },
      tiposNotaCredito: {
        title: 'Tipos de nota crédito',
      },
      tiposNotaDebito: {
        title: 'Tipos de nota débito',
      },
      tipoDocElectronico: {
        title: 'Tipos de documentos electrónicos',
        createTitle: 'Creación Tipo documento electrónico',
        updateTitle: 'Actualización Tipo documento electrónico',
        deleteTitle: 'Eliminar Tipo documento electrónico',
        deleteMessage: '¿Seguro desea eliminar el tipo documento electrónico?',
      },
      tipoCuentaBancaria: {
        title: 'Tipos cuentas bancarias',
      },
      tipoPago: {
        title: 'Tipos pago',
      },
      tipoTasas: {
        title: 'Tipos de tasas',
      },
      tiposImpuestos: {
        title: 'Tipos de impuestos',
      },
      tiposRetenciones: {
        title: 'Tipos de retenciones',
      },
    },
    procesos: {
      cajaMenor: {
        title: 'Caja Menor',
        createTitle: 'Creación caja menor',
        updateTitle: 'Actualización caja menor',
        deleteTitle: 'Eliminar caja menor',
        deleteMessage: '¿Seguro desea eliminar la caja menor?',
      },
      cajaMenorMovimiento: {
        title: 'Movimientos',
        tabTitleCreate: 'Creación movimiento',
        tabTitleUpdate: 'Actualización movimiento',
        tabTitleDelete: 'Eliminar movimiento',
        tabMessageDelete: '¿Seguro desea eliminar el movimiento?',
        deleteDocumentTitle: 'Eliminar documento',
        deleteDocumentMessage: '¿Seguro desea eliminar el documento?',
      },
      gastoTesoreria: {
        title: 'Gastos',
        createTitle: 'Creación gasto',
        updateTitle: 'Actualización gasto',
        deleteTitle: 'Eliminar gasto',
        deleteMessage: '¿Seguro desea eliminar el gasto?',
        gastoTesoreriaProducto: {
          tabTitle1: 'Productos',
          createTitle: 'Creación de producto',
          updateTitle: 'Actualización de producto',
          updateTitleModal: 'Actualización',
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Seguro desea eliminar el producto?',
          impuestos: {
            tabTitle1: 'Impuestos',
          },
        },
        gastoTesoreriaServicio: {
          tabTitle1: 'Servicios',
          createTitle: 'Creación servicio',
          updateTitle: 'Actualización servicio',
          updateTitleModal: 'Actualización',
          deleteTitle: 'Eliminar servicio',
          deleteMessage: '¿Seguro desea eliminar el servicio?',
          impuestos: {
            tabTitle1: 'Impuestos',
          },
        },
      },
      gastoRecurrente: {
        title: 'Gastos recurrentes',
        createTitle: 'Creación gasto recurrente',
        updateTitle: 'Actualización gasto recurrente',
        deleteTitle: 'Eliminar gasto recurrente',
        deleteMessage: '¿Seguro desea eliminar el gasto recurrente',
      },
      conciliacionBancaria: {
        title: 'Conciliación bancaria',
        createTitle: 'Creación conciliación bancaria',
        updateTitle: 'Actualización conciliación bancaria',
        deleteTitle: 'Eliminar conciliación bancaria',
        deleteMessage: '¿Seguro desea eliminar la conciliación bancaria?',
        detailTitle1: 'Movimientos pendientes',
      },
      conciliacionCompras: {
        title: 'Conciliación compras',
        createTitle: 'Creación conciliación compra',
        updateTitle: 'Actualización conciliación compra',
        deleteTitle: 'Eliminar conciliación compra',
        deleteMessage: '¿Seguro desea eliminar la conciliación compra?',
        detailTitle1: 'Archivos adjuntos',
        detailTitle2: 'Ordenes compra',
        detailTitle3: 'Recepciones',
        detailTitle4: 'Productos',
      },
      contratoTesoreria: {
        title: 'Contrato tesorería',
        tableTitle: 'Listado contrato tesoreria',
        createTitle: 'Creación de contrato tesoreria',
        updateTitle: 'Actualización contrato tesoreria',
        deleteTitle: 'Eliminar contrato tesoreria',
        deleteMessage: '¿Está seguro de eliminar este contrato tesoreria?',
        detailTitle1: 'Tipo pago',
      },
      contratoTesoreriaTipoPago: {
        title: 'Tipo pago',
        createTitle: 'Creación de tipo pagos',
        updateTitle: 'Actualización de tipo pagos',
        deleteTitle: 'Eliminar tipo pago',
        deleteMessage: '¿Está seguro de eliminar esta tipo pago?',
      },
      notasCredito: {
        title: 'Notas crédito',
        createTitle: 'Creación nota crédito',
        updateTitle: 'Actualización nota crédito',
        deleteTitle: 'Eliminar nota crédito',
        deleteMessage: '¿Seguro desea eliminar la nota crédito?',
        tableDetail1: 'Hay devolución de dinero',
        tableDetail2: 'Histórico estados',
        notasCreditoDescuentos: {
          deleteTitle: 'Eliminar descuento',
          deleteMessage: '¿Está seguro de eliminar este descuento?',
          createTitle: 'Creación de descuentos',
        },
        notasCreditoFacturaProductos: {
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Está seguro de eliminar este producto?',
          createTitle: 'Creación de productos',
        },
        notasCreditoFacturaServicios: {
          deleteTitle: 'Eliminar servicio',
          deleteMessage: '¿Está seguro de eliminar este servicio?',
          createTitle: 'Creación de servicios',
        },
      },
      notasDebito: {
        title: 'Notas débito',
        createTitle: 'Creación nota débito',
        updateTitle: 'Actualización nota débito',
        deleteTitle: 'Eliminar nota débito',
        deleteMessage: '¿Seguro desea eliminar la nota débito?',
        tableDetail1: 'Productos',
        createTableDetail1: 'Seleccionar productos',
        tableDetailDeleteTitle: 'Eliminar productos de la nota débito',
        tableDetailDeleteMessage: '¿Seguro desea eliminar el producto?',
        tableDetail2: 'Servicios',
        createTableDetail2: 'Agregar servicios a la nota débito',
        table2DetailDeleteTitle: 'Eliminar servicios de la nota débito',
        table2DetailDeleteMessage: '¿Seguro desea eliminar el servicio?',
        detailTitle3: 'Facturas',
        detailTitle4: 'Histórico estados',
      },
      documentosFinancieros: {
        title: 'Transacciones financieras',
        tableTitle: 'Listado transacciones financieras',
        createTitle: 'Creación transacción financiera',
        updateTitle: 'Actualización transacción financiera',
        deleteTitle: 'Eliminar trasnacción financiera',
        deleteMessage: '¿Seguro desea eliminar la transacción financiera?',
        detailTitle1: 'Prórrogas',
        detailTitle2: 'Compras',
        detailTitle3: 'Ordenes de compra',
      },
      documentosFinancierosProrroga: {
        title: 'Prórrogas',
        createTitle: 'Creación de prórrogas',
        updateTitle: 'Actualización de  prórrogas',
        deleteTitle: 'Eliminar Prórroga',
        deleteMessage: '¿Está seguro de eliminar esta Prórroga?',
      },
      forward: {
        title: 'Forward',
        tableTitle: 'Listado forward',
        createTitle: 'Creación de forward',
        updateTitle: 'Actualización forward',
        deleteTitle: 'Eliminar forward',
        deleteMessage: '¿Está seguro de eliminar este forward?',
        detailTitle1: 'Temporadas',
        detailTitle2: 'Compras',
        detailTitle3: 'Órdenes de compra',
      },
      TemporadaForward: {
        title: 'Temporadas',
        createTitle: 'Creación de temporadas',
        updateTitle: 'Actualización de  temporadas',
        deleteTitle: 'Eliminar temporada',
        deleteMessage: '¿Está seguro de eliminar esta temporada?',
      },
      egresos: {
        title: 'Egresos',
        tableTitle: 'Listado egresos',
        createTitle: 'Creación causación de créditos',
        updateTitle: 'Actualización causación de créditos',
        deleteTitle: 'Eliminar causación de créditos',
        deleteMessage: '¿Seguro desea eliminar la causación de créditos?',
        detailTitle1: 'Documentos',
      },
      otrosIngresos: {
        title: 'Otros Ingresos',
        createTitle: 'Creación otro ingreso',
        updateTitle: 'Actualización otro ingreso',
        deleteTitle: 'Eliminar otro ingreso',
        deleteMessage: '¿Seguro desea eliminar el otro ingreso?',
        detailTitle1: 'Archivos adjuntos',
        detailTitle2: 'Comentarios',
      },
      pagos: {
        title: 'Pagos',
        tableTitle: 'Listado pagos',
        createTitle: 'Creación Pago',
        updateTitle: 'Actualización pago',
        deleteTitle: 'Eliminar pago',
        deleteMessage: '¿Seguro desea eliminar el pago?',
      },
      consignacionesPorIdentificar: {
        title: 'Consignaciones por identificar',
        tableTitle: 'Listado consiganciones por identificar',
        createTitle: 'Creación consiganción por identificar',
        updateTitle: 'Actualización consiganción por identificar',
        deleteTitle: 'Eliminar consiganción por identificar',
        deleteMessage: '¿Seguro desea eliminar la consiganción por identificar?',
        detailTitle1: 'Movimientos sin identificar',
      },
      movimientosSinIdentificar: {
        title: 'Movimientos sin identificar',
        tableTitle: 'Listado movimientos sin identificar',
        createTitle: 'Creación de movimientos sin identificar',
        updateTitle: 'Actualización movimientos sin identificar',
        deleteTitle: 'Eliminar movimiento sin identificar',
        deleteMessage: '¿Está seguro de eliminar este movimiento sin identificar?',
      },
      pagoFacturas: {
        title: 'Pagos facturas',
        createTitle: 'Creación pago factura',
        updateTitle: 'Actualización pago factura',
        deleteTitle: 'Eliminar pago factura',
        deleteMessage: '¿Seguro desea eliminar el pago factura?',
      },
      detalleDocumetosEgreso: {
        title: 'Compras',
        createTitle: 'Creación de  compras',
        updateTitle: 'Actualización de  compras',
        deleteTitle: 'Eliminar compra',
        deleteMessage: '¿Está seguro de eliminar esta compra?',
      },
      recibosCaja: {
        title: 'Anticipos',
        tableTitle: 'Listado de ingresos',
        createTitle: 'Creación anticipo',
        updateTitle: 'Actualización anticipo',
        deleteTitle: 'Eliminar anticipo',
        deleteMessage: '¿Seguro desea eliminar el anticipo?',
        detailTitle1: 'Facturas',
        detailTitle2: 'Archivos adjuntos',
        detailTitle3: 'Comentarios',
        detalleImpuesto: {
          title: 'Impuestos',
          createTitle: 'Agregar impuestos a el ingreso',
          deleteTitle: 'Eliminar impuesto',
          deleteMessage: '¿Seguro desea eliminar el impuesto?',
        },
        detalleRetencion: {
          title: 'Retenciones',
          createTitle: 'Agregar retención a el ingreso',
          deleteTitle: 'Eliminar retención',
          deleteMessage: '¿Seguro desea eliminar la retención?',
        },
      },
      detalleDocumetos: {
        title: 'Facturas',
        createTitle: 'Creación de facturas',
        updateTitle: 'Actualización de facturas',
        deleteTitle: 'Eliminar factura',
        deleteMessage: '¿Está seguro de eliminar esta factura?',
        tableDetail1: 'Modificar facturas',
        tableDetail2: 'Impuestos y retenciones',
        innerTableDetail2: 'Impuestos',
        innerTableDetail3: 'Retenciones',
      },
      facturasPendientes: {
        title: 'Facturas pendientes',
        createTitle: 'Creación de facturas pendiente',
        updateTitle: 'Actualización de facturas pendiente',
        deleteTitle: 'Eliminar factura pendiente',
        deleteMessage: '¿Está seguro de eliminar esta factura pendiente?',
      },
      tributos: {
        title: 'Conceptos tributarios',
        createTitle: 'Creación de concepto',
        updateTitle: 'Actualización de concepto',
        deleteTitle: 'Eliminarconcepto',
        deleteMessage: '¿Está seguro de eliminar estaconcepto?',
      },
      causacionCreditos: {
        title: 'Causación créditos',
        createTitle: 'Creación de causación de créditos',
        updateTitle: 'Actualización de causación de créditos',
        deleteTitle: 'Eliminar',
        deleteMessage: '¿Está seguro de eliminar la causación crédito?',
        tabDetail1: 'Pagos',
      },
      programacionPago: {
        title: 'Programaciones pagos',
        tableTitle: 'Listado de programaciones de pago',
        createTitle: 'Creación Programación pago',
        updateTitle: 'Actualización Programación pago',
        deleteTitle: 'Eliminar Programación pago',
        deleteMessage: '¿Seguro desea eliminar la Programación pago?',
        modalEstadoProgramacionPago: 'Cambio estado Programación pago',
        tableDetalleTitle: 'Listado de tipos documentos',
        tableDetalleCreate: 'Agregar documento',
        tableDetalleUpdate: 'Modificar documento',
        tableDetalleDeleteTitle: 'Eliminar documento',
        tableDetalleDeleteMessage: '¿Está seguro de eliminar este documento?',
      },
    },
    consultas: {
      cuentasPorCobrar: {
        title: 'Cuentas por cobrar',
      },
    },
  },
  gestionContratos: {
    maestros: {
      consecutivo: {
        title: 'Consecutivos gestión de contratos',
        createTitle: 'Creación consecutivo gestión de contratos',
        updateTitle: 'Actualización consecutivo gestión de contratos',
        deleteTitle: 'Eliminar consecutivo gestión de contratos',
        deleteMessage: '¿Seguro desea eliminar el consecutivo gestión de contratos?',
      },
      documento: {
        title: 'Transacciones gestión de contratos',
        createTitle: 'Creación transacción gestión de contrato',
        updateTitle: 'Actualización transacción gestión de contrato',
        deleteTitle: 'Eliminar transacción gestión de contrato',
        deleteMessage: '¿Seguro desea eliminar la transacción gestión de contrato?',
      },
      tipoDocumentoContrato: {
        title: 'Tipos documentos contratos',
        createTitle: 'Creación tipo documento contrato',
        updateTitle: 'Actualización tipo documento contrato',
        deleteTitle: 'Eliminar tipo documento contrato',
        deleteMessage: '¿Seguro desea eliminar el tipo documento contrato?',
      },
      clasificacionContrato: {
        title: 'Clasificación contratos',
        createTitle: 'Creación clasificación contrato',
        updateTitle: 'Actualización clasificación contrato',
        deleteTitle: 'Eliminar clasificación contrato',
        deleteMessage: '¿Seguro desea eliminar la clasificación contrato?',
      },
      parteInvolucrada: {
        title: 'Partes involucradas',
        createTitle: 'Creación parte involucrada',
        updateTitle: 'Actualización parte involucrada',
        deleteTitle: 'Eliminar parte involucrada',
        deleteMessage: '¿Seguro desea eliminar la parte involucrada?',
      },
    },
    procesos: {
      solicitudContratos: {
        title: 'Contratos',
        createTitle: 'Creación Contrato',
        updateTitle: 'Actualización Contrato',
        deleteTitle: 'Eliminar Contrato',
        deleteMessage: '¿Seguro desea eliminar el Contrato?',
      },
    },
  },
  gestionProveedores: {
    maestros: {
      consecutivo: {
        title: 'Consecutivos gestión de proveedores',
        createTitle: 'Creación consecutivo gestión de proveedores',
        updateTitle: 'Actualización consecutivo gestión de proveedores',
        deleteTitle: 'Eliminar consecutivo gestión de proveedor',
        deleteMessage: '¿Seguro desea eliminar el consecutivo gestión de proveedor?',
      },
      documento: {
        title: 'Transacciones gestión de proveedores',
        createTitle: 'Creación transacción gestión de proveedores',
        updateTitle: 'Actualización transacción gestión de proveedores',
        deleteTitle: 'Eliminar transacción gestión de proveedor',
        deleteMessage: '¿Seguro desea eliminar la transacción gestión de proveedor?',
      },
    },
  },
  gestionActivo: {
    maestros: {
      metodoDepreciacion: {
        title: 'Métodos de depreciación',
        createTitle: 'Creación método de depreciación',
        updateTitle: 'Actualización método de depreciación',
        deleteTitle: 'Eliminar método de depreciación',
        deleteMessage: '¿Seguro desea eliminar el método de depreciación?',
      },
      parametrosDepreciacion: {
        title: 'Parametros depreciación',
        update: 'Actualizar parametros de depreciación',
        create: 'Crear parametros de depreciación',
        deleteTitle: 'Eliminar registro',
        deleteMessage: '¿Está seguro de eliminar el registro?',
        tableDetailTitle: 'Lista de libros contables',
        tableDetailDeleteTitle: 'Eliminar parametro',
        tableDetailDeleteMessage: '¿Está seguro de eliminar este parametro?',
      },
      activoCategorizacionCuenta: {
        title: 'Cuentas contables',
        update: 'Actualizar cuentas contables',
        create: 'Crear Cuentas contables',
        deleteTitle: 'Eliminar registro',
        deleteMessage: '¿Está seguro de eliminar el registro?',
      },
      activoDistribucion: {
        title: 'Distribución de activos',
        update: 'Actualizar Distribución de activos',
        create: 'Crear Distribución de activos',
        deleteTitle: 'Eliminar registro',
        deleteMessage: '¿Está seguro de eliminar el registro?',
      },
      consecutivoGestionActivo: {
        title: 'Consecutivos gestión activos',
        createTitle: 'Creación consecutivo gestión activos',
        updateTitle: 'Actualización consecutivo gestión activos',
        deleteTitle: 'Eliminar consecutivo gestión activos',
        deleteMessage: '¿Seguro desea eliminar el consecutivo gestión activos?',
      },
      documentoGestionActivos: {
        title: 'Transacciones gestión activos',
        createTitle: 'Creación transacción gestión activos',
        updateTitle: 'Actualización transacción gestión activos',
        deleteTitle: 'Eliminar transacción gestión activos',
        deleteMessage: '¿Seguro desea eliminar la transacción gestión activos?',
        detailTitle1: 'Libros contables',
        librosContables: {
          title: 'Libros contables',
          createTitle: 'Seleccionar libros contables',
          updateTitle: 'Actualizar libros contables',
          deleteTitle: 'Eliminar libro contable',
          deleteMessage: '¿Seguro desea eliminar el libro contable?',
        },
      },
      gruposActivos: {
        title: 'Grupos activos',
        createTitle: 'Creación grupo activo',
        updateTitle: 'Actualización grupo activo',
        deleteTitle: 'Eliminar grupo activo',
        deleteMessage: '¿Seguro desea eliminar el grupo activo?',
      },
    },
    procesos: {
      conceptosMovimientoActivos: {
        title: 'Conceptos movimiento activos',
        createTitle: 'Creación concepto movimiento activo',
        updateTitle: 'Actualización concepto movimiento activo',
        deleteTitle: 'Eliminar concepto movimiento activo',
        deleteMessage: '¿Seguro desea eliminar el concepto movimiento activo?',
        detailTitle1: 'Libros contables',
        librosContables: {
          title: 'Libros contables',
          createTitle: 'Seleccionar libros contables',
          updateTitle: 'Actualizar libros contables',
          deleteTitle: 'Eliminar libro contable',
          deleteMessage: '¿Seguro desea eliminar el libro contable?',
        },
      },
    },
  },
  calidad: {
    maestros: {
      nivelesServicios: {
        title: 'Nivel de servicio',
        createTitle: 'Creación nivel servicio',
        updateTitle: 'Actualización nivel servicio',
        deleteTitle: 'Eliminar nivel servicio',
        deleteMessage: '¿Seguro desea eliminar el nivel de servicio?',
        detailTitle1: 'Empleados',
        empleados: {
          createTitle: 'Seleccionar empleados',
          updateTitle: 'Actualización empleado',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
        },
      },
      niveles: {
        title: 'Nivel',
        createTitle: 'Creación nivel',
        updateTitle: 'Actualización nivel',
        deleteTitle: 'Eliminar nivel',
        deleteMessage: '¿Seguro desea eliminar el nivel?',
      },
      prioridadesImpactos: {
        title: 'Prioridad - Impacto',
        createTitle: 'Creación prioridad - impacto',
        updateTitle: 'Actualización prioridad - impacto',
        deleteTitle: 'Eliminar prioridad - impacto',
        deleteMessage: '¿Seguro desea eliminar la prioridad - impacto?',
      },
    },
    procesos: {
      gestionSolicitudes: {
        title: 'Gestión solicitudes - PQRSF',
        createTitle: 'Creación gestión solicitud - PQRSF',
        updateTitle: 'Actualización gestión solicitud - PQRSF',
        deleteTitle: 'Eliminar gestión solicitud - PQRSF',
        deleteMessage: '¿Seguro desea eliminar la gestión solicitud - PQRSF?',
        detailTitle1: 'Archivos adjuntos',
        detailTitle2: 'Imágenes',
        detailTitle3: 'Comentarios',
        detailTitle4: 'Lecciones aprendidas',
        detailTitle5: 'Histórico de asignaciones',
        leccionesAprendidas: {
          updateTitle: 'Actualización lección aprendida',
        },
      },
      seguimientoSolicitudes: {
        title: 'Seguimiento solicitudes',
        deleteTitle: 'Eliminar seguimiento solicitud',
        deleteMessage: '¿Seguro desea eliminar el seguimiento solicitud?'
      },
      cierreSolicitudes: {
        title: 'Cierre solicitudes',
        cierreAdministrativo: 'Cierre administrativo de solicitud'
      },
    },
  },
  gobiernoCorporativo: {
    maestros: {
      consecutivo: {
        title: 'Consecutivos gobierno corporativo',
        createTitle: 'Creación consecutivo gobierno corporativo',
        updateTitle: 'Actualización consecutivo gobierno corporativo',
        deleteTitle: 'Eliminar consecutivo gobierno corporativo',
        deleteMessage: '¿Seguro desea eliminar el consecutivo gobierno corporativo?',
      },
      documento: {
        title: 'Transacciones gobierno corporativo',
        createTitle: 'Creación transacción gobierno corporativo',
        updateTitle: 'Actualización transacción gobierno corporativo',
        deleteTitle: 'Eliminar transacción gobierno corporativo',
        deleteMessage: '¿Seguro desea eliminar la transacción gobierno corporativo?',
      },
      tipoCalendario: {
        title: 'Clasificación calendarios',
        createTitle: 'Creación clasificación calendario',
        updateTitle: 'Actualización clasificación calendario',
        deleteTitle: 'Eliminar clasificación calendario',
        deleteMessage: '¿Seguro desea eliminar la clasificación calendario?',
      },
    },
    gestionEmpresarial: {
      maestros: {
        tiposOrganigramas: {
          title: 'Estructura organizacional',
          createTitle: 'Creación estructura organizacional',
          updateTitle: 'Actualización estructura organizacional',
          deleteTitle: 'Eliminar estructura organizacional',
          deleteMessage: '¿Seguro desea eliminar la estructura organizacional?',
        },
        faltas: {
          title: 'Faltas',
          createTitle: 'Creación falta',
          updateTitle: 'Actualización falta',
          deleteTitle: 'Eliminar falta',
          deleteMessage: '¿Seguro desea eliminar la falta?',
        },
        politicas: {
          title: 'Políticas',
          createTitle: 'Creación política',
          updateTitle: 'Actualización política',
          deleteTitle: 'Eliminar política',
          deleteMessage: '¿Seguro desea eliminar la política?',
          detailTitle1: 'Archivos adjuntos',
        },
        manualesCorporativos: {
          title: 'Manuales corporativos',
          createTitle: 'Creación manual corporativo',
          updateTitle: 'Actualización manual corporativo',
          deleteTitle: 'Eliminar manual corporativo',
          deleteMessage: '¿Seguro desea eliminar el manual corporativo?',
          detailTitle1: 'Archivos adjuntos',
        },
        clasificacionPolitica: {
          title: 'Clasificación políticas',
          createTitle: 'Creación clasificación política',
          updateTitle: 'Actualización clasificación política',
          deleteTitle: 'Eliminar clasificación política',
          deleteMessage: '¿Seguro desea eliminar la clasificación política?',
        },
      },
      procesos: {
        gestionEmpresarial: {
          title: 'Gestión empresarial',
          createTitle: 'Creación gestión empresarial',
          updateTitle: 'Actualización gestión empresarial',
          deleteTitle: 'Eliminar gestión empresarial',
          deleteMessage: '¿Seguro desea eliminar la gestión empresarial?',
          detailTitle1: 'Archivos adjuntos',
          objetivos: {
            title: 'Objetivos',
            createTitle: 'Creación objetivo',
            updateTitle: 'Actualización objetivo',
            deleteTitle: 'Eliminar objetivo',
            deleteMessage: '¿Seguro desea eliminar el objetivo?',
          },
        },
        reglamentoInternoTrabajo: {
          title: 'Reglamento interno de trabajo',
          createTitle: 'Creación reglamento interno de trabajo',
          updateTitle: 'Actualización reglamento interno de trabajo',
          deleteTitle: 'Eliminar reglamento interno de trabajo',
          deleteMessage: '¿Seguro desea eliminar el reglamento interno de trabajo?',
          detailTitle1: 'Archivos adjuntos',
          detailTitle2: 'Reglamento interno',
          faltas: {
            title: 'Faltas',
            createTitle: 'Creación falta',
            updateTitle: 'Actualización falta',
          },
        },
      },
    },
  },
  rpdental: {
    informes: {
      programacion: {
        title: 'Asignaciones soportes quirúrgicos',
      },
      reporteGasto: {
        title: 'Reportes gastos',
        filtersTitle: 'Seleccionar los criterios de búsqueda',
      },
    },
    maestros: {
      especialidad: {
        title: 'Especialidades',
        createTitle: 'Creación especialidad',
        updateTitle: 'Actualización especialidad',
        deleteTitle: 'Eliminar especialidad',
        deleteMessage: '¿Seguro desea eliminar la especialidad?',
      },
      diagnostico: {
        title: 'Diagnósticos',
        createTitle: 'Creación diagnóstico',
        updateTitle: 'Actualización diagnóstico',
        deleteTitle: 'Eliminar diagnóstico',
        deleteMessage: '¿Seguro desea eliminar el diagnóstico?',
        tabProductoCreate: 'Seleccionar productos',
        tabProductoUpdate: 'Actualización producto',
        tabProductoDeleteTitle: 'Eliminar producto',
        tabProductoDeleteMessage: '¿Seguro desea eliminar el producto?',
        detailTitle1: 'Productos',
      },
      terceros: {
        instituciones: {
          title: 'Instituciones',
          tableTitle: 'Listado dinstituciones',
          updateTitle: 'Actualización institución',
          createTitle: 'Creación institución',
          deleteTitle: 'Eliminar institución',
          deleteMessage: '¿Seguro desea eliminar la institución?',
        },
        pacientes: {
          title: 'Pacientes',
          tableTitle: 'Listado pacientes',
          createTitle: 'Creación paciente',
          deleteTitle: 'Eliminar paciente',
          deleteMessage: '¿Seguro desea eliminar el paciente?',
          updateTitle: 'Actualización paciente',
          messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
        },
        profesional: {
          title: 'Cirujanos',
          tableTitle: 'Listado cirujano',
          createTitle: 'Creación cirujano',
          deleteTitle: 'Eliminar cirujano',
          deleteMessage: '¿Seguro desea eliminar el cirujano?',
          updateTitle: 'Actualización cirujano',
          messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
        },
        profesionalEmpleado: {
          title: 'Empleados',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
          createTitle: 'Creación empleado',
          updateTitle: 'Actualización empleado',
          createEmpleado: 'Agregar empleado',
        },
      },
    },
    procesos: {
      reporteGastos: {
        titleForm: 'Seleccione un instrumentador',
        title: 'Reportes gastos',
        listTitle: 'Lista reporte de gastos',
        infoForm: 'Información reporte gastos',
        deleteTitle: 'Eliminar reporte gasto',
        deleteMessage: '¿Seguro desea eliminar el reporte de gasto?',
        createTitle: 'Creación reporte gasto',
        updateTitle: 'Actualización reporte gasto',
        detailTitle1: 'Composición',
        detailTitle2: 'Bioseguridad',
        detailTitle3: 'Archivos adjuntos',
        detailTitle4: 'Histórico estados',
        detailTitle5: 'Equipos',
        detailTitle6: 'Bodega institución',
        detailTitle7: 'PQRSF',
        detailTitle8: 'Firmas',
        detailTitle9: 'Servicios',
        detailTitle10: 'Imágenes',
        newSolicitud: 'Creación solicitud logística',
        newSolicitudMessage: '¿Seguro desea crear la solicitud logística?',
        tabServicioCreate: 'Seleccionar servicios',
        tabServicioDeleteTitle: 'Eliminar servicio',
        tabServicioDeleteMessage: '¿Seguro desea eliminar el servicio?',
      },
      reporteGastosBioseguridad: {
        title: 'Bioseguridad',
        deleteTitle: 'Eliminar bioseguridad',
        deleteMessage: '¿Seguro desea eliminar la bioseguridad?',
        createTitle: 'Agregar bioseguridad',
        updateTitle: 'Actualización bioseguridad',
      },
      reporteGastoContenedorBodega: {
        createTraslado: 'Seleccionar productos',
      },
      reporteGastosMaterial: {
        title: 'Material',
        deleteTitle: 'Eliminar producto',
        deleteMessage: '¿Seguro desea eliminar el producto?',
        createTitle: 'Creación material',
        updateTitle: 'Actualización material',
        tabProductoCreate: 'Seleccionar productos',
        tabProductoUpdate: 'Actualización producto',
      },
      programacion: {
        title: 'Asignaciones soportes quirúrgicos',
        createTitle: 'Creación asignación soporte quirúrgico',
        deleteTitle: 'Eliminar asignación soporte quirúrgico',
        deleteMessage: '¿Seguro desea eliminar la asignación de soporte quirúrgico?',
        updateTitle: 'Actualización asignación soporte quirúrgico',
        ordenProduccionCirugiaList: 'Información asignación soporte quirúrgico',
        tableProductos: 'Productos',
        alertMessage: 'Recuerde que el instrumentador no puede estar excluido del cliente y del cirujano, para '
          + 'eliminar la exclusión, dar clic en el botón ELIMINAR EXCLUSIÓN',
        alertMessage2: 'Para agregar una programación se debe tener en cuenta que el instrumentador '
          + 'no puede tener cirugías programadas dos horas antes o despues de la fecha y hora de la cirugía programada',
        detailTitle1: 'Histórico estados',
      },
    },
  },
  scm: {
    compra: {
      informes: {
        ordenCompra: {
          title: 'Ordenes compras',
          filterTitle: 'Selecciones los criterios de búsqueda',
        },
      },
      maestros: {
        acuerdosComerciales: {
          title: 'Acuerdos comerciales',
          createTitle: 'Creación acuerdo comercial',
          updateTitle: 'Actualización acuerdo comercial',
          deleteTitle: 'Eliminar acuerdo comercial',
          deleteMessage: '¿Seguro desea eliminar el acuerdo comercial?',
          alertMessage: 'La fecha de vigencia ha caducado',
          acuerdosComercialesPaises: {
            title: 'Países',
            deleteTitle: 'Eliminar países',
            deleteMessage: '¿Seguro desea eliminar el país?',
            createPaises: 'Seleccionar países',
          },
        },
        costo: {
          title: 'Costos',
          tableTitle: 'Listado Costos',
          createTitle: 'Creación costo',
          deleteTitle: 'Eliminar costo',
          deleteMessage: '¿Seguro desea eliminar el costo?',
          updateTitle: 'Actualización costo',
        },
        agenteAduanero: {
          title: 'Agentes aduaneros',
          createTitle: 'Creación agente aduanero',
          updateTitle: 'Actualización agente aduanero',
          deleteTitle: 'Eliminar agente aduanero',
          deleteMessage: '¿Seguro desea eliminar el agente aduanero?',
        },
        registroInvimaProductos: {
          modalExistentes: 'Productos existentes',
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Seguro desea eliminar el producto?',
          createProducto: 'Seleccionar productos existentes',
        },
        clasificacionGasto: {
          title: 'Clasificaciones gastos',
          createTitle: 'Creación clasificación gasto',
          updateTitle: 'Actualización clasificación gasto',
          deleteTitle: 'Eliminar clasificación gasto',
          deleteMessage: '¿Seguro desea eliminar la clasificación gasto?',
        },
        clasificacionProveedor: {
          title: 'Clasificaciones proveedores',
          createTitle: 'Creación clasificación proveedor',
          updateTitle: 'Actualización clasificación proveedor',
          deleteTitle: 'Eliminar clasificación proveedor',
          deleteMessage: '¿Seguro desea eliminar la clasificación proveedor?',
        },
        consecutivoCompra: {
          title: 'Consecutivos compras',
          createTitle: 'Creación consecutivo compra',
          updateTitle: 'Actualización consecutivo compra',
          deleteTitle: 'Eliminar consecutivo compra',
          deleteMessage: '¿Seguro desea eliminar el consecutivo compra?',
        },
        requisitoProveedor: {
          title: 'Requisitos proveedores',
          createTitle: 'Creación requisitos proveedores',
          updateTitle: 'Actualización requisitos proveedores',
          deleteTitle: 'Eliminar requisitos proveedores',
          deleteMessage: '¿Seguro desea eliminar el requisito proveedor?',
        },
        criterioEvaluacion: {
          title: 'Criterios evaluación',
          createTitle: 'Creación criterio evaluación',
          updateTitle: 'Actualización criterio evaluación',
          deleteTitle: 'Eliminar criterio evaluación',
          deleteMessage: '¿Seguro desea eliminar el criterio evaluación?',
        },
        documentoCompra: {
          title: 'Transacciones compras',
          createTitle: 'Creación transacción compra',
          updateTitle: 'Actualización transacción compra',
          deleteTitle: 'Eliminar transacción compra',
          deleteMessage: '¿Seguro desea eliminar la transacción compra?',
        },
        gastos: {
          title: 'Gastos',
          createTitle: 'Creación gasto',
          updateTitle: 'Actualización gasto',
          deleteTitle: 'Eliminar gasto',
          deleteMessage: '¿Seguro desea eliminar el gasto?',
          detailTitle1: 'Centro de costos',
          detailTitle2: 'Retenciones',
          detailTitle3: 'Impuestos',
          detailTitle4: 'Procesos',
        },
        gastoscentrocostos: {
          title: 'Centros de costos',
          createTitle: 'Creación centro de costo',
          updateTitle: 'Actualización centro de costo',
          deleteTitle: 'Eliminar centro de costo',
          deleteMessage: '¿Seguro desea eliminar el centro de costo?',
          createCentroCosto: 'Seleccionar centros de costos',
          updateCentroCosto: 'Actualizar centro de costo',
        },
        gastoEntidad: {
          createTitle: 'Creación de gasto',
          deleteTitle: 'Eliminar gasto',
          deleteMessage: '¿Seguro desea eliminar este gasto?',
        },
        gastosProcesos: {
          createTitle: 'Selección de procesos',
          updateTitle: 'Configuración gasto',
          deleteTitle: 'Eliminar proceso',
          deleteMessage: '¿Seguro desea eliminar este proceso?',
        },
        incoterms: {
          title: 'Incoterms',
          createTitle: 'Creación incoterms',
          updateTitle: 'Actualización incoterms',
          deleteTitle: 'Eliminar incoterms',
          deleteMessage: '¿Seguro desea eliminar el incoterms?',
        },
        proveedor: {
          title: 'Proveedores',
          createTitle: 'Creación proveedor',
          updateTitle: 'Actualización proveedor',
          deleteTitle: 'Eliminar proveedor',
          deleteMessage: '¿Seguro desea eliminar el proveedor?',
          detailTitle1: 'Conceptos Tributarios',
          detailTitle2: 'Actividades económicas',
          detailTitle3: 'Atributos comerciales',
          detailTitle4: 'Archivos adjuntos',
          detailTitle5: 'Impuestos',
          detailTitle6: 'Retenciones',
          detailTitle7: 'Cuenta corriente',
          detailTitle8: 'Calendario trabajo',
          detailTitleOrdenesCompra: 'Ordenes compra',
          detalTitleCuentaCorrienteNotaDebito: 'Notas débito',
          detalTitleCuentaCorrienteNotaCredito: 'Notas crédito',
          detalTitleFacturaRecibida: 'Facturas recibidas',
          detalTitleCuentaCorrienteComprobantes: 'Comprobantes contables',
          messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
          proveedorAcuerdoComercial: {
            title: 'Acuerdos comerciales',
            deleteTitle: 'Eliminar acuerdo comercial',
            deleteMessage: '¿Seguro desea eliminar el acuerdo comercial?',
            createAcuerdo: 'Seleccionar acuerdos comerciales',
          },
          calendarioTrabajo: {
            createTitle: 'Creación calendario de trabajo',
            updateTitle: 'Actualización calendario de trabajo',
            deleteTitle: 'Eliminar calendario de trabajo',
            deleteMessage: '¿Seguro desea eliminar el calendario de trabajo?',
          },
        },
        registroInvima: {
          title: 'Registros invima',
          createTitle: 'Creación registro invima',
          updateTitle: 'Actualización registro invima',
          informativeTitle: 'Información registro invima',
          deleteTitle: 'Eliminar registro invima',
          deleteMessage: '¿Seguro desea eliminar el registro invima?',
          detailTitle1: 'Productos existentes',
          detailTitle2: 'Productos nuevos',
        },
        requisitosAduaneros: {
          title: 'Requisitos aduaneros',
          createTitle: 'Creación requisito aduanero',
          updateTitle: 'Actualización requisito aduanero',
          deleteTitle: 'Eliminar requisito aduanero',
          deleteMessage: '¿Seguro desea eliminar el requisito aduanero?',
          detailTitle1: 'Archivos adjuntos',
          deleteDocumentTitle: 'Eliminar documento',
          deleteDocumentMessage: '¿Seguro desea eliminar el documento?',
        },
        requisitosImportacion: {
          title: 'Requisitos importación',
          createTitle: 'Creación requisito importación',
          updateTitle: 'Actualización requisito importación',
          deleteTitle: 'Eliminar requisito importación',
          deleteMessage: '¿Seguro desea eliminar el requisito importación?',
        },
        unidadEmpaque: {
          title: 'Unidades de empaques',
          createTitle: 'Creación unidad de empaque',
          updateTitle: 'Actualización unidad de empaque',
          deleteMessage: '¿Seguro desea eliminar la unidad de empaque?',
          deleteTitle: 'Eliminar unidad de empaque',
        },
      },
      procesos: {
        ordenCompra: {
          title: 'Ordenes compras',
          createTitle: 'Creación orden compra',
          updateTitle: 'Actualización orden compra',
          deleteTitle: 'Eliminar orden compra',
          deleteMessage: '¿Seguro desea eliminar la orden compra?',
          tableTitle: 'Listado de ordenes de compras',
          sendEmailTitle: 'Enviar correo electronico',
          detailTitle1: 'Productos',
          detailTitle2: 'Gastos',
          detailTitle3: 'Impuestos tributarios',
          detailTitle4: 'Solicitud interna de recursos (SIR)',
          detailTitle5: 'Tipo de pago proveedor',
          detailTitle6: 'Requisitos aduaneros',
          detailTitle7: 'Requisitos importación',
          detailTitle8: 'Histórico estados',
          detailTitle9: 'Archivos adjuntos',
          detailTitle10: 'Imágenes',
          detailTitle11: 'Encuesta',
          detailTitle12: 'Gastos',
          detailTitle13: 'Servicios',
          detailDeleteTitle: 'Eliminar concepto tributario',
          detailDeleteMessage: '¿Está seguro de eliminar este concepto tributario?',
        },
        compraProducto: {
          title: 'Productos',
          createTitle: 'Creación de producto',
          updateTitle: 'Actualización de producto',
          updateTitleModal: 'Actualización',
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Seguro desea eliminar el producto?',
          detailTitle1: 'Impuestos y retenciones',
          detailTitle2: 'Retenciones',
        },
        compraGastos: {
          title: 'Gastos',
          createTitle: 'Creación de gasto',
          updateTitle: 'Actualización de gasto',
          updateTitleModal: 'Actualización',
          deleteTitle: 'Eliminar gasto',
          deleteMessage: '¿Seguro desea eliminar el gasto?',
          detailTitle1: 'Impuestos',
          detailTitle2: 'Retenciones',
        },
        ordenCompraSir: {
          title: 'SIR',
          deleteTitle: 'Eliminar SIR',
          deleteMessage: '¿Seguro desea eliminar la SIR?',
        },
        ordenCompraTipoPagoProveedor: {
          tableTitle: 'Listado de tipos pagos proveedor',
          createTitle: 'Agregar tipo pago proveedor',
          updateTitle: 'Actualizar tipo pago proveedor',
          deleteTitle: 'Eliminar tipo pago proveedor',
          deleteMessage: '¿Seguro desea eliminar el tipo pago proveedor?',
        },
        ordenCompraRequisitoImportacion: {
          deleteTitle: 'Eliminar requisito importación',
          deleteMessage: '¿Seguro desea eliminar este requisito de importación?',
          deleteDocumentTitle: 'Eliminar documento',
          deleteDocumentMessage: '¿Seguro desea eliminar este documento?',
        },
        ordenCompraServicio: {
          title: 'Servicios',
          createTitle: 'Seleccionar servicios',
          updateTitle: 'Actualización servicios',
          deleteTitle: 'Eliminar servicios',
          deleteMessage: '¿Seguro desea eliminar el servicio?',
        },
        sirs: {
          title: 'SIR',
          tableTitle: 'Listado SIR',
          createTitle: 'Creación SIR',
          updateTitle: 'Actualización SIR',
          deleteTitle: 'Eliminar SIR',
          deleteMessage: '¿Seguro desea eliminar la SIR?',
          detailTitle1: 'Productos',
          detailTitle6: 'Servicios',
          detailTitle2: 'Archivos adjuntos',
          detailTitle3: 'Histórico estados',
          detailTitle4: 'imágenes',
          detailTitle5: 'Gastos',
        },
        productosSirs: {
          title: 'Productos',
          createTitle: 'Seleccionar productos',
          updateTitle: 'Actualización producto',
          deleteTitle: 'Eliminar productos',
          deleteMessage: '¿Seguro desea eliminar el producto?',
        },
        serviciosSirs: {
          title: 'Servicios',
          createTitle: 'Seleccionar servicios',
          updateTitle: 'Actualización servicios',
          deleteTitle: 'Eliminar servicios',
          deleteMessage: '¿Seguro desea eliminar el servicio?',
        },
        gastoImportacion: {
          title: 'Gasto de importación',
          tableTitle: 'Listado de gasto de importación',
          createTitle: 'Creación de gasto de importación',
          deleteTitle: 'Anular gasto de importación',
          deleteMessage: '¿Está seguro de anular este registro?',
          updateTitle: 'Actualización de gasto de importación',
          tableGastoTitle: 'Listado de gastos',
          tableGastoUpdate: 'Modificar gasto',
          tableDetailUpdate: 'Gasto',
          tableImpuestosTitle: 'Listado de conceptos tributarios',
          tableDetailDeleteTitle: 'Eliminar registro',
          tableDetailDeleteMessage: '¿Está seguro de eliminar este registro?',
          tableImpuestoUpdate: 'Modificar impuesto',
          tableEmbarqueCreate: 'Agregar embarque',
          tableEmbarqueUpdate: 'Modificar embarque',
          changeEstado: 'Cambio de estado',
          detailTitle1: 'Historico estados',
        },
      },
    },
    inventario: {
      inventarioGeneral: {
        consultas: {
          existencias: {
            title: 'Existencias',
          },
          activoFijo: {
            title: 'Activos fijos',
          },
        },
        maestros: {
          consecutivoInventarioGeneral: {
            title: 'Consecutivos inventario general',
            createTitle: 'Creación consecutivo inventario general',
            updateTitle: 'Actualización consecutivo inventario general',
            deleteTitle: 'Eliminar consecutivo inventario general',
            deleteMessage: '¿Seguro desea eliminar el consecutivo inventario general?',
          },
          documentoInventarioGeneral: {
            title: 'Transacciones inventario general',
            createTitle: 'Creación transacción inventario general',
            updateTitle: 'Actualización transacción inventario general',
            deleteTitle: 'Eliminar transacción inventario general',
            deleteMessage: '¿Seguro desea eliminar la transacción inventario general?',
          },
          tipoMantenimiento: {
            title: 'Tipos mantenimientos',
            createTitle: 'Creación tipo mantenimiento',
            updateTitle: 'Actualización tipo mantenimiento',
            deleteTitle: 'Eliminar tipo mantenimiento',
            deleteMessage: '¿Seguro desea eliminar el tipo mantenimiento?',
          },
          tipoProductoInventarioGeneral: {
            title: 'Tipos productos',
            createTitle: 'Creación tipo producto',
            updateTitle: 'Actualización tipo producto',
            deleteTitle: 'Eliminar tipo producto',
            deleteMessage: '¿Seguro desea eliminar el tipo producto?',
          },
        },
        procesos: {
          mantenimiento: {
            title: 'Mantenimientos',
            tableTitle: 'Listado de mantenimiento',
            createTitle: 'Creación mantenimiento',
            deleteTitle: 'Eliminar mantenimiento',
            deleteMessage: '¿Seguro desea eliminar el mantenimiento?',
            updateTitle: 'Actualización mantenimiento',
            detailTitle1: 'Archivos adjuntos',
            detailTitle2: 'Histórico estados',
            detailTitle3: 'Productos',
            detailTitle4: 'Histórico asignaciones',
            detailTitle5: 'Comentarios',
            detailTitle6: 'Gastos',
          },
          mantenimientoExistencia: {
            title: 'Productos',
            createTitle: 'Creación producto',
            updateTitle: 'Actualización producto',
            deleteTitle: 'Eliminar producto',
            deleteMessage: '¿Seguro desea eliminar el producto?',
          },
          entradasSalidas: {
            E: {
              MP: {
                title: 'Entradas',
                tableTitle: 'Listado de entradas',
                createTitle: 'Creación de entrada',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización de entrada',
                detailTitle1: 'Productos',
                detailTitle2: 'Gastos',
              },
              IG: {
                title: 'Entradas',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación entrada',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización entrada',
                detailTitle1: 'Productos',
                detailTitle2: 'Histórico estados',
                detailTitle3: 'Gastos',
              },
              PT: {
                title: 'Entradas',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación entradas',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización entrada',
                detailTitle1: 'Productos',
                detailTitle2: 'Histórico estados',
                detailTitle3: 'Gastos',
              },
            },
            S: {
              MP: {
                title: 'Salidas',
                tableTitle: 'Listado de salidas',
                createTitle: 'Creación de salida',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización de salida',
                detailTitle1: 'Productos',
                detailTitle2: 'Gastos',
              },
              IG: {
                title: 'Salidas',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación salida',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización salida',
                detailTitle1: 'Productos',
                detailTitle2: 'Histórico estados',
                detailTitle3: 'Gastos',
              },
              PT: {
                title: 'Salidas',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación salida',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización salida',
                detailTitle1: 'Productos',
                detailTitle2: 'Histórico estados',
                detailTitle3: 'Gastos',
              },
            },
            tabProductos: {
              title: 'Inventario',
              tableTitle: 'Listado de inventarios',
              createTitle: 'Creación de inventario',
              deleteTitle: 'Eliminar inventario',
              deleteMessage: '¿Está seguro de eliminar este inventario?',
              updateTitle: 'Actualización de inventario',
              tableDetailTitle: 'Listado de productos',
              tableDetailCreate: 'Seleccionar productos',
              tableDetailUpdate: 'Modificar inventario producto',
              tableDetailDeleteTitle: 'Eliminar producto',
              tableDetailDeleteMessage: '¿Seguro desea eliminar el producto?',
            },
          },
          trasladoInventarioGeneral: {
            title: 'Traslados',
            route: '/distribucion/inventariogeneral/traslados',
            createTitle: 'Creación traslado de inventario general',
            updateTitle: 'Actualización traslado de inventario general',
            deleteTitle: 'Eliminar traslado de inventario general',
            deleteMessage: '¿Seguro desea eliminar el traslado  de inventario general?',
            tableTitle: 'Listado de traslados',
            routeProductos: 'inventario/productos',
            detailTitle1: 'Productos',
            detailTitle2: 'Histórico estados',
            detailTitle3: 'Gastos',
          },
        },
      },
      generales: {
        almacen: {
          title: 'Almacenes',
          createTitle: 'Creación almacén',
          updateTitle: 'Actualización almacén',
          deleteTitle: 'Eliminar almacén',
          deleteMessage: '¿Seguro desea eliminar el almacén?',
          titleList: 'Listado de almacenes',
          detalTitle1: 'Zonas',
          detalTitle2: 'Ubicaciones',
          detalTitle3: 'Empleados',
          detailTitle4: 'Contenedores',
          createAlmacen: 'Seleccionar almacén',
        },
        almacenEmpleado: {
          title: 'Empleados',
          createTitle: 'Creación empleado',
          updateTitle: 'Actualización empleado',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
          createEmpleado: 'Seleccionar empleados',
        },
        almacenUbicaciones: {
          title: 'Ubicaciones',
          createTitle: 'Creación ubicación',
          updateTitle: 'Actualización ubicación',
          deleteTitle: 'Eliminar ubicacón',
          deleteMessage: '¿Seguro desea eliminar la ubicación?',
        },
        almacenZonas: {
          title: 'Zonas',
          createTitle: 'Creación zona',
          updateTitle: 'Actualización zona',
          deleteTitle: 'Eliminar zona',
          deleteMessage: '¿Seguro desea eliminar la zona?',
          createZona: 'Seleccionar zonas',
        },
        bloque: {
          title: 'Bloques',
          createTitle: 'Crear bloque',
          updateTitle: 'Actualización del bloque',
          deleteTitle: 'Eliminar el bloque',
          deleteMessage: '¿Está seguro de eliminar este bloque?',
          titleList: 'Listado de bloques',
          detalTitle1: 'Almacén',
          detalTitle2: 'Empleados',
        },
        bloqueAlmacen: {
          title: 'Almacen',
          createTitle: 'Crear almacén',
          updateTitle: 'Actualización estado de la almacén',
          deleteTitle: 'Eliminar esta almacén',
          deleteMessage: '¿Está seguro de eliminar esta almacén?',
        },
        bodega: {
          title: 'Bodegas',
          createTitle: 'Creación bodega',
          updateTitle: 'Actualización bodega',
          deleteTitle: 'Eliminar bodega',
          deleteMessage: '¿Seguro desea eliminar la bodega?',
          titleList: 'Listado bodegas',
          detalTitle1: 'Almacén',
          detalTitle2: 'Contenedores',
          detalTitle3: 'Productos',
        },
        bodegaAlmacen: {
          title: 'Bodega',
          createTitle: 'Crear bodega',
          updateTitle: 'Actualización estado de la bodega',
          deleteTitle: 'Eliminar esta bodega',
          deleteMessage: '¿Está seguro de eliminar esta bodega?',
        },
        codigosEan: {
          title: 'Códigos Ean',
        },
        clasificacion1: {
          title: 'Clasificación 1',
          createTitle: 'Creación clasificación 1',
          updateTitle: 'Actualización clasificación 1',
          deleteTitle: 'Eliminar clasificación 1',
          deleteMessage: '¿Seguro desea eliminar la clasificación 1?',
        },
        clasificacion2: {
          title: 'Clasificación 2',
          createTitle: 'Creación clasificación 2',
          updateTitle: 'Actualización clasificación 2',
          deleteTitle: 'Eliminar clasificación 2',
          deleteMessage: '¿Seguro desea eliminar la clasificación 2?',
        },
        clasificacion3: {
          title: 'Clasificación 3',
          createTitle: 'Creación clasificación 3',
          updateTitle: 'Actualización clasificación 3',
          deleteTitle: 'Eliminar clasificación 3',
          deleteMessage: '¿Seguro desea eliminar la clasificación 3?',
        },
        centroDistribucion: {
          title: 'Centros de distribución',
          tableTitle: 'Listado centros de distribución',
          updateTitle: 'Actualización centro de distribución',
          createTitle: 'Creación centro de distribución',
          deleteTitle: 'Eliminar centro de distribución',
          deleteMessage: '¿Seguro desea eliminar el centro de distribución?',
          centroDistribucionCiudad: {
            deleteTitle: 'Eliminar ciudad',
            deleteMessage: '¿Seguro desea eliminar la ciudad?',
          },
        },
        clasificacionesInventario: {
          title: 'Clasificaciones inventario',
        },
        informes: {
          bodegasContenedores: {
            title: 'Bodegas',
            formTitle: 'Seleccione los criterios de búsqueda',
          },
          tomaAjusteInventario: {
            historicoAjustes: {
              title: 'Históricos ajustes inventario',
              formTitle: 'Seleccione los criterios de búsqueda',
            },
          },
          productosProximosVencer: {
            title: 'Productos próximos a vencer',
          },
        },
        perfilLogistico: {
          createTitle: 'Creación perfil logístico',
          deleteDetailMessage: '¿Está seguro de eliminar este detalle?',
          noDetailsFound: 'No se han encontrado elementos',
          title: 'Perfil logístico',
          deleteMessage: '¿Está seguro de eliminar este perfil logístico?',
          updateTitle: 'Actualización perfil logístico',
          deleteDetailTitle: 'Eliminar detalle perfil logístico',
          deleteTitle: 'Eliminar perfil logístico',
          detailTittle1: 'Detalles perfil logístico',
        },
        perfilLogisticoDetalle: {
          title: 'detalle',
          createTitle: 'Creación de detalle',
          updateTitle: 'Actualización de detalle',
          deleteTitle: 'Eliminar detalle',
          deleteMessage: '¿Está seguro de eliminar este detalle?',
        },
        procesos: {
          tomaDeinventario: {
            title: 'Programaciones tomas inventario',
            updateTitle: 'Actualización programación toma inventario',
            createTitle: 'Creación programación toma inventario',
            deleteTitle: 'Eliminar programación toma inventario',
            deleteMessage: '¿Seguro desea eliminar la programación toma inventario?',
            changeEstado: 'Cambio de estado',
            detailTitle1: 'Detalle',
            detailTitle2: 'Histórico estados',
            detailTitle3: 'Gastos',
            detalle: {
              deleteTitle: 'Eliminar producto',
              deleteMessage: '¿Seguro que desea eliminar este producto del detalle?',
            },
          },
          ajusteInventario: {
            title: 'Ajustes inventario',
            detailTitle1: 'Histórico estados',
            detailTitle2: 'Gastos',
            ajusteTitle: 'Realizar ajuste de inventario',
            ajusteMessage: '¿Seguro desea realizar el ajuste de inventario?',
            ajusteMessage1: 'La salida de inventario se realizará automáticamente',
            deleteTitle: 'Eliminar ajuste de inventario',
            deleteMessage: '¿Seguro desea eliminar el ajuste de inventario?',
            createTomaNueva: 'Generar toma sobre este ítem',
          },
          ejecucionTomaInventario: {
            title: 'Ejecuciones tomas inventario',
            detailTitle1: 'Histórico estados',
            detailTitle2: 'Gastos',
          },
          solicitudesTraslado: {
            title: 'Solicitudes traslados',
            deleteTitle: 'Eliminar solicitud traslado',
            deleteMessage: '¿Seguro desea eliminar la solicitud traslado?',
            createTitle: 'Creación solicitud traslado',
            updateTitle: 'Actualización solicitud traslado',
            detailTitle1: 'Productos',
            deleteProductoTitle: 'Eliminar producto',
            deleteProductoMessage: '¿Seguro desea eliminar el producto?',
            detailTitle2: 'Contenedores',
            detailTitle3: 'Gastos',
            detailTitle4: 'Histórico estados',
            deleteEquipoTitle: 'Eliminar contenedor',
            deleteEquipoMessage: '¿Seguro desea eliminar el contenedor?',
            tabCreateProducto: 'Seleccionar productos',
            tabCreateEquipo: 'Seleccionar contenedores',
          },
        },
        tipoInventario: {
          title: 'Tipos inventarios',
          createTitle: 'Creación tipo inventario',
          updateTitle: 'Actualización tipo inventario',
          deleteTitle: 'Eliminar tipo inventario',
          deleteMessage: '¿Seguro desea eliminar el tipo inventario?',
        },
        unidadMedida: {
          title: 'Unidades de medida',
          createTitle: 'Creación unidad de medida',
          updateTitle: 'Actualización unidad de medida',
          deleteTitle: 'Eliminar unidad de medida',
          deleteMessage: '¿Seguro desea eliminar la unidad de medida?',
        },
      },
      materiaPrima: {
        consultas: {
          existencias: {
            title: 'Existencias',
          },
        },
        maestros: {
          consecutivoMateriaPrima: {
            title: 'Consecutivos materia prima',
            createTitle: 'Creación consecutivo materia prima',
            updateTitle: 'Actualización consecutivo materia prima',
            deleteTitle: 'Eliminar consecutivo materia prima',
            deleteMessage: '¿Seguro desea eliminar el consecutivo materia prima?',
          },
          documentoMateriaPrima: {
            title: 'Transacciones materia prima',
            createTitle: 'Creación transacción materia prima',
            updateTitle: 'Actualización transacción materia prima',
            deleteTitle: 'Eliminar transacción materia prima',
            deleteMessage: '¿Seguro desea eliminar la transacción materia prima?',
          },
          tipoProductoMateriaPrima: {
            title: 'Tipos productos',
            createTitle: 'Creación tipo producto materia prima',
            updateTitle: 'Actualización tipo producto materia prima',
            deleteTitle: 'Eliminar tipo producto materia prima',
            deleteMessage: '¿Seguro desea elimiar el tipo producto materia prima?',
          },
        },
        procesos: {
          entradasSalidas: {
            E: {
              MP: {
                title: 'Entradas de materia prima',
                tableTitle: 'Listado de entradas',
                createTitle: 'Creación de entradas de materia prima',
                deleteTitle: 'Eliminar entrada de materia prima',
                deleteMessage: '¿Está seguro de eliminar esta entrada de materia prima?',
                updateTitle: 'Actualización de entrada de materia prima',
                detailTitle1: 'Productos',
              },
              IG: {
                title: 'Entradas de inventario general',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación entradas de inventario general',
                deleteTitle: 'Eliminar entrada de inventario general',
                deleteMessage: '¿Seguro desea eliminar la entrada de inventario general?',
                updateTitle: 'Actualización entrada de inventario general',
                detailTitle1: 'Productos',
              },
              PT: {
                title: 'Entradas de producto terminado',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación entradas de producto terminado',
                deleteTitle: 'Eliminar entrada de producto terminado',
                deleteMessage: '¿Seguro desea eliminar la entrada de producto terminado?',
                updateTitle: 'Actualización entrada de producto terminado',
                detailTitle1: 'Productos',
              },
            },
            S: {
              MP: {
                title: 'Salida de materia prima',
                tableTitle: 'Listado de salidas',
                createTitle: 'Creación de salidas de materia prima',
                deleteTitle: 'Eliminar salida de materia prima',
                deleteMessage: '¿Está seguro de eliminar esta salida de materia prima?',
                updateTitle: 'Actualización de salida de materia prima',
                detailTitle1: 'Productos',
              },
              IG: {
                title: 'Salidas de inventario general',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación salidas de inventario general',
                deleteTitle: 'Eliminar salida de inventario general',
                deleteMessage: '¿Seguro desea eliminar la salida de inventario general?',
                updateTitle: 'Actualización salida de inventario general',
                detailTitle1: 'Productos',
              },
              PT: {
                title: 'Salidas de producto terminado',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación salidas de producto terminado',
                deleteTitle: 'Eliminar salida de producto terminado',
                deleteMessage: '¿Seguro desea eliminar la salida de producto terminado?',
                updateTitle: 'Actualización salida de producto terminado',
                detailTitle1: 'Productos',
              },
            },
            tabProductos: {
              title: 'Inventario',
              tableTitle: 'Listado de inventarios',
              createTitle: 'Creación de inventario',
              deleteTitle: 'Eliminar inventario',
              deleteMessage: '¿Está seguro de eliminar este inventario?',
              updateTitle: 'Actualización de inventario',
              tableDetailTitle: 'Listado de productos',
              tableDetailCreate: 'Agregar productos',
              tableDetailUpdate: 'Modificar inventario producto',
              tableDetailDeleteTitle: 'Eliminar producto',
              tableDetailDeleteMessage: '¿Está seguro de eliminar este producto?',
            },
          },
          trasladoMateriaPrima: {
            title: 'Traslados',
            route: '/inventario/proceso/materiaprima/traslados',
            createTitle: 'Creación traslado',
            updateTitle: 'Actualización traslado',
            deleteTitle: 'Eliminar traslado',
            deleteMessage: '¿Seguro desea eliminar el traslado?',
            tableTitle: 'Listado de traslados',
            routeProductos: 'inventario/productos',
            detailTitle1: 'Productos',
            detailTitle2: 'Gastos',
          },
        },
      },
      productoTerminado: {
        consultas: {
          existencias: {
            title: 'Existencias',
            warningTitle: 'No se han encontrado atributos de control para este formulario',
            searchTitle: 'Por favor seleccione todos los valores del formulario',
            switchTitle: 'Seleccione los criterios de busqueda',
            noSelected: 'No hay campos seleccionados',
          },
          inventarioValorizable: {
            title: 'Inventario Valorizable',
          },
          reporteUsos: {
            title: 'Reportes de usos',
          },
          semaforo: {
            title: 'Semáforo',
            warningTitle: 'No se han encontrado atributos de control para este formulario',
          },
          kardex: {
            title: 'Kardex',
            busqueda: 'Busqueda kardex avanzada',
            titleForm: 'Filtro de búsqueda',
            warningTitle: 'No se han encontrado atributos de control para este formulario',
          },
          trazabilidad: {
            title: 'Trazabilidad por recepción y producto',
            titleForm: 'Filtro de búsqueda',
            warningTitle: 'No se han encontrado registros para esta consulta',
          },
          reservas: {
            title: 'Reservas',
          },
          mercanciaEnTransito: {
            title: 'Mercancías en tránsito',
          },
        },
        maestros: {
          agrupacion: {
            title: 'Agrupación',
            tableTitle: 'Listado de agrupaciones',
            deleteTitle: 'Eliminar agrupación',
            deleteMessage: '¿Está seguro de eliminar esta agrupación?',
            createTitle: 'Creación agrupación',
            updateTitle: 'Actualización agrupación',
            tableVariantsTitle: 'Listado de variantes',
            tableVariantsCreate: '',
            tableVariantsUpdate: 'Actualizar variante',
            tableVariantsDeleteTitle: 'Eliminar variante',
            tableVariantsDeleteMessage: '¿Está seguro de eliminar esta variante?',
            deletePerfilLogisticoTitle: 'Eliminar perfil logístico',
            deletePerfilLogisticoMessage: '¿Está seguro de eliminar este perfil logístico?',
            codigoBarrasFormCreate: 'Crear código de barras',
            codigoBarrasFormUpdate: 'Actualizar código de barras',
            deleteCodigoEanTitle: 'Eliminar código EAN',
            deleteCodigoEanMessage: '¿Está seguro de eliminar este código EAN?',
            createControlAtributos: 'Listado de atributos',
            deleteControlAtributos: 'Eliminar atributo de control',
            deleteControlAtributosMessage: '¿Está seguro de eliminar el este atributo de control?',
            tableProductoTitle: 'Listado de productos',
            tableProductoCreate: 'Seleccionar productos',
            tableProductoUpdate: 'Modificar producto',
            tableProductoDeleteTitle: 'Eliminar producto',
            tableProductoDeleteMessage: '¿Seguro desea eliminar el producto terminado?',
            formPerfilLogistico: 'Listado de perfiles',
            messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
            detailTittle1: 'Atributos comerciales',
            detailTittle2: 'Variantes',
            detailTittle3: 'Perfil logístico',
            detailTittle4: 'Atributos de control',
            detailTittle5: 'Composiciones',
            detailTittle6: 'Imagenes',
            validationCantidades: 'Cantidad mínima debe ser menor a la cantidad',
          },
          categoria: {
            title: 'Categorías',
          },
          categoriaComposicion: {
            title: 'Categorías composición',
            createTitle: 'Creación categoría composición',
            updateTitle: 'Actualización categoría composición',
            deleteTitle: 'Eliminar categoría composición',
            deleteMessage: '¿Seguro desea eliminar la categoría composición?',
          },
          consecutivoProductoTerminado: {
            title: 'Consecutivos producto terminado',
            createTitle: 'Creación consecutivo producto terminado',
            updateTitle: 'Actualización consecutivo producto terminado',
            deleteTitle: 'Eliminar consecutivo producto terminado',
            deleteMessage: '¿Seguro desea eliminar el consecutivo producto terminado?',
          },
          documentoProductoTerminado: {
            title: 'Transacciones producto terminado',
            createTitle: 'Creación transacción producto terminado',
            updateTitle: 'Actualización transacción producto terminado',
            deleteTitle: 'Eliminar transacción producto terminado',
            deleteMessage: '¿Seguro desea eliminar la transacción de producto terminado?',
          },
          tipoProductoTerminado: {
            title: 'Tipos productos',
            createTitle: 'Creación tipo producto',
            updateTitle: 'Actualización tipo producto',
            deleteTitle: 'Eliminar tipo producto',
            deleteMessage: '¿Seguro desea elimiar el tipo producto?',
          },
        },
        procesos: {
          entradasSalidas: {
            E: {
              MP: {
                title: 'Entradas',
                tableTitle: 'Listado de entradas',
                createTitle: 'Creación de entrada',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización de entrada',
                detailTitle1: 'Productos',
              },
              IG: {
                title: 'Entradas',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación de entrada',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización de entrada',
                detailTitle1: 'Productos',
              },
              PT: {
                title: 'Entradas',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación entrada',
                deleteTitle: 'Eliminar entrada',
                deleteMessage: '¿Seguro desea eliminar la entrada?',
                updateTitle: 'Actualización entrada',
                detailTitle1: 'Productos',
              },
            },
            S: {
              MP: {
                title: 'Salidas',
                tableTitle: 'Listado de salidas',
                createTitle: 'Creación de salida',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización de salida',
                detailTitle1: 'Productos',
              },
              IG: {
                title: 'Salidas',
                tableTitle: 'Listado de inventario general',
                createTitle: 'Creación de salida',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización de salida',
                detailTitle1: 'Productos',
              },
              PT: {
                title: 'Salidas',
                tableTitle: 'Listado de producto terminado',
                createTitle: 'Creación de salidas',
                deleteTitle: 'Eliminar salida',
                deleteMessage: '¿Seguro desea eliminar la salida?',
                updateTitle: 'Actualización de salida',
                detailTitle1: 'Productos',
              },
            },
            tabProductos: {
              title: 'Inventario',
              tableTitle: 'Listado de inventarios',
              createTitle: 'Creación de inventario',
              deleteTitle: 'Eliminar inventario',
              deleteMessage: '¿Está seguro de eliminar este inventario?',
              updateTitle: 'Actualización de inventario',
              tableDetailTitle: 'Listado de productos',
              tableDetailCreate: 'Seleccionar productos',
              tableDetailUpdate: 'Modificar inventario producto',
              tableDetailDeleteTitle: 'Eliminar producto',
              tableDetailDeleteMessage: '¿Seguro desea eliminar el producto?',
              tabCreateProducto: 'Agregar producto',
            },
          },
          productoTraslado: {
            title: 'Productos',
            createTitle: 'Agregar producto',
            updateTitle: 'Actualización de producto',
            deleteTitle: 'Eliminar este producto',
            deleteMessage: '¿Seguro desea eliminar el producto?',
          },
          trasladoProductoTerminado: {
            title: 'Traslados',
            route: '/inventario/procesos/productoterminado/traslados',
            createTitle: 'Creación traslado de producto terminado',
            updateTitle: 'Actualización traslado de producto terminado',
            deleteTitle: 'Eliminar traslado de producto terminado',
            deleteMessage: '¿Seguro desea eliminar el traslado de producto terminado?',
            tableTitle: 'Listado de traslados',
            routeProductos: 'inventario/productos',
            detailTitle1: 'Productos',
            detailTitle2: 'Histórico estados',
            detailTitle3: 'Gastos',
          },
        },
      },
    },
    wms: {
      maestros: {
        clasificacionTransportador: {
          title: 'Clasificaciones transportadores',
          createTitle: 'Creación clasificación transportador',
          updateTitle: 'Actualización clasificación transportador',
          deleteTitle: 'Eliminar clasificación transportador',
          deleteMessage: '¿Seguro desea eliminar la clasificación transportador?',
        },
        consecutivoWms: {
          title: 'Consecutivos Logística',
          createTitle: 'Creación consecutivo logística',
          updateTitle: 'Actualización consecutivo logística',
          deleteTitle: 'Eliminar consecutivo logística',
          deleteMessage: '¿Seguro desea eliminar el consecutivo logística?',
        },
        contenedores: {
          title: 'Contenedores',
          tableTitle: 'Listado contenedores',
          createTitle: 'Creación contenedor',
          deleteTitle: 'Eliminar contenedor',
          deleteMessage: '¿Seguro desea eliminar el contenedor?',
          updateTitle: 'Actualización contenedor',
          detailTitle1: 'Contenido',
          detailTitle2: 'Imágenes',
          detailTitle3: 'Histórico de traslados',
          detailTitle4: 'Histórico de usos',
        },
        documentoWms: {
          title: 'Transacciones logística',
          createTitle: 'Creación transacción logística',
          updateTitle: 'Actualización transacción logística',
          deleteTitle: 'Eliminar transacción logística',
          deleteMessage: '¿Seguro desea eliminar la transacción logística?',
        },
        puerto: {
          title: 'Puertos',
        },
        tipoCodigoBarras: {
          title: 'Tipo código de barras',
        },
        tipoContenedor: {
          title: 'Tipos contenedores',
          createTitle: 'Creación tipo contenedor',
          updateTitle: 'Actualización tipo contenedor',
          deleteTitle: 'Eliminar tipo contenedor',
          deleteMessage: '¿Seguro desea eliminar el tipo contenedor?',
        },
        tipoTransporte: {
          title: 'Tipos transportes',
          createTitle: 'Creación tipo transporte',
          updateTitle: 'Actualización tipo transporte',
          deleteTitle: 'Eliminar tipo transporte',
          deleteMessage: '¿Seguro desea eliminar el tipo transporte?',
        },
        transportador: {
          title: 'Transportadores',
          createTitle: 'Creación transportador',
          updateTitle: 'Actualización transportador',
          deleteTitle: 'Eliminar transportador',
          deleteMessage: '¿Seguro desea eliminar el transportador?',
          detailTitle1: 'Archivos adjuntos',
          detailTitle2: 'Tipos Transportes',
          detailTitle3: 'Guías envío',
          tipoTransporte: {
            tableTitle: 'Seleccionar tipos transportes',
            deleteTitle: 'Eliminar tipo transporte',
            deleteMessage: '¿Seguro desea eliminar el tipo transporte?',
          },
        },
        recoleccion: {
          title: 'Alistamiento',
          updateTitle: 'Actualización alistamiento',
          createDespachoVD: 'Creación despacho',
          createDespachoSL: 'Creación despacho solicitud traslado',
          tabProductos: 'Productos',
          tabContenedores: 'Contenedores',
          detailTitle3: 'Histórico estados',
          detailTitle4: 'Gastos',
        },
        zona: {
          title: 'Zonas',
          createTitle: 'Creación zona',
          updateTitle: 'Actualización zona',
          deleteTitle: 'Eliminar zona',
          deleteMessage: '¿Seguro desea eliminar la zona?',
        },
      },
      procesos: {
        alistamiento: {
          title: 'Alistamiento',
          tableTitle: 'Listado de alistamiento',
          createTitle: 'Creación de alistamiento',
          updateTitle: 'Actualización de alistamiento',
          deleteTitle: 'Eliminar alistamiento',
          deleteMessage: '¿Seguro desea eliminar el alistamiento?',
        },
        despacho: {
          title: 'Despachos',
          tableTitle: 'Listado de Despacho',
          createTitle: 'Creación Despacho',
          updateTitle: 'Actualización Despacho',
          deleteTitle: 'Eliminar Despacho',
          deleteMessage: '¿Seguro desea eliminar el Despacho?',
          wizarStep1: 'Pedido',
          wizarStep2: 'Recolección - Empaquetado',
          wizarStep3: 'Despacho',
          messageInfo: 'Se debe tener al menos un CLIENTE y un PEDIDO\n'
            + 'creados en bases de datos. Para continuar con este proceso.',
          detailTitle1: 'Detalle pedido',
          detailTitle2: 'Lista de cargue',
          detailTitle3: 'Empleados',
          detailTitle4: 'Transporte tercero',
          detailTitle5: 'Vehículos',
          detailTitle6: 'Encuesta',
          detailTitle7: 'Gastos',
          detailTitle8: 'Archivos adjuntos',
          detailTitle9: 'Imágenes',
          detailTitle10: 'Histórico estados',
          detailTitle11: 'Gastos',
          pdfTitle: 'Descarga PDF',
          pdfDescrition: '¿Desea incluir los precios en el PDF?',
        },
        despachoListaCargue: {
          title: 'Lista de cargue',
          tableTitle: 'Listado de listas de cargue',
          createTitle: 'Creación de lista de cargue',
          updateTitle: 'Actualización de lista de cargue',
          deleteTitle: 'Eliminar lista de cargue',
          deleteMessage: '¿Está seguro de eliminar este lista de cargue?',
        },
        despachoEmpleado: {
          title: 'Empleados',
          tableTitle: 'Listado de empleados',
          createTitle: 'Creación de empleado',
          updateTitle: 'Actualización de empleado',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Está seguro de eliminar este empleado?',
        },
        despachoGasto: {
          title: 'Gastos',
          tableTitle: 'Listado de gastos',
          createTitle: 'Creación gasto',
          updateTitle: 'Actualización gasto',
          deleteTitle: 'Eliminar gasto',
          deleteMessage: '¿Seguro desea eliminar el gasto?',
        },
        despachoOperarios: {
          title: 'Operarios externos',
          tableTitle: 'Listado de operarios externos',
          createTitle: 'Creación de operario',
          updateTitle: 'Actualización de operario',
          deleteTitle: 'Eliminar operario',
          deleteMessage: '¿Está seguro de eliminar este operario?',
        },
        despachoPedidos: {
          title: 'Pedidos',
          tableTitle: 'Listado de pedidos',
          createTitle: 'Creación de pedido',
          updateTitle: 'Actualización de pedido',
          deleteTitle: 'Eliminar pedido',
          deleteMessage: '¿Está seguro de eliminar este pedido?',
        },
        despachoVehiculo: {
          title: 'Vehículos',
          tableTitle: 'Listado de vehículo',
          createTitle: 'Creación de vehículo',
          updateTitle: 'Actualización de vehículo',
          deleteTitle: 'Eliminar vehículo',
          deleteMessage: '¿Está seguro de eliminar este vehículo?',
          tableVehiculoContenedorTitle: 'Listado equipos',
        },
        despachoTransportador: {
          title: 'Transportador',
          tableTitle: 'Listado de transportadores',
          createTitle: 'Creación transportador',
          updateTitle: 'Actualización transportador',
          deleteTitle: 'Eliminar transportador',
          deleteMessage: '¿Seguro desea eliminar el transportador?',
          createTransporte: 'Seleccionar transportador',
        },
        embarque: {
          title: 'Embarque',
          tableTitle: 'Listado de embarques',
          createTitle: 'Creación de embarque',
          updateTitle: 'Actualización de embarque',
          deleteTitle: 'Eliminar embarque',
          deleteMessage: '¿Está seguro de eliminar este embarque?',
          detailTitle1: 'Equipos',
          detailTitle2: 'Orden de compra',
          detailTitle3: 'Archivos adjuntos',
          detailTitle4: 'Historico estados',
        },
        embarqueContenedores: {
          title: 'Equipos',
          createTitle: 'Creación de equipo',
          updateTitle: 'Actualización de equipo',
          deleteTitle: 'Eliminar equipo',
          deleteMessage: '¿Está seguro de eliminar este equipo?',
        },
        embarqueDocumentos: {
          title: 'Transacción compra',
          createTitle: 'Creación de transacción',
          updateTitle: 'Actualización de transacción',
          deleteTitle: 'Eliminar transacción',
          deleteMessage: '¿Está seguro de eliminar esta transacción?',
          tableDocumentoTitle: 'Listado de transacción de compras',
          tabsTittle1: 'Ordenes de compras',
          tabsCreateTitle: 'Creación de orden compra',
          tabsUpdateTitle: 'Actualización de orden compra',
          tabsDeleteTitle: 'Eliminar orden compra',
          tabsDeleteMessage: '¿Está seguro de eliminar esta orden compra?',
          tabsTittle2: 'Compras',
          createListaTitle: 'Crear nueva lista de empaque',
          createListaMessage: '¿Está seguro de crear esta lista para este transacción de embarque?',
        },
        hojaRuta: {
          title: 'Hojas de rutas',
          createTitle: 'Creación hoja de ruta',
          updateTitle: 'Actualización hoja de ruta',
          updateTitleId: 'Actualización hoja de ruta ID: HR - ',
          deleteTitle: 'Eliminar hoja de ruta',
          deleteMessage: '¿Seguro desea eliminar la hoja de ruta?',
          detailTitle1: 'Prioridades',
          detailTitle2: 'Despachos',
          detailTitle3: 'Solicitudes logísticas',
          detailTitle4: 'Histórico estados',
          detailTitle5: 'Gastos',
          despachos: {
            deleteTitle: 'Eliminar despacho',
            deleteMessage: '¿Seguro desea eliminar el despacho?',
            infoMessage: 'Recuerde que solo se muestran los despachos con fecha anterior a la de la hoja de ruta',
            createDespacho: 'Seleccionar despachos',
          },
          solicitudLogistica: {
            deleteTitle: 'Eliminar solicitud logística',
            deleteMessage: '¿Seguro desea eliminar la solicitud logística?',
            infoMessage: 'Recuerde que solo se muestran las solicitudes'
              + ' logísticas con fecha anterior a la de la hoja de ruta',
            createSolicitud: 'Seleccionar solicitudes logísticas',
          },
        },
        listaEmpaque: {
          title: 'Lista de empaques',
          tableTitle: 'Listado de lista de empaque',
          createTitle: 'Creación de lista de empaque',
          updateTitle: 'Actualización de lista de empaque',
          deleteTitle: 'Eliminar lista de empaque',
          deleteMessage: '¿Está seguro de eliminar este lista de empaque?',
          detailTitle1: 'Producto',
          detailTitle2: 'Perfil logístico',
          detailTitle3: 'Códigos EAN',
          detailTitle4: 'Archivos adjuntos',
        },
        listaEmpaqueProductos: {
          title: 'Productos',
          createTitle: 'Creación de productos',
          updateTitle: 'Actualización de productos',
          deleteTitle: 'Eliminar productos',
          deleteMessage: '¿Está seguro de eliminar este productos?',
        },
        packing: {
          title: 'Empaquetado',
          tableTitle: 'Listado de empaquetado',
          createTitle: 'Creación de empaquetado',
          updateTitle: 'Actualización de empaquetado',
          deleteTitle: 'Eliminar empaquetado',
          deleteMessage: '¿Está seguro de eliminar este empaquetado?',
        },
        picking: {
          title: 'Recolección',
          tableTitle: 'Listado de recolección',
          createTitle: 'Creación de recolección',
          updateTitle: 'Actualización de recolección',
          deleteTitle: 'Eliminar recolección',
          deleteMessage: '¿Está seguro de eliminar esta recolección?',
        },
        preinspeccion: {
          title: 'Preinspección',
          tableTitle: 'Listado de preinspecciones',
          createTitle: 'Creación de Preinspección',
          deleteTitle: 'Anular Preinspección',
          deleteMessage: '¿Está seguro de anular esta preinspección?',
          updateTitle: 'Actualización de preinspección',
          messageInfo: 'Recuerde completar los diferentes ATRIBUTOS antes de salir de este formulario',
          tableDetailTitle: 'Listado de producto',
          tableDetailDeleteTitle: 'Eliminar producto',
          tableDetailDeleteMessage: '¿Está seguro de eliminar este producto?',
          detailTitle1: 'Productos',
          detailTitle2: 'Atributos de control',
          detailTitle3: 'Encuesta',
          detailTitle4: 'Historico estados',
          createTitleAtributos: 'Formulario de atributos de control',
          createControlAtributos: 'Listado de atributos',
          deleteControlAtributos: 'Eliminar atributo de control',
          deleteControlAtributosMessage: '¿Está seguro de eliminar el este atributo de control?',
          warningTitle: 'No se han encontrado atributos de control para este formulario',
          desagregarProducto: 'Desagregar producto',
        },
        programacionPicking: {
          title: 'Programación recolección',
          tableTitle: 'Programación recolección',
          createTitle: 'Creación de programación recolección',
          updateTitle: 'Actualización de programación recolección',
          deleteTitle: 'Eliminar programación recolección',
          deleteMessage: '¿Está seguro de eliminar esta programación recolección?',
          changeEstado: 'Ejecutar programación',
          changeEstadoMessage: '¿Está seguro de ejecutar esta programación?',
          detailTitle1: 'Pedidos',
          detailTitle2: 'Eventos',
          detailTitle3: 'Recolecciones',
        },
        programacionPickingExistencias: {
          deleteTitle: 'Eliminar producto',
          deleteMessage: '¿Seguro desea eliminar el producto?',
          createRecoleccion: 'Indique de dónde sacará el inventario para el pedido',
          createTabProducto: 'Seleccionar existencia',
        },
        programacionRecoleccion: {
          title: 'Programaciones alistamiento',
          updateTitle: 'Actualización programación alistamiento',
          deleteTitle: 'Eliminar programación alistamiento',
          deleteMessage: '¿Seguro desea eliminar la programación alistamiento?',
          errorModalTitle: 'Productos sin precio en la lista precio del cliente',
          detailTitle1: 'Contenedores',
          detailTitle2: 'Productos',
          detailTitle3: 'Histórico estados',
          detailTitle4: 'Gastos',
        },
        programacionPickingPedido: {
          title: 'Pedido',
          tableTitle: 'Listado de pedidos',
          createTitle: 'Creación de pedido',
          updateTitle: 'Actualización de pedido',
          deleteTitle: 'Eliminar pedido',
          deleteMessage: '¿Seguro desea eliminar el pedido?',
        },
        feedbackPicking: {
          changeEstado: 'Ejecutar programación',
          changeEstadoMessage: '¿Está seguro de ejecutar esta programación?',
        },
        recepcion: {
          title: 'Recepción mercancía',
          tableTitle: 'Listado recepción',
          createTitle: 'Creación recepción mercancía',
          updateTitle: 'Actualización recepción mercancía',
          deleteTitle: 'Eliminar recepción mercancía',
          deleteMessage: '¿Seguro desea eliminar la recepción mercancía?',
          tabProductoDocumental: 'Producto Documental',
          tableProductoDocumentalTitle: 'Listado de recibo documental',
          tableProductoDocumentalCreate: 'Agregar recibo documental',
          tableProductoDocumentalDeleteTitle: 'Eliminar recibo documental',
          tableProductoDocumentalDeleteMessage: '¿Está seguro de eliminar este recibo?',
          tabProductoReferenciado: 'Lista de empaque',
          tableProductoReferenciadoTitle: 'Listado de recibo referenciado',
          tableProductoReferenciadoCreate: 'Agregar recibo referenciado',
          tableProductoReferenciadoDeleteTitle: 'Eliminar recibo referenciado',
          tableProductoReferenciadoDeleteMessage: '¿Está seguro de eliminar este recibo?',
          tabEmpleado: 'Empleados',
          tableEmpleadoTitle: 'Listado de empleados',
          tableEmpleadoCreate: 'Agregar empleados',
          tableEmpleadoDeleteTitle: 'Eliminar empleado',
          tableEmpleadoDeleteMessage: '¿Está seguro de eliminar este empleado?',
          tabOperario: 'Operarios Externos',
          tableOperarioTitle: 'Listado de operarios externos',
          tableOperarioCreate: 'Agregar operarios externos',
          tableOperarioDeleteTitle: 'Eliminar operario externo',
          tableOperarioDeleteMessage: '¿Está seguro de eliminar este operario externo?',
          tabEncuesta: 'Preinspección',
          tabRequisitosAduaneros: 'Requisitos aduaneros',
          tabRequisitosImportacion: 'Requisitos importación',
          tabGastos: 'Gastos',
          tabImagen: 'Imágenes',
          changeEstado: 'Cambio de estado',
          tabArchivosAdjuntos: 'Archivos adjuntos',
          tabCierreContenedores: 'Cierre equipos',
          modalEditCierreContenedorAlmacenamiento: 'Cierre equipo almacenamiento',
          tabResumen: 'Resumen',
          messageInfo: 'Recuerde que la transacción de logística debe tener una'
            + ' transacción de inventario agregada para poder continuar con el proceso',
          detailTitle1: 'Ordenes compra',
          detailTitle2: 'Traslados',
          detailTitle3: 'Productos orden compra',
          detailTitle4: 'Histórico estados',
          detailTitle5: 'Requisitos aduaneros',
          detailTitle6: 'Requisitos importación',
          detailTitle7: 'Productos adicionales',
          detailTitle8: 'Novedades',
          detailTitle9: 'Productos traslados',
          detailTitle10: 'Gastos',
          recepcionOrdenCompra: {
            deleteTitle: 'Elimina orden de compra',
            deleteMessage: '¿Seguro desea eliminar esta orden de compra?',
          },
          recepcionTraslado: {
            deleteTitle: 'Eliminar traslado',
            deleteMessage: '¿Seguro desea eliminar el traslado?',
          },
          recepcionProductoOC: {
            deleteTitle: 'Eliminar producto',
            deleteMessage: '¿Seguro desea eliminar el producto?',
            updateMessage: 'Actualización producto',
            createProducto: 'Seleccionar productos',
          },
          recepcionProductos: {
            deleteTitle: 'Eliminar producto',
            deleteMessage: '¿Seguro desea eliminar el producto?',
            updateMessage: 'Actualización producto',
            createProducto: 'Seleccionar productos',
          },
          recepcionRequisitoAduanero: {
            createRequisito: 'Seleccionar resquisitos aduaneros',
          },
          recepcionRequisitoImportacion: {
            createRequisito: 'Seleccionar requisitos importación',
          },
        },
        solicitudesLogisticas: {
          title: 'Solicitudes logísticas',
          deleteTitle: 'Eliminar solicitud logística',
          deleteMessage: '¿Seguro desea eliminar la solicitud logística?',
          createTitle: 'Creación solicitud logística',
          updateTitle: 'Actualización solicitud logística',
          detailTitle1: 'Equipos',
          deleteEquipoTitle: 'Eliminar equipo',
          deleteEquipoMessage: '¿Seguro desea eliminar el equipo?',
          detailTitle2: 'Productos',
          deleteProductoTitle: 'Eliminar producto',
          deleteProductoMessage: '¿Seguro desea eliminar el producto?',
          detailTitle3: 'Transporte tecero',
          deleteTransportadorTilte: 'Eliminar transportador',
          deleteTransportadorMessage: '¿Seguro desea eliminar el transportador?',
          detailTitle4: 'Otros',
          detailTitle5: 'Cambios',
          detailTitle6: 'Entregas',
          detailTitle7: 'Gastos',
          detailTitle8: 'Histórico estados',
          genericDeleteTitle: 'Eliminar registro',
          genericDeleteMessage: '¿Seguro desea eliminar el registro?',
          warning1: 'Debe seleccionar un transportador',
          warning2: 'Solo puede seleccionar un transportador',
          tabCreateTransportador: 'Seleccionar transportador',
          tabCreateOtros: 'Creación otros',
        },
        transaccion: {
          title: 'Transacción',
          tableTitle: 'Listado de transacciones',
          deleteTitle: 'Eliminar transacción',
          deleteMessage: '¿Está seguro de eliminar este transacción?',
        },
        transporteTercero: {
          title: 'Transporte terceros',
        },
      },
    },
  },
  centroAyuda: {
    maestros: {
      ayudasGraficas: {
        title: 'Ayudas gráficas',
        createTitle: 'Creación ayuda gráfica',
        updateTitle: 'Actualización ayuda gráfica',
        deleteTitle: 'Eliminar ayuda gráfica',
        deleteMessage: '¿Seguro desea eliminar la ayuda gráfica?',
      },
    },
    procesos: {
      sugerencias: {
        title: 'Sugerencias',
      },
      agendarDemostracion: {
        title: 'Agendar demostración',
      },
      enviarPreguntas: {
        title: 'Enviar preguntas',
      },
      preguntasFrecuentes: {
        title: 'Preguntas frecuentes',
        createTitle: 'Creación pregunta frecuente',
        updateTitle: 'Actualización pregunta frecuente',
        deleteTitle: 'Eliminar pregunta frecuente',
        deleteMessage: '¿Seguro desea eliminar la pregunta frecuente?',
        detailTitle1: 'Ayudas gráficas',
      },
    },
    consultas: {
      preguntasFrecuentes: {
        title: 'Preguntas frecuentes',
      },
    },
  },
  seguridad: {
    usuarios: {
      title: 'Usuarios',
      tableTitle: 'Listado de usuarios',
      createTitle: 'Creación usuario',
      addTitle: 'Adjuntar de usuario',
      updateTitle: 'Actualización usuario',
      tableDetailCreate: 'Listado de permisos',
      tableDetailDeleteTitle: 'Eliminar permiso',
      tableDetailDeleteMessage: '¿Seguro desea eliminar el permiso?',
      deleteTitle: 'Eliminar usuario',
      deleteMessage: '¿Seguro desea eliminar el usuario?',
      detailTitle1: 'General',
      detailTitle2: 'Cambio de contraseña',
      detailTitle3: 'Permisos',
      detailTitle4: 'Roles',
      roles: {
        createTitle: 'Agregar roles',
        deleteTitle: 'Eliminar rol',
        deleteMessage: '¿Seguro desea eliminar el rol?',
      },
    },
    rol: {
      title: 'Roles',
      tableTitle: 'Listado roles',
      createTitle: 'Creación rol',
      addTitle: 'Seleccionar roles',
      updateTitle: 'Actualización rol',
      deleteTitle: 'Eliminar rol',
      deleteMessage: '¿Seguro desea eliminar el rol?',
      detailTitle1: 'Permisos',
    },
    permisos: {
      title: 'Permisos',
      createTitle: 'Creación permiso',
      addTitle: 'Seleccionar permisos',
      updateTitle: 'Actualización permiso',
      deleteTitle: 'Eliminar permiso',
      deleteMessage: '¿Seguro desea eliminar el permiso?',
      deleteMulTitle: 'Eliminar permisos',
      deleteMulMessage: '¿Seguro desea eliminar estos permisos?',
    },
    grupoPermisos: {
      title: 'Grupos permisos',
      createTitle: 'Creación grupo permisos',
      addTitle: 'Asignación de permisos',
      updateTitle: 'Actualización grupo permisos',
      deleteTitle: 'Eliminar grupo permisos',
      deleteMessage: '¿Seguro desea eliminar el grupo de permisos?',
      tabPermisosCreate: 'Seleccionar permisos',
      tabPermisoDeleteTitle: 'Eliminar permiso',
      tabPermisoDeleteMessage: '¿Seguro desea eliminar el permiso?',
      detailTitle1: 'Permisos',
    },
  },
  venta: {
    title: 'Ventas',
    consultas: {
      title: 'Consultas',
      pedidosPendientes: {
        title: 'Pedidos pendientes',
        busqueda: 'Busqueda pedidos pendientes avanzada',
        warningTitle: 'No se han encontrado atributos de control para este formulario',
      },
      facturas: {
        title: 'Ventas',
        searchTitle: 'Por favor seleccione todos los valores del formulario',
        switchTitle: 'Seleccione los criterios de busqueda',
        noSelected: 'No hay campos seleccionados',
        productosFacturados: {
          title: 'Margen'
        },
      },
      ventas: {
        title: 'Pedidos',
        searchTitle: 'Por favor seleccione todos los valores del formulario',
        switchTitle: 'Seleccione los criterios de busqueda',
        noSelected: 'No hay campos seleccionados',
      },
      ventasProductos: {
        title: 'Ventas productos',
        noFields: 'Por favor seleccione alguno de los criterios de busqueda antes de abrir el formulario',
      },
    },
    maestros: {
      cajasRegistradoras: {
        title: 'Cajas registradoras',
        createTitle: 'Creación cajas registradoras',
        updateTitle: 'Actualización cajas registradoras',
        deleteTitle: 'Eliminar caja registradora',
        deleteMessage: '¿Seguro desea eliminar la caja registradora?',
        detailTitle1: 'Empleados',
        empleados: {
          tableTitle: 'Empleados',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
        },
      },
      clasificacionServicio: {
        title: 'Clasificaciones servicios',
        createTitle: 'Creación clasificación servicio',
        updateTitle: 'Actualización clasificación servicio',
        deleteTitle: 'Eliminar clasificación servicio',
        deleteMessage: '¿Seguro desea eliminar la clasificación servicio?',
      },
      descuentos: {
        title: 'Descuentos',
        createTitle: 'Creación descuento',
        updateTitle: 'Actualización descuento',
        deleteTitle: 'Eliminar descuento',
        deleteMessage: '¿Seguro desea eliminar el descuento?',
      },
      consecutivoVenta: {
        title: 'Consecutivos ventas',
        createTitle: 'Creación consecutivo venta',
        updateTitle: 'Actualización consecutivo venta',
        deleteTitle: 'Eliminar consecutivo venta',
        deleteMessage: '¿Seguro desea eliminar el consecutivo venta?',
      },
      documentoVenta: {
        title: 'Transacciones ventas',
        createTitle: 'Creación transacción venta',
        updateTitle: 'Actualización transacción venta',
        deleteTitle: 'Eliminar transacción venta',
        deleteMessage: '¿Seguro desea eliminar la transacción venta?',
      },
      servicio: {
        title: 'Servicios',
        createTitle: 'Creación servicio',
        updateTitle: 'Actualización servicio',
        deleteTitle: 'Eliminar servicio',
        deleteMessage: '¿Seguro desea eliminar el servicio?',
        detailTitle1: 'Empleados',
        detailTitle2: 'Costos',
        detailTitle3: 'Retenciones',
        detailTitle4: 'Impuestos',
        tabEmpleadoCreate: 'Seleccionar empleados',
        tabEmpleadoUpdate: 'Actualización empleado',
        tabEmpleadoDeleteTitle: 'Eliminar empleado',
        tabEmpleadoDeleteMessage: '¿Seguro desea eliminar el empleado?',
        tabCostoCreate: 'Seleccionar costos',
        tabCostoUpdate: 'Actualización costo',
        tabCostoDeleteTitle: 'Eliminar costo',
        tabCostoDeleteMessage: '¿Seguro desea eliminar el costo?',
      },
      operacionesDian: {
        title: 'Operaciones DIAN',
        createTitle: 'Creación operación DIAN',
        updateTitle: 'Actualización operación DIAN',
        deleteTitle: 'Eliminar operación DIAN',
        deleteMessage: '¿Seguro desea eliminar la operación DIAN?',
      },
      puntosVentas: {
        title: 'Punto de venta',
        createTitle: 'Creación de punto de venta',
        updateTitle: 'Actualización de punto de venta',
        deleteTitle: 'Eliminar punto de venta',
        deleteMessage: '¿Seguro desea eliminar el punto de venta?',
        detailTitle1: 'Cajas registradoras',
        detailTitle2: 'Empleados',
        cajasRegistradoras: {
          tableTitle: 'Cajas registradoras',
          deleteTitle: 'Eliminar caja registradora',
          deleteMessage: '¿Seguro desea eliminar la caja registradora?',
        },
        empleados: {
          tableTitle: 'Empleados',
          deleteTitle: 'Eliminar empleado',
          deleteMessage: '¿Seguro desea eliminar el empleado?',
        },
      },
    },
    procesos: {
      generacionFactura: {
        title: 'Generación de facturas',
        detailTitle1: 'Pedidos',
        detailTitle2: 'Alistamiento',
        detailTitle3: 'Despacho de vehículos',
      },
      factura: {
        title: 'Facturas',
        tableTitle: 'Listado de facturas',
        createTitle: 'Creación factura',
        updateTitle: 'Actualización factura',
        tableDetailTitle: 'Listado de productos',
        tableDetailCreate: 'Agregar productos',
        tableDetailUpdate: 'Modificar producto',
        detailTitle1: 'Reporte de gastos',
        detailTitle2: 'Servicios',
        detailTitle3: 'Impuestos',
        detailTitle4: 'Pedidos',
        detailTitle5: 'Asesores',
        detailTitle6: 'Reporte de usos',
        detailTitle7: 'Productos',
        detailTitle8: 'Archivos adjuntos',
        detailTitle9: 'Retenciones sugeridas',
        detailTitle10: 'Gastos',
        detailTitle11: 'Histórico estados',
      },
      pendienteFactura: {
        title: 'Pendientes por facturar',
        modalTitle: 'Crear factura',
      },
      prefactura: {
        title: 'Pre facturas',
        tableTitle: 'Listado de pre facturas',
        createTitle: 'Creación de pre facturas',
        updateTitle: 'Actualización de pre facturas',
        tableDetailTitle: 'Listado de productos',
        tableDetailCreate: 'Agregar productos',
        tableDetailUpdate: 'Modificar producto',
        detailTitle1: 'Productos',
        detailTitle2: 'Servicios',
        detailTitle3: 'Impuestos tributarios',
      },
      facturaProducto: {
        title: 'Productos',
        createTitle: 'Creación de producto',
        updateTitle: 'Actualización de producto',
        updateTitleModal: 'Actualización',
        deleteTitle: 'Eliminar producto',
        deleteMessage: '¿Seguro desea eliminar el producto?',
        detailTitle1: 'IMPUESTOS',
        createProducto: 'Seleccionar productos',
      },
      facturaProductosImpuestos: {
        title: 'Impuestos',
        createTitle: 'Agregar impuestos al producto',
        deleteTitle: 'Eliminar impuesto',
        deleteMessage: '¿Seguro desea eliminar el impuesto?',
      },
      facturaProductosRetenciones: {
        title: 'Retenciones',
        createTitle: 'Agregar retenciones al producto',
        deleteTitle: 'Eliminar retención',
        deleteMessage: '¿Seguro desea eliminar la retención?',
      },
      facturaPedido: {
        title: 'Pedidos',
        createTitle: 'Creación de pedido',
        updateTitle: 'Actualización de pedido',
        updateTitleModal: 'Actualización',
        deleteTitle: 'Eliminar pedido',
        deleteMessage: '¿Está seguro de eliminar este pedido?',
      },
      facturaImpuestos: {
        title: 'Impuestos',
        createTitle: 'Creación de impuestos',
        updateTitle: 'Actualización de impuestos',
        updateTitleModal: 'Actualización',
        deleteTitle: 'Eliminar impuesto',
        deleteMessage: '¿Seguro desea eliminar el impuesto?',
      },
      facturaRetenciones: {
        title: 'Retenciones',
        createTitle: 'Creación de retenciones',
        updateTitle: 'Actualización de retenciones',
        updateTitleModal: 'Actualización',
        deleteTitle: 'Eliminar retención',
        deleteMessage: '¿Seguro desea eliminar la retención?',
      },
      facturaServicios: {
        title: 'Servicios',
        createTitle: 'Creación de servicios',
        updateTitle: 'Actualización de servicios',
        updateTitleModal: 'Actualización',
        deleteTitle: 'Eliminar servicio',
        deleteMessage: '¿Seguro desea eliminar el servicio?',
        detailTitle1: 'Impuestos',
        detailTitle2: 'Retenciones',
        detailTitle3: 'IMPUESTOS',
        createServicio: 'Seleccionar servicios',
      },
      pedido: {
        title: 'Pedidos',
        tableTitle: 'Listado de pedidos',
        createTitle: 'Creación pedido',
        deleteTitle: 'Eliminar pedido',
        deleteMessage: '¿Seguro desea eliminar el pedido?',
        updateTitle: 'Actualización pedido',
        tableDetailTitle: 'Listado de pedidos',
        tableDetailCreate: 'Agregar pedidos',
        tableDetailUpdate: 'Modificar pedido',
        tableDetailDeleteTitle: 'Eliminar pedido',
        tableDetailDeleteMessage: '¿Está seguro de eliminar esta prepedido?',
        tablePredistribuidoTitle: 'Listado de prepedido',
        tablePredistribuidoCreate: 'Agregar prepedido',
        tablePredistribuidoUpdate: 'Modificar prepedido',
        tablePredistribuidoDeleteTitle: 'Eliminar prepedido',
        tablePredistribuidoDeleteMessage: '¿Está seguro de eliminar este prepedido?',
        changeEstado: 'Cambio de estado',
        detailTitle1: 'Productos',
        detailTitle2: 'Servicios',
        detailTitle3: 'Archivos adjuntos',
        detailTitle4: 'Histórico estados',
        detailTitle5: 'Gastos',
        tabProductoCreate: 'Seleccionar productos',
        tabProductoUpdate: 'Modificar producto',
        tabProductoDeleteTitle: 'Eliminar producto',
        tabProductoDeleteMessage: '¿Seguro desea eliminar el producto?',
        tabServicioCreate: 'Seleccionar servicios',
        tabServicioDeleteTitle: 'Eliminar servicio',
        tabServicioDeleteMessage: '¿Seguro desea eliminar el servicio?',
        notFoundRecomendacionContenedor: 'No hay equipos para suplir el pedido',
        notFoundRecomendacionProductoExtra: 'No hay suficientes productos extra para suplir el pedido',
      },
      prePedido: {
        title: 'Pre pedidos',
        tableTitle: 'Listado de Pre pedidos',
        createTitle: 'Creación Pre pedido',
        deleteTitle: 'Eliminar Pre pedido',
        deleteMessage: '¿Seguro desea eliminar el pre pedido?',
        updateTitle: 'Actualización Pre pedido',
        createTitleProducto: 'Seleccionar productos extras',
        equiposExtraModalTitle: 'Seleccionar equipos extras',
        selectServiciosModalTitle: 'Seleccionar servicios',
        updateTitleProducto: 'Actualización de productos',
        deleteTitleProducto: 'Eliminar producto extra',
        deleteMessageProducto: '¿Seguro desea eliminar el producto extra?',
        deleteTitleEquipo: 'Eliminar equipo extra',
        deleteMessageEquipo: '¿Seguro desea eliminar el equipo extra?',
        deleteTitleServicio: 'Eliminar servicio',
        deleteMessageServicio: '¿Seguro desea eliminar el servicio?',
        detailTitle1: 'Histórico estados',
        wizarStep1: 'Cliente',
        wizarStep2: 'Paciente',
        wizarStep3: 'Cirujano',
        wizarStep4: 'Pedido',
        messageInfo: 'Se debe tener al menos un CLIENTE y un PRODUCTO de tipo cirugía\n'
          + 'creados en bases de datos. Para continuar con este proceso.',
        messageInfo2: 'La fecha y hora de entrega máxima debe ser posterior a la fecha de entrega mínima.',
      },
      ventaDirecta: {
        title: 'Ventas directas',
        tableTitle: 'Listado de venta directa',
        createTitle: 'Creación venta directa',
        deleteTitle: 'Eliminar venta directa',
        deleteMessage: '¿Seguro desea eliminar la venta directa?',
        updateTitle: 'Actualización venta directa',
        tableDetailTitle: 'Listado de pedidos',
        tableDetailCreate: 'Agregar pedidos',
        tableDetailUpdate: 'Modificar pedido',
        tableDetailDeleteTitle: 'Eliminar pedido',
        tableDetailDeleteMessage: '¿Seguro desea eliminar el prepedido?',
        tablePredistribuidoTitle: 'Listado de prepedido',
        tablePredistribuidoCreate: 'Agregar prepedido',
        tablePredistribuidoUpdate: 'Modificar prepedido',
        tablePredistribuidoDeleteTitle: 'Eliminar prepedido',
        tablePredistribuidoDeleteMessage: '¿Está seguro de eliminar este prepedido?',
        changeEstado: 'Cambio de estado',
        titleList: 'Listado de transportadores',
        detailTitle1: 'Productos',
        detailTitle2: 'Servicios',
        detailTitle3: 'Archivos adjuntos',
        detailTitle4: 'Histórico estados',
        detailTitle5: 'Gastos',
        tabProductoCreate: 'Agregar productos',
        tabProductoUpdate: 'Modificar producto',
        tabProductoDeleteTitle: 'Eliminar producto',
        tabProductoDeleteMessage: '¿Está seguro de eliminar esta producto?',
        alertMessage1: 'La lista de precio tiene que estar asignada al cliente del pedido',
        alertMessage2: 'El producto debe tener un precio asignado dentro de la lista de precio principal del cliente',
      },
    },
  },
};
