const endPoints = {
  auth: {
    login: 'authenticate',
    logout: 'logout',
    token: 'token',
    find: 'find',
    reset: 'resetPassword',
    createResetRequest: 'createResetRequest',
    getUser: 'user',
  },
  bi: {
    inventario: {
      puntoreorden: {
        base: 'sidis/puntoreorden',
      },
    },
  },
  gestionContratos: {
    maestros: {
      consecutivos: {
        base: 'gestiondecontratos/consecutivos',
        excel: 'reports/consecutivos/excel',
      },
      documentos: {
        base: 'gestiondecontratos/documentogestioncontratos',
        autocomplete: 'gestiondecontratos/documentogestioncontratos/autocomplete',
      },
      tipoDocumentoContrato: {
        base: 'gestiondecontratos/tiposdocumentoscontratos',
        autocomplete: 'gestiondecontratos/tiposdocumentoscontratos/autocomplete',
      },
      clasificacionContrato: {
        base: 'gestiondecontratos/clasificacionescontratos',
        autocomplete: 'gestiondecontratos/clasificacionescontratos/autocomplete',
      },
      parteInvolucrada: {
        base: 'gestiondecontratos/partesinvolucradas',
        autocomplete: 'gestiondecontratos/partesinvolucradas/autocomplete',
      },
    },
    procesos: {
      solicitudContrato: {
        base: 'gestiondecontratos/solicitudcontrato',
        init: 'gestiondecontratos/solicitudcontrato/initformcomponent',
        cambioEstado: 'gestiondecontratos/solicitudcontrato/cambioestado',
      },
    },
  },
  gestionProveedores: {
    maestros: {
      consecutivos: {
        base: 'gestiondeproveedores/consecutivos',
      },
      documentos: {
        base: 'gestiondeproveedores/documentosgestionproveedores',
        autocomplete: 'gestiondeproveedores/documentosgestionproveedores/autocomplete',
      },
    },
  },
  gestionActivo: {
    maestros: {
      metodoDepreciacion: {
        base: 'gestionactivo/metodosdepreciaciones',
        autocomplete: 'gestionactivo/metodosdepreciaciones/autocomplete',
        excel: 'reports/gestionactivo/metodosDepreciacionesExcel',
        sendExcelToMail: 'gestionactivo/metodosdepreciaciones/sendExcelToMail',
      },
      consecutivos: {
        base: 'gestionactivo/consecutivos',
        excel: 'reports/consecutivos/excel',
      },
      documentosGestionActivo: {
        base: 'gestionactivo/documentogestionactivo',
        autocomplete: 'gestionactivo/documentogestionactivo/autocomplete',
        excel: 'reports/gestionactivo/documentosGestionActivoExcel',
        librosContables: {
          base: 'gestionactivo/documentogestionactivolibroscontables',
          getLibrosContablesNotInDocumento: 'gestionactivo/documentogestionactivolibroscontables'
            + '/getlibroscontablesnotindocumento',
        },
      },
      parametrosDepreciacion: {
        base: 'gestionactivo/activosparametrosdepreciacion',
        initFormComponent: 'gestionactivo/activosparametrosdepreciacion/initFormComponent',
        librosNotInParametros: 'gestionactivo/activosparametrosdepreciacion/librosnotinparametros',
      },
      activoParametroDepreciacionCategorizacion: {
        base: 'gestionactivo/activoparametrodepreciacioncategorizacion',
        initFormComponent: 'gestionactivo/activoparametrodepreciacioncategorizacion/initform',
      },
      activoParametroDepreciacionCategorizacionDistribucion: {
        base: 'gestionactivo/activoparametrodepreciacioncategorizaciondistribucion',
        initComp: 'gestionactivo/activoparametrodepreciacioncategorizaciondistribucion/initform',
      },
      gruposActivos: {
        base: 'gestionactivo/gruposactivos',
        autoComplete: 'gestionactivo/gruposactivos/autocomplete',
      },
      activo: {
        base: 'gestionactivo/activo',
        initForm: 'gestionactivo/activo/initform',
        autoComplete: 'gestionactivo/activo/autocomplete',
        sendExcelToMail: 'gestionactivo/activo/sendExcelToMail',
        activosExcel: 'reports/contabilidad/activosExcel',
        imagenes: {
          base: 'gestionactivo/activoimagenes',
        },
      },
      activoExistencias: {
        base: 'gestionactivo/activoexistencias',
        existenciasNotInActivo: 'gestionactivo/activoexistencias/getExistenciasNotInActivo',
      },
      categoriaActivo: {
        base: 'gestionactivo/categoriaactivo',
        autoComplete: 'gestionactivo/categoriaactivo/autocomplete',
        sendExcelToMail: 'gestionactivo/categoriaactivo/sendExcelToMail',
        categoriasActivosExcel: 'reports/contabilidad/categoriasActivosExcel',
      },
      tipoActivo: {
        base: 'gestionactivo/tipoactivo',
        autoComplete: 'gestionactivo/tipoactivo/autocomplete',
        indexActivos: 'gestionactivo/tipoactivo/indexActivos',
        sendExcelToMail: 'gestionactivo/tipoactivo/sendExcelToMail',
        tiposActivosExcel: 'reports/contabilidad/tiposActivosExcel',
      },
    },
    procesos: {
      conceptosMovimientoActivo: {
        base: 'gestionactivo/conceptosmovimientoactivos',
        autocomplete: 'gestionactivo/conceptosmovimientoactivos/autocomplete',
        librosContables: {
          base: 'gestionactivo/conceptosmovimientoactivoslibroscontables',
          getLibrosContablesNotInDocumento: 'gestionactivo'
            + '/conceptosmovimientoactivoslibroscontables/getlibroscontablesnotinconcepto',
        },
      },
    },
  },
  calidad: {
    maestros: {
      nivelesServicios: {
        base: 'calidad/nivelesservicios',
        autocomplete: 'calidad/nivelesservicios/autocomplete',
        empleados: {
          base: 'calidad/nivelesserviciosempleados',
          getEmpleadosNotInNivel: 'calidad/nivelesserviciosempleados/getempleadosnotinnivel',
        },
      },
      niveles: {
        base: 'calidad/niveles',
        autocomplete: 'calidad/niveles/autocomplete',
      },
      prioridadesImpactos: {
        base: 'calidad/prioridadesimpactos',
        autocomplete: 'calidad/prioridadesimpactos/autocomplete',
      },
    },
    procesos: {
      gestionSolicitudes: {
        base: 'calidad/gestionsolicitudes',
        cambioEstado: 'calidad/gestionsolicitudes/cambioestado',
        getSolicitudesByEstado: 'calidad/gestionsolicitudes/getSolicitudesByEstado',
        initFormCierreAdministrativo: 'calidad/gestionsolicitudes/initFormCierreAdministrativo',
        comentarios: {
          base: 'calidad/gestionsolicitudcomentarios',
        },
        imagenes: {
          base: 'calidad/gestionsolicitudimagenes',
        },
        historicoAsignacion: {
          base: 'calidad/gestionsolicitudhistoricoasignaciones',
        },
        leccionesAprendidas: {
          base: 'calidad/gestionsolicitudleccionesaprendidas',
        },
      },
      seguimientoSolicitudes: {
        base: 'calidad/gestionsolicitudes/getsolicitudesiniciales'
      },
    },
  },
  gobiernoCorporativo: {
    maestros: {
      consecutivos: {
        base: 'gobiernocorporativo/consecutivos',
        excel: 'reports/consecutivos/excel',
      },
      documentosGobiernoCorporativo: {
        base: 'gobiernocorporativo/documentogobiernocorporativo',
        autocomplete: 'gobiernocorporativo/documentogobiernocorporativo/autocomplete',
        excel: 'reports/gobiernocorporativo/documentoGobiernoCorporativoExcel',
      },
      tipoCalendario: {
        base: 'general/tiposcalendarios',
        autocomplete: 'general/tiposcalendarios/autocomplete',
        excel: 'reports/gobiernocorporativo/tipoCalendarioExcel',
        sendExcelToMail: 'general/tiposcalendarios/sendExcelToMail',
      },
    },
    procesos: {
      organigrama: {
        base: 'gobiernocorporativo/organigramas',
      },
    },
    gestionEmpresarial: {
      maestros: {
        tipoOrganigrama: {
          base: 'gobiernocorporativo/tiposorganigramas',
          autocomplete: 'gobiernocorporativo/tiposorganigramas/autocomplete',
          excel: 'reports/gobiernocorporativo/tipoOrganigramaExcel',
          sendExcelToMail: 'gobiernocorporativo/tiposorganigramas/sendExcelToMail',
        },
        faltas: {
          base: 'gobiernocorporativo/faltas',
          excel: 'reports/gobiernocorporativo/faltaExcel',
          autoComplete: 'gobiernocorporativo/faltas/autocomplete',
          sendExcelToMail: 'gobiernocorporativo/faltas/sendExcelToMail',
        },
        politicas: {
          base: 'gobiernocorporativo/politicas',
          autocomplete: 'gobiernocorporativo/politicas/autocomplete',
        },
        manualesCorporativos: {
          base: 'gobiernocorporativo/manualescorporativos',
          autocomplete: 'gobiernocorporativo/manualescorporativos/autocomplete',
        },
        clasificacionPolitica: {
          base: 'gobiernocorporativo/clasificacionespoliticas',
          autocomplete: 'gobiernocorporativo/clasificacionespoliticas/autocomplete',
        },
      },
      procesos: {
        gestionEmpresarial: {
          base: 'gobiernocorporativo/gestionempresarial',
          excel: 'reports/gobiernocorporativo/gestionEmpresarialExcel',
          cambioEstado: 'gobiernocorporativo/gestionempresarial/cambioEstado',
          sendExcelToMail: 'gobiernocorporativo/gestionempresarial/sendExcelToMail',
          objetivos: {
            base: 'gobiernocorporativo/gestionempresarialobjetivos',
          },
        },
        reglamentoInternoTrabajo: {
          base: 'gobiernocorporativo/reglamentointernotrabajo',
          autocomplete: 'gobiernocorporativo/reglamentointernotrabajo/autocomplete',
          faltas: {
            base: 'gobiernocorporativo/reglamentointernotrabajofaltas',
            cambioEstado: 'gobiernocorporativo/reglamentointernotrabajofaltas/cambioEstado',
          },
        },
      },
    },
  },
  contabilidad: {
    maestros: {
      actividadEconomica: {
        base: 'general/actividadeseconomicas',
        autoComplete: 'general/actividadeseconomicas/autocomplete',
        sendExcelToMail: 'general/actividadeseconomicas/sendExcelToMail',
        actividadesEconomicasExcel: 'reports/contabilidad/actividadesEconomicasExcel',
      },
      centroCosto: {
        base: 'contabilidad/centroscostos',
        autocomplete: 'contabilidad/centroscostos/autocomplete',
        autocompleteByNivel: 'contabilidad/centroscostos/autocompletebynivel',
        sendExcelToMail: 'contabilidad/centroscostos/sendExcelToMail',
        getCentroCostoNotInGasto: 'contabilidad/centroscostos/getcentrocostonotingasto',
        centrosCostosExcel: 'reports/contabilidad/centrosCostosExcel',
      },
      clasificacionTributariaProducto: {
        base: 'contabilidad/clasificaciontributariaproducto',
        impuesto: {
          base: 'contabilidad/clasificaciontributariaproductoimpuesto',
          notIn: 'contabilidad/clasificaciontributariaproductoimpuesto/getimpuestosnotinclasificacion'
        },
        retencion: {
          base: 'contabilidad/clasificaciontributariaproductoretencion',
          notIn: 'contabilidad/clasificaciontributariaproductoretencion/getretencionesnotinclasificacion'
        }
      },
      clasificacionTributariaGasto: {
        base: 'contabilidad/clasificaciontributariagasto',
        autocomplete: 'contabilidad/clasificaciontributariagasto/autocomplete',
        impuesto: {
          base: 'contabilidad/clasificaciontributariagastoimpuesto',
          notIn: 'contabilidad/clasificaciontributariagastoimpuesto/getimpuestosnotinclasificacion'
        },
        retencion: {
          base: 'contabilidad/clasificaciontributariagastoretencion',
          notIn: 'contabilidad/clasificaciontributariagastoretencion/getretencionesnotinclasificacion'
        }
      },
      clasificacionTributariaServicio: {
        base: 'contabilidad/clasificaciontributariaservicio',
        autocomplete: 'contabilidad/clasificaciontributariaservicio/autocomplete',
        impuesto: {
          base: 'contabilidad/clasificaciontributariaservicioimpuesto',
          notIn: 'contabilidad/clasificaciontributariaservicioimpuesto/getimpuestosnotinclasificacion'
        },
        retencion: {
          base: 'contabilidad/clasificaciontributariaservicioretencion',
          notIn: 'contabilidad/clasificaciontributariaservicioretencion/getretencionesnotinclasificacion'
        }
      },
      clasificacionTributariaActivo: {
        base: 'gestionactivo/clasificacionestributariasactivos',
        retenciones: {
          base: 'gestionactivo/clasificacionestributariasactivosretenciones',
          notIn: 'gestionactivo/clasificacionestributariasactivosretenciones/getretencionesnotinclasificacion',
        },
        impuestos: {
          base: 'gestionactivo/clasificacionestributariasactivosimpuestos',
          notIn: 'gestionactivo/clasificacionestributariasactivosimpuestos/getimpuestosnotinclasificacion',
        },
      },
      configuracionEstadoFinanciero: {
        base: 'contabilidad/configuracionestadosfinancieros',
        configuracionEstadoFinancieroExcel: 'reports/contabilidad/configuracionesEstadosFinancierosExcel',
      },
      consecutivosContabilidad: {
        base: 'contabilidad/consecutivos',
        consecutivosContabilidadExcel: 'reports/consecutivos/excel',
      },
      clasificacionCuentaContable: {
        base: 'contabilidad/clasificacioncuentacontable',
        autocomplete: 'contabilidad/clasificacioncuentacontable/autocomplete',
        clasificacionesCuentasContablesExcel: 'reports/contabilidad/clasificacionesCuentasContablesExcel',
      },
      clasificacionesJuridicas: {
        base: 'contabilidad/clasificacionesjuridicas',
        autocomplete: 'contabilidad/clasificacionesjuridicas/autocomplete',
        clasificacionesJuridicasExcel: 'reports/contabilidad/clasificacionesJuridicasExcel',
      },
      cuentasContables: {
        base: 'contabilidad/cuentascontables',
        accordeon: 'contabilidad/cuentascontables/accordeon',
        autoCompleteByNivel: 'contabilidad/cuentascontables/autoCompleteByNivel',
        autoComplete: 'contabilidad/cuentascontables/autoComplete',
        initForm: 'contabilidad/cuentascontables/initformcomponent',
        cuentasContablesExcel: 'reports/contabilidad/cuentasContablesExcel',
      },
      documentosContabilidad: {
        base: 'contabilidad/documentoscontabilidad',
        documentosContabilidadExcel: 'reports/contabilidad/documentosContabilidadExcel',
      },
      responsabilidadTributaria: {
        base: 'contabilidad/responsabilidadtributaria',
        autocomplete: 'contabilidad/responsabilidadtributaria/autocomplete',
        responsabilidadesTributariasExcel: 'reports/contabilidad/responsabilidadesTributariasExcel',
        conceptosnotinresponsabilidad: 'contabilidad/responsabilidadtributaria/conceptosnotinresponsabilidad',
        conceptosTributarios: {
          base: 'contabilidad/responsabilidadtributariaconceptostributarios',
        },
      },
      responsabilidadesFiscales: {
        base: 'venta/responsabilidadesfiscales',
        autoComplete: 'venta/responsabilidadesfiscales/autocomplete',
        responsabilidadesFiscalesExcel: 'reports/contabilidad/responsabilidadesFiscalesExcel',
      },
      rubros: {
        base: 'contabilidad/rubros',
        autocomplete: 'contabilidad/rubros/autocomplete',
        rubrosExcel: 'reports/contabilidad/rubrosExcel',
      },
      centroNegocio: {
        base: 'contabilidad/centrosnegocios',
        autocomplete: 'contabilidad/centrosnegocios/autocomplete',
        sendExcelToMail: 'contabilidad/centrosnegocios/sendExcelToMail',
        centrosNegociosExcel: 'reports/contabilidad/centrosNegociosExcel',
      },
      grupoUnidadNegocio: {
        base: 'contabilidad/gruposunidadnegocios',
        autocomplete: 'contabilidad/gruposunidadnegocios/autocomplete',
        sendExcelToMail: 'contabilidad/gruposunidadnegocios/sendExcelToMail',
        grupoUnidadNegocioExcel: 'reports/contabilidad/grupoUnidadNegocioExcel',
      },
      unidadNegocio: {
        base: 'contabilidad/unidadesnegocios',
        autocomplete: 'contabilidad/unidadesnegocios/autocomplete',
        sendExcelToMail: 'contabilidad/unidadesnegocios/sendExcelToMail',
        unidadNegocioExcel: 'reports/contabilidad/unidadNegocioExcel',
      },
      librosContables: {
        base: 'contabilidad/libroscontables',
        autocomplete: 'contabilidad/libroscontables/autocomplete',
        sendExcelToMail: 'contabilidad/libroscontables/sendExcelToMail',
        libroContableExcel: 'reports/contabilidad/libroContableExcel',
      },
      Uvt: {
        base: 'contabilidad/uvt',
      },
      retenciones: {
        base: 'tesoreria/retenciones',
        autocomplete: 'tesoreria/retenciones/autocomplete',
        initFormComponent: 'tesoreria/retenciones/initFormComponent',
        excel: 'reports/tesoreria/retencionExcel',
        responsabilidadesTributarias: {
          base: 'contabilidad/retencionresponsabilidadestributarias',
          getResponsabilidadesTributariasNotInRetencion: 'contabilidad/'
            + 'retencionresponsabilidadestributarias/getresponsabilidadestributariasnotinretencion',
        },
      },
      impuestos: {
        base: 'tesoreria/impuestos',
        autocomplete: 'tesoreria/impuestos/autocomplete',
        initFormComponent: 'tesoreria/impuestos/initFormComponent',
        excel: 'reports/tesoreria/impuestoExcel',
        responsabilidadesTributarias: {
          base: 'contabilidad/impuestoresponsabilidadestributarias',
          getResponsabilidadesTributariasNotInImpuesto: 'contabilidad/'
            + 'impuestoresponsabilidadestributarias/getresponsabilidadestributariasnotinimpuesto',
        },
      },
    },
    procesos: {
      movimientoContable: {
        base: 'contabilidad/movimientocontable',
      },
      ajustesContables: {
        base: 'contabilidad/ajustescontables',
        initFormComponent: 'contabilidad/ajustescontables/initformcomponent',
        excel: 'reports/contabilidad/ajustesContablesExcel',
        detalle: {
          base: 'contabilidad/ajustecontabledetalle',
        },
      },
      movimientoContableCuenta: {
        base: 'contabilidad/movimientocontablecuenta',
        initcomp: 'contabilidad/movimientocontablecuenta/initcomp',
        modelo: 'contabilidad/movimientocontablecuenta/bymodelo',
      },
      saldoContable: {
        base: 'contabilidad/saldocontable',
      },
      certificadosRetencion: {
        getClientesWithRetenciones: 'contabilidad/certificadosretenciones/getClientesWithRetenciones',
        getRetencionesByClienteTipoAndFechas: 'contabilidad'
          + '/certificadosretenciones/getRetencionesByClienteTipoAndFechas',
        generatePdf: 'impresion/pdf/print/certificadoRetencion',
        excel: 'reports/contabilidad/certificadosRetencionesExcel',
      },
      trasladoFondos: {
        base: 'contabilidad/trasladofondos',
        initFormComponent: 'contabilidad/trasladofondos/initformcomponent',
        excel: 'reports/contabilidad/trasladosFondosExcel',
      },
    },
  },
  crm: {
    maestros: {
      clasificacionCliente: {
        base: 'crm/clasificacionesclientes',
        autocomplete: 'crm/clasificacionesclientes/autocomplete',
        sendExcelToMail: 'crm/clasificacionesclientes/sendExcelToMail',
        clasificacionesClientesExcel: 'reports/crm/clasificacionesClientesExcel',
      },
      cliente: {
        base: 'crm/clientes',
        autocomplete: 'crm/clientes/autocomplete',
        sendExcelToMail: 'crm/clientes/sendExcelToMail',
        autocompleteWithListaPrecio: 'crm/clientes/autocompleteClienteWithListaPrecio',
        initCrudComponent: 'crm/clientes/initcrudcomponent',
        getCuentaCorrienteInfo: 'crm/clientes/getCuentaCorrienteInfo',
        plantillaCargueMasivo: 'reports/plantillaClientesUpload/excel',
        getProductoCirugiaByListaPrecioAndDiagnostico: 'crm/clientes/'
          + 'getProductosCirugiaByListaPrecioPrincipalAndDiagnostico',
        getProductosExtraByListaPrecio: 'crm/clientes/getProductosExtrasByListaPrecioPrincipal',
        tercerosClientesExcel: 'reports/crm/tercerosClientesExcel',
        impuestos: {
          base: 'crm/terceroclienteimpuesto',
        },
        retenciones: {
          base: 'crm/terceroclienteretencion',
        },
        estadoCuenta: {
          base: 'crm/terceroclienteestadocuenta',
        },
        calendarioTrabajo: {
          base: 'crm/clientecalendariotrabajo',
          autocomplete: 'crm/clientecalendariotrabajo/autocomplete',
          excepcion: {
            base: 'crm/clientecalendariotrabajoexcepcion',
          },
          horario: {
            base: 'crm/clientecalendariotrabajohorario',
          },
        },
      },
      terceroClienteEmpleado: {
        base: 'crm/tercerosclientesempleados',
        initFormComponent: 'crm/tercerosclientesempleados/initFormComponent',
      },
      consecutivosCrm: {
        base: 'crm/consecutivos',
        consecutivosCrmExcel: 'reports/consecutivos/excel',
      },
      documentosCrm: {
        base: 'crm/documentoscrm',
        autocomplete: 'crm/documentoscrm/autocomplete',
        documentosCrmExcel: 'reports/crm/documentosCrmExcel',
      },
      prospectos: {
        base: 'crm/prospectos',
        initFormComponent: 'crm/prospectos/initFormComponent',
        autocomplete: 'crm/prospectos/autocomplete',
        sendExcelToMail: 'crm/prospectos/sendExcelToMail',
        prospectosExcel: 'reports/crm/prospectosExcel',
      },
      temporada: {
        base: 'crm/temporadas',
        autocomplete: 'crm/temporadas/autocomplete',
        temporadasExcel: 'reports/crm/temporadasExcel',
      },
      terceroClienteAtributoComercial: {
        base: 'crm/clientes/atributoscomerciales',
      },
      tercerosContactos: {
        base: 'crm/clientes/contactos',
        autocomplete: 'crm/clientes/contactos/autocomplete',
      },
      tercerosDocumentos: {
        base: 'crm/tercerosdocumentos',
        initFormComponent: 'crm/tercerosdocumentos/initForm',
      },
      terceroHabeasData: {
        base: 'crm/tercerohabeasdata',
      },
      tercerosReglas: {
        base: 'crm/tercerosreglas',
        initFormComponent: 'crm/tercerosreglas/initForm',
      },
      tercerosSucursales: {
        base: 'crm/tercerossucursales',
        autocomplete: 'crm/tercerossucursales/autocomplete',
        contactos: 'crm/tercerossucursales/contactos',
      },
      tercerosListasPrecios: {
        base: 'crm/clientelistasdeprecios',
        autocomplete: 'crm/clientelistasdeprecios/autocomplete',
        initFormComponent: 'crm/clientelistasdeprecios/initformcomponent',
      },
      habeasData: {
        base: 'crm/habeasdata',
        autocomplete: 'crm/habeasdata/autocomplete',
        sendExcelToMail: 'crm/habeasdata/sendExcelToMail',
        getHabeasDataNotInTercero: 'crm/habeasdata/getHabeasDataNotInTercero',
        habeasDatasExcel: 'reports/crm/habeasDatasExcel',
      },
      tiposCotizaciones: {
        base: 'crm/tiposcotizaciones',
        autocomplete: 'crm/tiposcotizaciones/autocomplete',
      },
      clasificacionCotizaciones: {
        base: 'crm/clasificacionescotizaciones',
        autocomplete: 'crm/clasificacionescotizaciones/autocomplete',
        getClasificacionesByTipo: 'crm/clasificacionescotizaciones/getClasificacionesByTipo',
      },
      medioComunicacionCliente: {
        base: 'crm/medioscomunicacionclientes',
        autocomplete: 'crm/medioscomunicacionclientes/autocomplete',
      },
      listaPrecios: {
        base: 'inventario/listasprecios',
        autocomplete: 'inventario/listasprecios/autocomplete',
        sendExcelToMail: 'inventario/listasprecios/sendExcelToMail',
        listasAprobadas: 'inventario/listasprecios/indexListasAprobadas',
        initFormComponent: 'inventario/listasprecios/initFormComponent',
        duplicarListaPrecios: 'inventario/listasprecios/duplicarListaPrecios',
        listaPrecioNotCliente: 'inventario/listasprecios/listaPrecioNotCliente',
        autocompleteListaForProveedor: 'inventario/listasprecios/autocompleteListaForProveedor',
        listasPreciosExcel: 'reports/crm/listasPreciosExcel',
      },
      listaPreciosProductos: {
        base: 'inventario/productolistadeprecios',
        getProductosCirugia: 'inventario/productolistadeprecios/getProductosCirugia',
        getKits: 'inventario/productolistadeprecios/getKits',
        productowithPrecio: 'inventario/productolistadeprecios/productowithvalor',
        cargueMasivo: 'inventario/productolistadeprecios/upload',
        updatePrecio: 'inventario/productolistadeprecios/updatePrecio',
        changeAllProductsByPercent: 'inventario/productolistadeprecios/changeAllProductsByPercent',
        changeAllKitsByPercent: 'inventario/productolistadeprecios/changeallkitsbypercent',
        plantillaCargueMasivo: 'reports/plantillaListaPrecioUpload/excel',
        excel: 'reports/crm/listasPreciosProductosExcel',
      },
      listaPreciosServicios: {
        base: 'venta/listaprecioservicios',
        updatePrecio: 'venta/listaprecioservicios/updatePrecio',
        getServiciosNotInLista: 'venta/listaprecioservicios/getServiciosNotInListaPrecio',
        getServiciosByCliente: 'venta/listaprecioservicios/getServiciosByCliente',
        listaPreciosServiciosExcel: 'reports/crm/listasPreciosServiciosExcel',
        upload: 'venta/listaprecioservicios/upload',
      },
      tiposListasPrecios: {
        base: 'inventario/tiposlistasdeprecios',
        autocomplete: 'inventario/tiposlistasdeprecios/autocomplete',
        sendExcelToMail: 'inventario/tiposlistasdeprecios/sendExcelToMail',
        tiposListasPreciosExcel: 'reports/crm/tiposListasPreciosExcel',
      },
    },
    procesos: {
      cotizacion: {
        base: 'crm/cotizaciones',
        initformcomponent: 'crm/cotizaciones/initformcomponent',
        cambioEstado: 'crm/cotizaciones/cambioestado',
        report: 'reports/cotizaciones/excel',
        sendExcelToMail: 'crm/cotizaciones/sendexceltomail',
      },
      cotizacionGastos: {
        base: 'crm/cotizacionesgastos',
        initFormcomponent: 'crm/cotizacionesgastos/initformcomponent',
      },
      cotizacionServicios: {
        base: 'crm/cotizacionesservicios',
        getServiciosNotInCotizacion: 'crm/cotizacionesservicios/getServiciosNotInCotizacion',
        cargueMasivo: 'crm/cotizacionesservicios/upload',
        plantillaCargueMasivo: 'reports/plantillaCotizacionServicioUpload/excel',
        initFormComponent: 'crm/cotizacionesservicios/initformcomponent',
      },
      cotizacionTipoPagoCliente: {
        base: 'crm/cotizacionestipospagosclientes',
        initFormComponent: 'crm/cotizacionestipospagosclientes/initformcomponent',
      },
      cotizacionTipoPagoProveedor: {
        base: 'crm/cotizacionestipospagosproveedores',
        initFormComponent: 'crm/cotizacionestipospagosproveedores/initformcomponent',
      },
      cotizacionProductos: {
        base: 'crm/cotizacionesproductos',
        notInCotizacion: 'crm/cotizacionesproductos/productosnotincotizacion',
        cargueMasivo: 'crm/cotizacionesproductos/upload',
        plantillaCargueMasivo: 'reports/plantillaCotizacionProductoUpload/excel',
        initFormComponent: 'crm/cotizacionesproductos/initformcomponent',
      },
      cotizacionProductosNuevos: {
        base: 'crm/cotizacionesproductosnuevos',
        cargueMasivo: 'crm/cotizacionesproductosnuevos/upload',
        initFormComponent: 'crm/cotizacionesproductosnuevos/initformcomponent',
        plantillaCargueMasivo: 'reports/plantillaCotizacionProductoNuevoUpload/excel',
      },
      cotizacionComercioExterior: {
        base: 'crm/cotizacionescomercioexterior',
        initFormcomponent: 'crm/cotizacionescomercioexterior/initformcomponent',
        report: 'reports/cotizacionescomercioexterior/excel',
      },
      cotizacionComercioExteriorProductos: {
        base: 'crm/cotizacionescomercioexteriorproductos',
      },
      cargueMasivo: {
        base: 'crm/clientes/upload',
      },
    },
  },
  general: {
    consecutivo: {
      autocomplete: 'general/consecutivos/autocomplete',
    },
    calendarioTrabajo: {
      base: 'general/calendariotrabajo',
      autocomplete: 'general/calendariotrabajo/autocomplete',
      excepcion: {
        base: 'general/calendariotrabajoexcepcion',
      },
      horario: {
        base: 'general/calendariotrabajohorario',
      },
    },
    sedeEmpresa: {
      base: 'general/sedesempresa',
      autocomplete: 'general/sedesempresa/autocomplete',
    },
    configuracion: {
      configuracion: {
        base: 'general/configuracion',
        initFormComponent: 'general/configuracion/initformcomponent',
      },
      configuracionSedeEmpresa: {
        base: 'general/configuracionsedesempresa',
        autocomplete: 'general/configuracionsedesempresa/autocomplete',
        getSedesNotInConfiguracion: 'general/configuracionsedesempresa/getsedesnotinconfiguracion',
        calendario: {
          base: 'general/configuracionsedesempresacalendario',
          getCalendariosNotInSede: 'general/configuracionsedesempresacalendario/getcalendariosnotinsede',
        },
      },
      configuracionCostos: {
        base: 'general/configuracioncostos',
      },
      configuracionFacturaDian: {
        base: 'general/configuracionfacturadian',
      },
      contribucionParafiscal: {
        base: 'general/contribucionesparafiscales',
      },
    },
    getWebSocketInput: 'general/potoWs/getPotoCredentials',
    maestros: {
      atributos: {
        atributosGenerales: {
          atributo: {
            base: 'general/atributos',
            sendExcelToMail: 'general/atributos/sendExcelToMail',
            indexActivos: 'general/atributos/indexactivos',
            initFormComponent: 'general/atributos/initformcomponent',
            atributosGeneralesExcel: 'reports/generalmaestros/atributosGeneralesExcel',
          },
          etiquetas: {
            base: 'general/etiquetas',
            etiquetasExcel: 'reports/generalmaestros/etiquetasExcel',
          },
        },
      },
      consecutivos: {
        getConsecutivosByTipo: 'general/consecutivos/getConsecutivosByTipo',
      },
      divisionPolitica: {
        ciudad: {
          base: 'general/ciudades',
          ciudadesExcel: 'reports/generalmaestros/ciudadesExcel',
          autocomplete: 'general/ciudades/autocomplete',
          autoCompleteByPais: 'general/ciudades/autocompleteByPais',
          autocompleteByDepartamento: 'general/ciudades/autocompleteByDepartamento',
          sendExcelToMail: 'general/ciudades/sendExcelToMail',
        },
        departamento: {
          base: 'general/departamentos',
          departamentosExcel: 'reports/generalmaestros/departamentosExcel',
          autocomplete: 'general/departamentos/autocomplete',
          sendExcelToMail: 'general/departamentos/sendExcelToMail',
        },
        pais: {
          base: 'general/paises',
          paisesExcel: 'reports/generalmaestros/paisesExcel',
          autocomplete: 'general/paises/autocomplete',
          sendExcelToMail: 'general/paises/sendExcelToMail',
        },
      },
      encuestas: {
        base: 'general/encuestas',
        autocomplete: 'general/encuestas/autocomplete',
        encuestasExcel: 'reports/generalmaestros/encuestasExcel',
        baseDocumentos: 'general/encuestas/indexdocumentos',
      },
      encuestaDetalles: {
        base: 'general/encuestasdetalles',
      },
      imprimibles: {
        base: 'general/imprimibles',
        autocomplete: 'general/imprimibles/autocomplete',
      },
      documentosElectronicos: {
        base: 'general/documentoselectronicosintercambio',
        documentosIntercambiosExcel: 'reports/generalmaestros/documentosIntercambiosExcel',
      },
      notificaciones: {
        base: 'general/notificaciones',
        excel: 'reports/notificacion/excel',
        autocomplete: 'general/constantes/autocomplete',
        sendExcelToMail: 'general/notificaciones/sendExcelToMail',
        getNotificacionesSinLeer: 'general/notificaciones/getnotificacionessinleer',
        marcarNotificacionLeida: 'general/notificaciones/marcarnotificacionleida',
      },
      emailNotifications: {
        base: 'general/emailnotifications',
        excel: 'reports/emailnotification/excel',
        autocomplete: 'general/emailnotifications/autocomplete',
        sendExcelToMail: 'general/emailnotifications/sendExcelToMail',
        initFormComponent: 'general/emailnotifications/initFormComponent',
        sendNotification: 'general/emailnotifications/sendNotification',
        verifyHasPDF: 'general/emailnotifications/verifyHasPDF',
        getNotificationsByProcess: 'general/emailnotifications/getNotificationsByProcess',
      },
      notificacionesRoles: {
        base: 'general/modelrolestonotify',
        notInNotificacion: 'general/modelrolestonotify/getRolesNotInModel',
      },
      notificacionesUsuarios: {
        base: 'general/notificacionesusuarios',
        notInNotificacion: 'general/notificacionesusuarios/usuariosnotinnotificacion',
      },
      nomenclatura: {
        base: 'general/nomenclatura',
        autocomplete: 'general/nomenclatura/autocomplete',
        sendExcelToMail: 'general/nomenclatura/sendExcelToMail',
        nomenclaturasExcel: 'reports/generalmaestros/nomenclaturasExcel',
      },
      stateHistory: {
        base: 'general/statehistory',
      },
      tipoDocumento: {
        base: 'general/tiposdocumentos',
        autoComplete: 'general/tiposdocumentos/autocomplete',
        selectData: 'general/tiposdocumentos/selectTiposdocumentos',
        selectCampos: 'general/tiposdocumentos/selectModelo',
        tiposAdjuntosExcel: 'reports/generalmaestros/tiposAdjuntosExcel',
      },
      tipoIdentificacion: {
        base: 'general/tiposidentificaciones',
        autocomplete: 'general/tiposidentificaciones/autocomplete',
        sendExcelToMail: 'general/tiposidentificaciones/sendExcelToMail',
        tiposidentifiacionesExcel: 'reports/generalmaestros/tiposidentifiacionesExcel',
      },
      trazabilidad: {
        puntoControl: {
          base: 'general/puntocontrol',
          initFormComponent: 'general/puntocontrol/initFormComponent',
          puntosControlesExcel: 'reports/generalmaestros/puntosControlesExcel',
        },
        masterTrazabilidad: {
          base: 'general/mastertrazabilidad',
          initFormComponent: 'general/mastertrazabilidad/initFormComponent',
          masterTrazabilidadExcel: 'reports/generalmaestros/masterTrazabilidadExcel',
        },
        masterTrazabilidadPuntosControl: {
          base: 'general/mastertrazabilidadpuntoscontrol',
          initFormComponent: 'general/mastertrazabilidadpuntoscontrol/initFormComponent',
        },
      },
      tributarios: {
        conceptosTributarios: {
          base: 'general/conceptostributarios',
          indexgastoimportacion: 'general/conceptostributarios/indexgastoimportacion',
          getId: 'general/conceptostributarios/id',
          conceptosTributariosExcel: 'reports/generalmaestros/conceptosTributariosExcel',
        },
        clasificacionTributariaItem: {
          base: 'general/clasificaciontributariaitems',
          clasificacionesTributariasItemsExcel: 'reports/generalmaestros/clasificacionesTributariasItemsExcel',
        },
        clasificacionTributariaItemConceptos: {
          base: 'general/clasificacionestributariasitemsconceptos',
        },
        clasificacionTributariaTercero: {
          base: 'general/clasificaciontributariaterceros',
          clasificacionesTributariasTercerosExcel: 'reports/generalmaestros/clasificacionesTributariasTercerosExcel',
        },
        clasificacionTributariaTerceroConceptos: {
          base: 'general/clasificacionestributariastercerosconceptos',
          initFormComponent: 'general/clasificacionestributariastercerosconceptos/initcrudcomponent',
        },
        terceroConceptoTributario: {
          base: 'general/tercerosconceptostributarios',
        },
      },
    },
    constantes: {
      base: 'general/constantes',
      getModelsToNotify: 'general/constantes/getModelsToNotify',
    },
    divisionPolitica: {
      ciudad: {
        base: 'general/ciudades',
        autocomplete: 'general/ciudades/autocomplete',
        getCiudadesByPaisId: 'general/ciudades/getCiudadesByPais',
        autocompleteByPais: 'general/ciudades/autocompleteByPais',
        autocompleteByDepartamento: 'general/ciudades/autocompleteByDepartamento',
      },
      departamento: {
        base: 'general/departamentos',
        autocomplete: 'general/departamentos/autocomplete',
        autocompleteByPais: 'general/departamentos/autocompleteByPais',
      },
      pais: {
        base: 'general/paises',
        autocomplete: 'general/paises/autocomplete',
      },
    },
    documentos: {
      base: 'general/documentos',
      download: 'general/documentos/download',
      cliente: 'general/documentos/bycliente',
      configuracionEmpresa: 'general/documentos/byconfiguracionempresa',
      proveedor: 'general/documentos/byproveedor',
      compra: 'general/documentos/bycompra',
      ordenCompra: 'general/documentos/byordencompra',
      sir: 'general/documentos/bysir',
      pedido: 'general/documentos/bypedido',
      producto: 'general/documentos/byProducto',
      factura: 'general/documentos/byfactura',
      empleado: 'general/documentos/byempleado',
      embarque: 'general/documentos/byembarque',
      transportador: 'general/documentos/bytransportador',
      listaEmpaque: 'general/documentos/bylistaempaque',
      recepcion: 'general/documentos/byrecepcion',
      despacho: 'general/documentos/bydespacho',
      cotizacion: 'general/documentos/bycotizacion',
      formato: 'general/documentos/byformato',
      inventarioGeneral: 'general/documentos/byInventarioGeneral',
      solicitudesPermisos: 'general/documentos/bySolicitudesPermisos',
      solicitudesVacaciones: 'general/documentos/bySolicitudesVacaciones',
      incapacidadesMedicas: 'general/documentos/byIncapacidadesMedicas',
      reporteHorasExtras: 'general/documentos/byReporteHorasExtras',
      candidatos: 'general/documentos/byTerceroCandidato',
      equiposTrabjo: 'general/documentos/byEquipoTrabajo',
      asignacionTareas: 'general/documentos/byAsignacionTarea',
      gestionTareas: 'general/documentos/byGestionTarea',
      reporteTiempos: 'general/documentos/byReporteTiempo',
      activo: 'general/documentos/byActivo',
      reporteGasto: 'general/documentos/byreportegasto',
      solicitud: 'general/documentos/byrequest',
      tesoreria: 'general/documentos/bytesoreria',
      otrosIngresos: 'general/documentos/byOtrosIngresos',
      view: 'general/documentos/view',
      gestionEmpresarial: 'general/documentos/byGestionEmpresarial',
      reglamentoInternoTrabajo: 'general/documentos/byReglamentoInternoTrabajo',
      procesoDisciplinario: 'general/documentos/byprocesodisciplinario',
      procesoDescargoDocumento: 'general/documentos/byprocesodescargodocumento',
      procesoDescargoPruebaEmpleado: 'general/documentos/byprocesodescargopruebaempleado',
      solicitudRecursosHumanos: 'general/documentos/bysolicitudrecursohumano',
      cargos: 'general/documentos/bycargo',
      politicas: 'general/documentos/bypolitica',
      manualesCorporativos: 'general/documentos/bymanualcorporativo',
      identEvalRiesgo: 'general/documentos/byidentevalriesgo',
      conciliacionCompra: 'general/documentos/byconciliacioncompra',
    },
    images: {
      base: 'general/images',
    },
    partidasArancelarias: {
      base: 'general/partidasarancelarias',
      autoCompleteByNivel: 'general/partidasarancelarias/autoCompleteByNivel',
      initForm: 'general/partidasarancelarias/initform',
      descripcionMinima: 'general/partidasarancelarias/descripcionMinima',
      vistoBueno: 'general/partidasarancelarias/vistoBueno',
      atributos: 'general/partidasarancelarias/atributos',
    },
    tercero: {
      byNumeroIdentificacion: 'general/terceros/byidentificacion',
      autocomplete: 'general/terceros/autocomplete',
    },
    terceroActividadEconomica: {
      base: 'general/tercerosactividadeseconomicas',
      list: 'general/tercerosactividadeseconomicas',
      new: 'general/tercerosactividadeseconomicas/create',
      delete: 'general/tercerosactividadeseconomicas/delete',
    },
    terceroPortal: {
      base: 'general/tercerosportal',
    },
    tercerosUsuariosPortal: {
      base: 'general/tercerosusuariosportal',
      autocomplete: 'general/tercerosusuariosportal/autocomplete',
    },
  },
  hrm: {
    nomina: {
      maestros: {
        prestacionesSocales: {
          base: 'hrm/prestacionessociales',
          autocomplete: 'hrm/prestacionessociales/autocomplete',
          sendExcelToMail: 'hrm/prestacionessociales/sendExcelToMail',
          excel: 'reports/hrm/prestacionSocialExcel',
        },
        conceptosNomina: {
          base: 'hrm/conceptosnomina',
          getConceptosForComprobante: 'hrm/conceptosnomina/getConceptosForComprobante',
        },
        salariosMinimosVigentes: {
          base: 'hrm/salariosminimoslegales',
          sendExcelToMail: 'hrm/salariosminimoslegales/sendExcelToMail',
          excel: 'reports/hrm/salarioMinimoVigenteExcel',
        },
      },
      procesos: {
        comprobanteNomina: {
          base: 'hrm/comprobantesnomina',
          excel: 'reports/hrm/comprobantenominaexcel',
          indexColillas: 'hrm/comprobantesnomina/indexColillas',
          cambioEstado: 'hrm/comprobantesnomina/cambioEstado',
          initFormComponent: 'hrm/comprobantesnomina/initFormComponent',
          getProcessesByEmpleado: 'hrm/comprobantesnomina/getProcessesByEmpleado',
          deleteConcepto: 'hrm/comprobantesnomina/deleteConceptoByUuid',
          updateConcepto: 'hrm/comprobantesnomina/updateConceptoByUuid',
        },
        liquidacionVacaciones: {
          base: 'hrm/liquidacionvacaciones',
          cambioEstado: 'hrm/liquidacionvacaciones/cambioestado',
          excel: 'reports/hrm/liquidacionvacacionesexcel',
          sendExcelToMail: 'hrm/liquidacionvacaciones/sendexceltomail',
        },
        liquidacionAuxilioCesantia: {
          base: 'hrm/liquidacionauxiliocesantias',
          autocomplete: 'hrm/liquidacionauxiliocesantias/autocomplete',
          cambioEstado: 'hrm/liquidacionauxiliocesantias/cambioestado',
          excel: 'reports/hrm/liquidacionAuxilioCesantiaExcel',
          sendExcelToMail: 'hrm/liquidacionauxiliocesantias/sendExcelToMail',
        },
        liquidacionInteresCesantia: {
          base: 'hrm/liquidacioninterescesantias',
          cambioEstado: 'hrm/liquidacioninterescesantias/cambioestado',
          excel: 'reports/hrm/liquidacionInteresCesantiaExcel',
          sendExcelToMail: 'hrm/liquidacioninterescesantias/sendExcelToMail',
        },
      },
    },
    procesos: {
      gestionTalento: {
        cargos: {
          autocomplete: 'hrm/cargos/autocomplete',
          base: 'hrm/cargos',
          requisitocargo: {
            base: 'hrm/cargorequisitocargo',
            getRequisitosNotInCargo: 'hrm/cargorequisitocargo/getrequisitonotincargo',
          },
          roles: {
            base: 'hrm/cargorol',
            getRolesNotInCargo: 'hrm/cargorol/getrolesnotincargo',
          },
          pruebasSeleccion: {
            base: 'hrm/cargopruebasseleccion',
            getPruebasNotInCargo: 'hrm/cargopruebasseleccion/getpruebasnotincargo',
          },
          examenesMedicos: {
            base: 'hrm/cargoexamenmedico',
            getExamenesNotInCargo: 'hrm/cargoexamenmedico/getexamenesnotincargo',
          },
          entrevistas: {
            base: 'hrm/cargoentrevista',
            getEntrevistasNotInCargo: 'hrm/cargoentrevista/getentrevistasnotincargo',
          },
          catalogoCompetenecia: {
            base: 'hrm/cargocatalogocompetencia',
            getCatalogoCompetenciaNotInCargo: 'hrm/cargocatalogocompetencia/getcatalogoscompetencianotincargo',
          },
          catalogoHabilidad: {
            base: 'hrm/cargocatalogohabilidad',
            getCatalogoHabilidadesNotInCargo: 'hrm/cargocatalogohabilidad/getcatalogohabilidadesnotincargo',
          },
          manualFunciones: {
            base: 'hrm/cargomanualfuncion',
            autocomplete: 'hrm/cargomanualfuncion/autocomplete',
          },
        },
        solicitudRecursosHumanos: {
          base: 'hrm/solicitudrecursohumano',
          autocomplete: 'hrm/solicitudrecursohumano/autocomplete',
          excel: 'reports/hrm/solicitudRecursoHumanoExcel',
          sendExcelToMail: 'hrm/solicitudrecursohumano/sendExcelToMail',
          pruebasSeleccion: {
            base: 'hrm/solicitudrecursopruebaseleccion',
            notIn: 'hrm/solicitudrecursopruebaseleccion/getpruebasnotinsolicitud',
          },
          examenMedico: {
            base: 'hrm/solicitudrecursoexamenmedico',
            notIn: 'hrm/solicitudrecursoexamenmedico/getexamenesnotinsolicitud',
          },
          requisitoCargo: {
            base: 'hrm/solicitudrecursorequisitocargo',
            notIn: 'hrm/solicitudrecursorequisitocargo/getrequisitosnotinsolicitud',
          },
          catalogoHabilidades: {
            base: 'hrm/solicitudrecursocatalogohabilidad',
            notIn: 'hrm/solicitudrecursocatalogohabilidad/getcatalogosnotinsolicitud',
          },
          catalogoCompetencias: {
            base: 'hrm/solicitudrecursocatalogocompetencia',
            notIn: 'hrm/solicitudrecursocatalogocompetencia/getcatalogosnotinsolicitud',
          },
          entrevistas: {
            base: 'hrm/solicitudrecursoentrevista',
            notIn: 'hrm/solicitudrecursoentrevista/getentrevistasnotinsolicitud',
          },
        },
      },
      liquidacionPrimaServicio: {
        base: 'hrm/liquidacionesprimasservicios',
        excel: 'reports/hrm/liquidacionPrimaServicioExcel',
        sendExcelToMail: 'hrm/liquidacionesprimasservicios/sendExcelToMail',
      },
      programacionTurnos: {
        base: 'hrm/programacionturnos',
        cambioEstado: 'hrm/programacionturnos/cambioestado',
        initForm: 'hrm/programacionturnos/initformcomponent',
        sendExcelToMail: 'hrm/programacionturnos/sendExcelToMail',
        excel: 'reports/hrm/programacionTurnoExcel',
        demanda: {
          base: 'hrm/programacionturnodemanda',
          empleados: {
            base: 'hrm/programacionturnodemandaempleado',
          },
        },
        empleados: {
          base: 'hrm/programacionturnoempleado',
          restricciones: {
            base: 'hrm/programacionturnoempleadorestriccion',
            notIn: 'hrm/programacionturnoempleadorestriccion/getrestriccionesnotinprogturnoempleado'
          },
          turnos: {
            base: 'hrm/programacionturnoempleadoturno',
            notIn: 'hrm/programacionturnoempleadoturno/getturnosnotinprogturnoempleado',
          },
        },
        restricciones: {
          base: 'hrm/programacionturnorestriccion',
          notIn: 'hrm/programacionturnorestriccion/getrestriccionesnotinprogturno',
        },
        turnos: {
          base: 'hrm/programacionturnoturno',
          notIn: 'hrm/programacionturnoturno/getturnosnotinprogturno',
        },
      },
      reclutamiento: {
        vacantesEmpleo: {
          excel: 'reports/hrm/vacanteEmpleoExcel',
          base: 'hrm/solicitudrecursohumano/getsolicitudesaprobadas',
          sendExcelToMail: 'hrm/solicitudrecursohumano/sendExcelToMail',
          sendExcelToMailSolicitudesAprobadas: 'hrm/solicitudrecursohumano/sendExcelToMailSolicitudesAprobadas',
        },
        programacionEntrevista: {
          base: 'hrm/programacionentrevistas',
          initFormComponent: 'hrm/programacionentrevistas/initFormComponent',
          excel: 'reports/hrm/programacionEntrevistaExcel',
          cambioEstado: 'hrm/programacionentrevistas/cambioestado',
          sendExcelToMail: 'hrm/programacionentrevistas/sendExcelToMail',
        },
      },
      seguimientoYretencion: {
        capacitaciones: {
          base: 'hrm/capacitaciones',
          getCapacitacionesAprobadas: 'hrm/capacitaciones/getCapacitacionesAprobadas',
          initFormComponent: 'hrm/capacitaciones/initFormComponent',
          cambioEstado: 'hrm/capacitaciones/cambioEstado',
          sendExcelToMail: 'hrm/capacitaciones/sendExcelToMail',
          excel: 'reports/hrm/capacitacionExcel',
          evaluacionExcel: 'reports/hrm/evaluacioncapacitacionexcel',
          capacitadores: {
            base: 'hrm/capacitacionescapacitadores',
            getCapacitadoresNotIn: 'hrm/capacitacionescapacitadores/getcapacitadoresnotincapacitacion',
          },
          asistentes: {
            base: 'hrm/capacitacionesasistentes',
            getAsistentesNotIn: 'hrm/capacitacionesasistentes/getasistentesnotincapacitacion',
          },
        },
        evaluacionDesempeno: {
          base: 'hrm/evaluaciondesempeno',
          excel: 'reports/hrm/evaluacionDesempenoExcel',
          sendExcelToMail: 'hrm/evaluaciondesempeno/sendExcelToMail',
          planDesempeno: {
            base: 'hrm/evaluaciondesempeno/getplanesbyevaluacion',
          },
        },
        ordenAprovicionamiento: {
          base: 'hrm/ordenaprovicionamiento',
          excel: 'reports/hrm/ordenaprovisionamientoexcel',
          init: 'hrm/ordenaprovicionamiento/initformcomponent',
          cambioEstado: 'hrm/ordenaprovicionamiento/cambioestado',
          requisitoContratacion: {
            base: 'hrm/ordenaprovicionamientorequisitocontratacion',
            cambioEstado: 'hrm/ordenaprovicionamientorequisitocontratacion/cambioestado',
          },
        },
        descargos: {
          base: 'hrm/descargos',
          initFormComponent: 'hrm/descargos/initFormComponent',
          cambioEstado: 'hrm/descargos/cambioestado',
          excel: 'reports/hrm/descargoExcel',
          sendExcelToMail: 'hrm/descargos/sendExcelToMail',
          falta: {
            base: 'hrm/descargofalta'
          }
        },
        llamadosAtencion: {
          base: 'hrm/llamadosatencion',
          initFormComponent: 'hrm/llamadosatencion/initFormComponent',
          cambioEstado: 'hrm/llamadosatencion/cambioestado',
          excel: 'reports/hrm/llamadoAtencionExcel',
          sendExcelToMail: 'hrm/llamadosatencion/sendExcelToMail',
          falta: {
            base: 'hrm/llamadoatencionfalta',
          },
        },
        procesoDisciplinario: {
          base: 'hrm/procesodisciplinario',
          autocomplete: 'hrm/procesodisciplinario/autocomplete',
          cambioEstado: 'hrm/procesodisciplinario/cambioestado',
          excel: 'reports/hrm/procesoDisciplinarioExcel',
          sendExcelToMail: 'hrm/procesodisciplinario/sendExcelToMail',
          init: 'hrm/procesodisciplinario/initformcomponent',
          llamadosAtencion: {
            base: 'hrm/procesodisciplinariollamadoatencion',
          },
          descargos: {
            base: 'hrm/procesodisciplinariodescargo',
            asistentes: {
              base: 'hrm/procesodisciplinariodescargoasistente',
              notIn: 'hrm/procesodisciplinariodescargoasistente/getempleadosnotindescargo',
            },
          },
          suspenciones: {
            base: 'hrm/procesodisciplinariosuspencion',
          },
        },
        programacionEvaluacion: {
          base: 'hrm/progevaluacionesdesempenos',
          excel: 'reports/hrm/progEvaluacionDesempenoExcel',
          sendExcelToMail: 'hrm/progevaluacionesdesempenos/sendExcelToMail',
          habilidad: {
            base: 'hrm/progevaluaciondesempenohabilidad',
          },
          competencia: {
            base: 'hrm/progevaluaciondesempenocompetencia',
          },
          desempeno: {
            base: 'hrm/progevaluaciondesempenodesempeno',
          },
          ambiente: {
            base: 'hrm/progevaluaciondesempenoambiente',
          },
        },
        planDesempeno: {
          base: 'hrm/plandesempenoindividual',
          excel: 'reports/hrm/planDesempenoIndividualExcel',
          sendExcelToMail: 'hrm/plandesempenoindividual/sendExcelToMail',
        },
        suspencion: {
          base: 'hrm/suspenciones',
          initFormComponent: 'hrm/suspenciones/initFormComponent',
          excel: 'reports/hrm/suspencionExcel',
          sendExcelToMail: 'hrm/suspenciones/sendExcelToMail',
          trazabilidad: {
            base: 'hrm/suspenciontrazabilidad',
          },
        },
      },
      gestionTiempo: {
        tareas: {
          asignacionTareas: {
            base: 'hrm/asignaciontareas',
            initFormComponent: 'hrm/asignaciontareas/initFormComponent',
            autocomplete: 'hrm/asignaciontareas/autocomplete',
            cambioEstado: 'hrm/asignaciontareas/cambioestado',
            sendExcelToMail: 'hrm/asignaciontareas/sendExcelToMail',
            excel: 'reports/hrm/asignacionTareaExcel',
          },
          gestionTareas: {
            base: 'hrm/asignaciontareas/getasignacionesbyempleado',
            cambioEstado: 'hrm/asignaciontareas/cambioestadogestion',
            sendExcelToMail: 'hrm/asignaciontareas/sendExcelToMailAsignacionesByEmpleado',
            excel: 'reports/hrm/gestionTareaExcel',
          },
        },
        gestionDeTiempo: {
          reporteTiempo: {
            base: 'hrm/reportetiempos',
            initFormComponent: 'hrm/reportetiempos/initFormComponent',
            cambioEstado: 'hrm/reportetiempos/cambioestado',
            sendExcelToMail: 'hrm/reportetiempos/sendExcelToMail',
            excel: 'reports/hrm/reporteTiempoExcel',
          },
          solicitudModalidad: {
            base: 'hrm/solicitudesmodalidadestrabajo',
            excel: 'reports/hrm/solicitudmodalidadtrabajoexcel',
            autocomplete: 'hrm/solicitudesmodalidadestrabajo/autocomplete',
            initFormComponent: 'hrm/solicitudesmodalidadestrabajo/initformcomponent',
            cambioEstado: 'hrm/solicitudesmodalidadestrabajo/cambioestado',
          },
        },
      },
      solicitudesPermisos: {
        base: 'hrm/solicitudespermisos',
        cambioEstado: 'hrm/solicitudespermisos/cambioEstado',
        sendExcelToMail: 'hrm/solicitudespermisos/sendExcelToMail',
        excel: 'reports/hrm/solicitudesPermisosExcel',
        historicoSolicitudesPermisos: {
          base: 'hrm/historicosolicitudespermisos',
        },
      },
      solicitudesVacaciones: {
        autocomplete: 'hrm/solicitudesvacaciones/autocomplete',
        base: 'hrm/solicitudesvacaciones',
        cambioEstado: 'hrm/solicitudesvacaciones/cambioEstado',
        sendExcelToMail: 'hrm/solicitudesvacaciones/sendExcelToMail',
        excel: 'reports/hrm/solicitudesVacacionesExcel',
      },
      incapacidadesMedicas: {
        base: 'hrm/incapacidadesmedicas',
        initFormComponent: 'hrm/incapacidadesmedicas/initFormComponent',
        cambioEstado: 'hrm/incapacidadesmedicas/cambioEstado',
        sendExcelToMail: 'hrm/incapacidadesmedicas/sendExcelToMail',
        excel: 'reports/hrm/incapacidadesMedicasExcel',
      },
      registrosLicencias: {
        base: 'hrm/registroslicencias',
        initFormComponent: 'hrm/registroslicencias/initFormComponent',
        cambioEstado: 'hrm/registroslicencias/cambioestado',
        sendExcelToMail: 'hrm/registroslicencias/sendExcelToMail',
        excel: 'reports/hrm/registroLicenciaExcel',
      },
      turnos: {
        base: 'hrm/turnos',
        autocomplete: 'hrm/turnos/autocomplete',
        cambioEstado: 'hrm/turnos/cambioEstado',
        sendExcelToMail: 'hrm/turnos/sendExcelToMail',
        excel: 'reports/hrm/turnosExcel',
        recesos: {
          base: 'hrm/turnosrecesos',
          getRecesosNotInTurno: 'hrm/turnosrecesos/getRecesosNotInTurno',
        },
      },
      reporteHorasExtras: {
        base: 'hrm/reporteshorasextras',
        cambioEstado: 'hrm/reporteshorasextras/cambioEstado',
        sendExcelToMail: 'hrm/reporteshorasextras/sendExcelToMail',
        excel: 'reports/hrm/reporteHoraExtraExcel',
        comentarios: {
          base: 'hrm/reportehoraextracomentario',
        },
        imagenes: {
          base: 'hrm/reportehoraextraimagen',
        },
      },
      beneficios: {
        base: 'hrm/beneficios',
        cambioEstado: 'hrm/beneficios/cambioEstado',
        autocomplete: 'hrm/beneficios/autocomplete',
        sendExcelToMail: 'hrm/beneficios/sendExcelToMail',
        excel: 'reports/hrm/beneficioExcel',
      },
      solicitudBeneficios: {
        base: 'hrm/solicitudesbeneficios',
        cambioEstado: 'hrm/solicitudesbeneficios/cambioEstado',
        sendExcelToMail: 'hrm/solicitudesbeneficios/sendExcelToMail',
        initFormComponent: 'hrm/solicitudesbeneficios/initformcomponent',
        excel: 'reports/hrm/solicitudBeneficioExcel',
        autorizacionDeduccion: {
          base: 'hrm/solicitudbeneficioautorizaciondeduccion',
        },
      },
      solicitudBeneficiosEncuestas: {
        base: 'hrm/solicitudbeneficiosencuestas',
      },
      bonificaciones: {
        base: 'hrm/bonificaciones',
        cambioEstado: 'hrm/bonificaciones/cambioEstado',
        sendExcelToMail: 'hrm/bonificaciones/sendExcelToMail',
        initFormComponent: 'hrm/bonificaciones/initformcomponent',
        excel: 'reports/hrm/bonificacionesExcel',
      },
      desvinculacionLaboral: {
        desvinculacionLaboral: {
          base: 'hrm/desvinculacionlaboral',
          excel: 'reports/hrm/desvinculacionlaboralexcel',
          init: 'hrm/desvinculacionlaboral/initformcomponent',
          cambioEstado: 'hrm/desvinculacionlaboral/cambioestado',
          requisitoContratacion: {
            base: 'hrm/desvinculacionlaboralrequisitocontratacion',
            cambioEstado: 'hrm/desvinculacionlaboralrequisitocontratacion/cambioestado',
          },
        },
      },
    },
    maestros: {
      consecutivos: {
        base: 'hrm/consecutivos',
        excel: 'reports/consecutivos/excel',
      },
      documentosHrm: {
        base: 'hrm/documentoshrm',
        autocomplete: 'hrm/documentoshrm/autocomplete',
        initFormComponent: 'hrm/documentoshrm/initFormComponent',
      },
      empleados: {
        base: 'hrm/empleados',
        autocomplete: 'hrm/empleados/autocomplete',
        getInfoEmpleado: 'hrm/empleados/getinformacionempleado',
        sendExcelToMail: 'hrm/empleados/sendExcelToMail',
        autocompleteCajaMenorEmpleado: 'hrm/empleados/autocompleteCajaMenor',
        autocompleteByAlmacenes: 'hrm/empleados/autocompletebyalmacenes',
        setUsuarioToEmpleado: 'hrm/empleados/setUsuarioToEmpleado',
        initFormUsuario: 'hrm/empleados/initFormUsuario',
        autocompleteExcludeTercero: 'hrm/empleados/autocompleteexcludetercero',
        autocompleteWithTipoEmpleado: 'hrm/empleados/autocompletewithtipoempleado',
        autocompleteWithInformacionLaboral: 'hrm/empleados/autocompletewithinformacionlaboral',
        autocompleteEmpleadoNotInCirujano: 'hrm/empleados/autocompleteempleadonotincirujano',
        asesoresAutocomplete: 'hrm/empleados/asesoresAutocomplete',
        initFormComponent: 'hrm/empleados/initformcomponent',
        autocompleteInstrumentador: 'hrm/empleados/autocompleteInstrumentador',
        autocompleteByNivelServicio: 'hrm/empleados/autocompletebynivelservicio',
        autocompleteInstrumentadorCliente: 'hrm/empleadosclientes/autocompleteInstrumentadorCliente',
        autocompleteInstrumentadorProfesional: 'hrm/empleadosprofesional/instrumentadorProfesional',
        autocompleteInstrumentadorNotClienteOrProfesional: 'hrm/'
          + 'empleadosclientes/autocompleteInstrumentadorNotClienteOrProfesional',
        contactos: 'hrm/empleados/contactos',
        informacionLaboral: 'hrm/terceroempleadoinformacionlaboral',
        metodoPago: 'hrm/terceroempleadometodopago',
        clientes: 'hrm/empleadosclientes',
        cirujanos: 'hrm/empleadosprofesional',
        clientesByClienteAndEmpleado: 'hrm/empleadosclientes/getByClienteAndEmpleado',
        cirujanosByProfesionalAndEmpleado: 'hrm/empleadosprofesional/getByProfesionalAndEmpleado',
        listHistoricoCargo: 'hrm/empleados/listHistoricoCargo',
        getClienteNotEmpleado: 'hrm/empleadosclientes/getClienteNotEmpleado',
        tercerosEmpleadosExcel: 'reports/hrm/tercerosEmpleadosExcel',
        calendarioTrabajo: {
          base: 'hrm/calendariotrabajoempleado',
          notIn: 'hrm/calendariotrabajoempleado/getcalendariosbyempleado',
          excepcion: {
            base: 'hrm/calendarioempleadoexcepcion',
          },
          horario: {
            base: 'hrm/calendarioempleadohorario',
          },
        },
        empleadosAlmacenes: {
          base: 'hrm/empleadosalmacenes',
          getAlmacenesNotInEmpleado: 'hrm/empleadosalmacenes/getAlmacenNotInEmpleado',
        },
        firmas: {
          base: 'hrm/empleados/firmas',
          getFirmaByEmpleado: 'hrm/empleados/firmas/getFirmaByEmpleado',
        },
        dotacion: {
          base: 'hrm/terceroempleadodotacion',
        },
        deducciones: {
          base: 'hrm/empleadodeducciones',
          getDeduccionesNotInEmpleado: 'hrm/empleadodeducciones/getdeduccionesnotinempleado',
        },
        sedes: {
          base: 'hrm/terceroEmpleadoconfiguracionsedeempresa',
          getSedesNotInTerceroEmpleado: 'hrm/terceroEmpleadoconfiguracionsedeempresa/getSedesNotInTerceroEmpleado',
        },
      },
      disciplinario: {
        base: 'hrm/disciplinario',
        autocomplete: 'hrm/disciplinario/autocomplete',
        cambioEstado: 'hrm/disciplinario/cambioestado',
        faltas: {
          base: 'hrm/disciplinariofalta',
          notIn: 'hrm/disciplinariofalta/getfaltasnotindisciplinario'
        },
      },
      tiposEmpleados: {
        base: 'hrm/tiposempleados',
        sendExcelToMail: 'hrm/tiposempleados/sendExcelToMail',
        initFormComponent: 'hrm/tiposempleados/initformcomponent',
        tiposEmpleadosExcel: 'reports/hrm/tiposEmpleadosExcel',
        autocomplete: 'hrm/tiposempleados/autocomplete',
      },
      clasesRiesgos: {
        base: 'hrm/clasesriesgos',
        autocomplete: 'hrm/clasesriesgos/autocomplete',
        sendExcelToMail: 'hrm/clasesriesgos/sendExcelToMail',
        clasesRiesgosExcel: 'reports/hrm/clasesRiesgosExcel',
      },
      altoRiesgo: {
        base: 'hrm/altosriesgos',
        autocomplete: 'hrm/altosriesgos/autocomplete',
      },
      frecuenciasPagos: {
        base: 'hrm/frecuenciaspagos',
        autocomplete: 'hrm/frecuenciaspagos/autocomplete',
        sendExcelToMail: 'hrm/frecuenciaspagos/sendExcelToMail',
        frecuenciasPagosExcel: 'reports/hrm/frecuenciasPagosExcel',
      },
      subTiposEmpleados: {
        base: 'hrm/subtiposempleados',
        autocomplete: 'hrm/subtiposempleados/autocomplete',
        sendExcelToMail: 'hrm/subtiposempleados/sendExcelToMail',
        excel: 'reports/hrm/subTiposEmpleadosExcel',
      },
      evaluacionCapacitacionEncuesta: {
        base: 'hrm/evaluacioncapacitacionencuesta',
        initFormComponent: 'hrm/evaluacioncapacitacionencuesta/initFormComponent',
      },
      evaluacionCapacitacionEncuestaDetalle: {
        base: 'hrm/evaluacioncapacitacionencuestadetalle',
      },
      tiposContratos: {
        base: 'hrm/tiposcontratos',
        initFormComponent: 'hrm/tiposcontratos/initFormComponent',
        autocomplete: 'hrm/tiposcontratos/autocomplete',
        sendExcelToMail: 'hrm/tiposcontratos/sendExcelToMail',
        getPrestacionesSocialesByTipoContrato: 'hrm/tiposcontratos/getPrestacionesSocialesByTipoContrato',
        tiposContratosExcel: 'reports/hrm/tiposContratosExcel',
      },
      tiposContratosPrestacionesSociales: {
        base: 'hrm/tipocontratoprestacionsocial',
        getPrestacionesSocialesNotInTipoContrato: 'hrm/'
          + 'tipocontratoprestacionsocial/getPrestacionesSocialesNotInTipoContrato'
      },
      tiposContratosRetenciones: {
        base: 'hrm/tipocontratoretencion',
        getRetencionesNotInTipoContrato: 'hrm/tipocontratoretencion/getRetencionesNotInTipoContrato',
      },
      tiposCotizantes: {
        base: 'hrm/tiposcotizantes',
        autocomplete: 'hrm/tiposcotizantes/autocomplete',
      },
      tiposPlanillas: {
        base: 'hrm/tiposplanillas',
        autocomplete: 'hrm/tiposplanillas/autocomplete',
      },
      responsabilidades: {
        base: 'hrm/responsabilidades',
        autocomplete: 'hrm/responsabilidades/autocomplete',
      },
      requisitoContratacion: {
        base: 'hrm/requisitoscontratacion',
        autocomplete: 'hrm/requisitoscontratacion/autocomplete',
        sendExcelToMail: 'hrm/requisitoscontratacion/sendExcelToMail',
        requisitoContratacionExcel: 'reports/hrm/requisitoContratacionExcel',
      },
      tiposHorasExtras: {
        base: 'hrm/tiposhorasextras',
        autocomplete: 'hrm/tiposhorasextras/autocomplete',
        sendExcelToMail: 'hrm/tiposhorasextras/sendExcelToMail',
        excel: 'reports/hrm/tiposHorasExtrasExcel',
      },
      tiposPermisos: {
        base: 'hrm/tipospermisos',
        autocomplete: 'hrm/tipospermisos/autocomplete',
        sendExcelToMail: 'hrm/tipospermisos/sendExcelToMail',
        excel: 'reports/hrm/tipoPermisoExcel',
      },
      tiposDeducciones: {
        base: 'hrm/tiposdeducciones',
        autocomplete: 'hrm/tiposdeducciones/autocomplete',
        sendExcelToMail: 'hrm/tiposdeducciones/sendExcelToMail',
        excel: 'reports/hrm/tipoDeduccionExcel',
      },
      tiposBeneficios: {
        base: 'hrm/tiposbeneficios',
        autocomplete: 'hrm/tiposbeneficios/autocomplete',
        sendExcelToMail: 'hrm/tiposbeneficios/sendExcelToMail',
        excel: 'reports/hrm/tipoBeneficioExcel',
      },
      tiposIncapacidades: {
        base: 'hrm/tiposincapacidades',
        autocomplete: 'hrm/tiposincapacidades/autocomplete',
        sendExcelToMail: 'hrm/tiposincapacidades/sendExcelToMail',
        excel: 'reports/hrm/tipoIncapacidadExcel',
      },
      tiposBonificaciones: {
        base: 'hrm/tiposbonificaciones',
        autocomplete: 'hrm/tiposbonificaciones/autocomplete',
        sendExcelToMail: 'hrm/tiposbonificaciones/sendExcelToMail',
        excel: 'reports/hrm/tipoBonificacionExcel',
      },
      categoriasBeneficios: {
        base: 'hrm/categoriasbeneficios',
        autocomplete: 'hrm/categoriasbeneficios/autocomplete',
        sendExcelToMail: 'hrm/categoriasbeneficios/sendExcelToMail',
        getCategoriasByTipoId: 'hrm/categoriasbeneficios/getcategoriasbytipoid',
        excel: 'reports/hrm/categoriaBeneficioExcel',
      },
      recesos: {
        base: 'hrm/recesos',
        autocomplete: 'hrm/recesos/autocomplete',
        sendExcelToMail: 'hrm/recesos/sendExcelToMail',
        excel: 'reports/hrm/recesosExcel',
      },
      deducciones: {
        base: 'hrm/deducciones',
        autocomplete: 'hrm/deducciones/autocomplete',
        cambioEstado: 'hrm/deducciones/cambioEstado',
        sendExcelToMail: 'hrm/deducciones/sendExcelToMail',
        excel: 'reports/hrm/deduccionesExcel',
      },
      horasExtras: {
        base: 'hrm/horasextras',
        autocomplete: 'hrm/horasextras/autocomplete',
        cambioEstado: 'hrm/horasextras/cambioEstado',
        sendExcelToMail: 'hrm/horasextras/sendExcelToMail',
        initFormComponent: 'hrm/horasextras/initFormComponent',
        excel: 'reports/hrm/horasExtrasExcel',
      },
      reclutamiento: {
        candidatos: {
          base: 'hrm/terceroscandidatos',
          autocomplete: 'hrm/terceroscandidatos/autocomplete',
          sendExcelToMail: 'hrm/terceroscandidatos/sendExcelToMail',
          sendExcelToMailByCargoOrVacante: 'hrm/terceroscandidatos/sendExcelToMailByCargoOrVacante',
          initformcomponent: 'hrm/terceroscandidatos/initformcomponent',
          getCandidatoByCargoOrVacante: 'hrm/terceroscandidatos/getcandidatobycargoorvacante',
          excelByCargoOrVacante: 'reports/hrm/evaluacionCandidatoExcel',
          excel: 'reports/hrm/terceroCandidatoExcel',
          tabs: {
            pruebaSeleccion: 'hrm/tercerocandidatopruebaseleccion',
            examenMedico: 'hrm/tercerocandidatoexamenmedico',
            requisitoCargo: 'hrm/tercerocandidatorequisitocargo',
            catalogoHabilidad: 'hrm/tercerocandidatocatalogohabilidad',
            catalogoCompetencia: 'hrm/tercerocandidatocatalogocompetencia',
            entrevista: 'hrm/tercerocandidatoentrevista',
          },
        },
        pruebaSeleccion: {
          base: 'hrm/pruebaseleccion',
          autocomplete: 'hrm/pruebaseleccion/autocomplete',
          sendExcelToMail: 'hrm/pruebaseleccion/sendExcelToMail',
          excel: 'reports/hrm/pruebaSeleccionExcel',
        },
        examenesMedicos: {
          base: 'hrm/examenesmedicos',
          autocomplete: 'hrm/examenesmedicos/autocomplete',
          sendExcelToMail: 'hrm/examenesmedicos/sendExcelToMail',
          excel: 'reports/hrm/examenMedicoExcel',
        },
        requisitosCargos: {
          base: 'hrm/requisitoscargos',
          autocomplete: 'hrm/requisitoscargos/autocomplete',
          sendExcelToMail: 'hrm/requisitoscargos/sendExcelToMail',
          excel: 'reports/hrm/requisitoCargoExcel',
        },
        catalogoHabilidades: {
          base: 'hrm/catalogohabilidades',
          autocomplete: 'hrm/catalogohabilidades/autocomplete',
          sendExcelToMail: 'hrm/catalogohabilidades/sendExcelToMail',
          excel: 'reports/hrm/catalogoHabilidadExcel',
        },
        catalogoCompetencias: {
          base: 'hrm/catalogocompetencias',
          autocomplete: 'hrm/catalogocompetencias/autocomplete',
          sendExcelToMail: 'hrm/catalogocompetencias/sendExcelToMail',
          excel: 'reports/hrm/catalogoCompetenciaExcel',
        },
        entrevistas: {
          base: 'hrm/entrevistas',
          autocomplete: 'hrm/entrevistas/autocomplete',
          getEntrevistaBySolicitud: 'hrm/entrevistas/getentrevistabysolicitud',
          sendExcelToMail: 'hrm/entrevistas/sendExcelToMail',
          excel: 'reports/hrm/entrevistaExcel',
        },
      },
      seguimientoYretencion: {
        tiposCapacitaciones: {
          base: 'hrm/tiposcapacitaciones',
          autocomplete: 'hrm/tiposcapacitaciones/autocomplete',
          sendExcelToMail: 'hrm/tiposcapacitaciones/sendExcelToMail',
          excel: 'reports/hrm/tipoCapacitacionExcel',
        },
        temasCapacitaciones: {
          base: 'hrm/temascapacitaciones',
          autocomplete: 'hrm/temascapacitaciones/autocomplete',
        },
      },
      gestionTiempo: {
        tareas: {
          equiposTrabajo: {
            base: 'hrm/equipostrabajos',
            autocomplete: 'hrm/equipostrabajos/autocomplete',
            cambioEstado: 'hrm/equipostrabajos/cambioestado',
            excel: 'reports/hrm/equipoTrabajoExcel',
            empleados: {
              base: 'hrm/equipostrabajosempleados',
              getEmpleadosNotInEquipo: 'hrm/equipostrabajosempleados/getempleadosnotinequipo',
            },
          },
          hitos: {
            base: 'hrm/hitos',
            autocomplete: 'hrm/hitos/autocomplete',
          },
          proyectos: {
            base: 'hrm/proyectos',
            autocomplete: 'hrm/proyectos/autocomplete',
            miembros: {
              base: 'hrm/proyectosmiembros',
              getMiembrosNotInProyecto: 'hrm/proyectosmiembros/getmiembrosnotinproyecto',
            },
          },
        },
        gestionDeTiempo: {
          tiposTurnos: {
            base: 'hrm/tiposturnos',
            autocomplete: 'hrm/tiposturnos/autocomplete',
          },
          unidadesTiempo: {
            base: 'hrm/unidadestiempo',
            autocomplete: 'hrm/unidadestiempo/autocomplete',
          },
          restriccionesLaborales: {
            base: 'hrm/restriccioneslaborales',
            autocomplete: 'hrm/restriccioneslaborales/autocomplete',
            sendExcelToMail: 'hrm/restriccioneslaborales/sendExcelToMail',
            excel: 'reports/hrm/restriccionLaboralExcel',
          },
          tipoModalidadTrabajo: {
            base: 'hrm/tiposmodalidadestrabajo',
            autocomplete: 'hrm/tiposmodalidadestrabajo/autocomplete',
          },
          modalidadTrabajo: {
            base: 'hrm/modalidadestrabajo',
            autocomplete: 'hrm/modalidadestrabajo/autocomplete',
          },
        },
        ausencias: {
          licencias: {
            base: 'hrm/licencias',
            autocomplete: 'hrm/licencias/autocomplete',
          },
        },
      },
      desvinculacionLaboral: {
        requisitosPazYSalvo: {
          base: 'hrm/requisitospazysalvo',
          autocomplete: 'hrm/requisitospazysalvo/autocomplete',
        },
        pazYSalvo: {
          base: 'hrm/pazysalvo',
          autocomplete: 'hrm/pazysalvo/autocomplete',
          requisitosPazYSalvo: {
            base: 'hrm/pazysalvorequisitospazysalvo',
            getRequisitosNotInPazYSalvo: 'hrm/pazysalvorequisitospazysalvo/getrequisitosnotinpazysalvo',
          },
        },
      },
      pila: {
        clasesAportantes: {
          base: 'hrm/clasesaportantes',
          excel: 'reports/hrm/claseaportanteexcel',
        },
      },
      gestionTalento: {
        nivelesEducativos: {
          base: 'hrm/niveleseducativos',
          autocomplete: 'hrm/niveleseducativos/autocomplete',
          sendExcelToMail: 'hrm/niveleseducativos/sendExcelToMail',
          excel: 'reports/hrm/nivelEducativoExcel',
        },
        tiposRestricciones: {
          base: 'hrm/tiposrestricciones',
          autocomplete: 'hrm/tiposrestricciones/autocomplete',
        },
        filtroGestionTalento: {
          base: 'hrm/filtrosgestiontalento',
          autocomplete: 'hrm/filtrosgestiontalento/autocomplete',
        },
      },
      eps: {
        base: 'hrm/eps',
        autocomplete: 'hrm/eps/autocomplete',
        epsExcel: 'reports/hrm/epsExcel',
        sendExcelToMail: 'hrm/eps/sendExcelToMail',
      },
      tipoAfiliacionEps: {
        base: 'hrm/tipoafiliacioneseps',
        sendExcelToMail: 'hrm/tipoafiliacioneseps/sendExcelToMail',
        tiposAfiliacionExcel: 'reports/hrm/tipoAfiliacionExcel',
      },
    },
  },
  sst: {
    maestros: {
      consecutivos: {
        base: 'sst/consecutivos',
      },
      documentos: {
        base: 'sst/documentos',
        autocomplete: 'sst/documentos/autocomplete',
      },
      tipoRiesgo: {
        base: 'sst/tiposriesgos',
        autocomplete: 'sst/tiposriesgos/autocomplete',
      },
      clasificacionRiesgo: {
        base: 'sst/clasificacionesriesgos',
        autocomplete: 'sst/clasificacionesriesgos/autocomplete',
        getClasificacionesByTipo: 'sst/clasificacionesriesgos/getclasificacionesbytipo',
      },
      probabilidadRiesgo: {
        base: 'sst/probabilidadesriesgos',
        autocomplete: 'sst/probabilidadesriesgos/autocomplete',
      },
      impactoRiesgo: {
        base: 'sst/impactosriesgos',
        autocomplete: 'sst/impactosriesgos/autocomplete',
      },
      tipoEquipoTrabajo: {
        base: 'sst/tiposequipostrabajo',
        autocomplete: 'sst/tiposequipostrabajo/autocomplete',
      },
      elementosProteccionPersonal: {
        base: 'sst/elementosproteccionpersonal',
        autocomplete: 'sst/elementosproteccionpersonal/autocomplete',
        cargos: {
          base: 'sst/elementosproteccionpersonalcargos',
          getCargosNotInElemetoPP: 'sst/elementosproteccionpersonalcargos/getcargosnotinelemetopp',
        },
        roles: {
          base: 'sst/elementosproteccionpersonalroles',
          getRolesNotInElemetoPP: 'sst/elementosproteccionpersonalroles/getrolesnotinelemetopp',
        },
        empleados: {
          base: 'sst/elementosproteccionpersonalempleados',
          getEmpleadosNotInElemetoPP: 'sst/elementosproteccionpersonalempleados/getempleadosnotinelementopp',
        },
      },
    },
    procesos: {
      identEvalRiesgo: {
        excel: 'reports/sst/identEvalRiesgoExcel',
        base: 'sst/identificacionevaluacionriesgos',
        cambioEstado: 'sst/identificacionevaluacionriesgos/cambioestado',
        autocomplete: 'sst/identificacionevaluacionriesgos/autocomplete',
        sendExcelToMail: 'sst/identificacionevaluacionriesgos/sendExcelToMail',
        evaluaciones: {
          base: 'sst/identificacionevaluacionriesgoevaluaciones',
          cambioEstado: 'sst/identificacionevaluacionriesgoevaluaciones/cambioestado',
        },
      },
      regAccidenteLab: {
        base: 'sst/registroaccidenteslaborales',
        excel: 'reports/sst/regaccidentelaboralexcel',
        cambioEstado: 'sst/registroaccidenteslaborales/cambioestado',
        testigos: {
          base: 'sst/registroaccidenteslaboralestestigos',
          getTestigosNotInRegistro: 'sst/registroaccidenteslaboralestestigos/gettestigosnotinregistro',
        },
      },
    },
  },
  plm: {
    maestros: {
      clasificacionProceso: {
        base: 'plm/clasificacionesprocesos',
      },
      clasificacionRecurso: {
        base: 'plm/clasificacionesrecursos',
      },
      consecutivo: {
        base: 'plm/consecutivos',
      },
      documento: {
        base: 'plm/documentosplm',
      },
      operacion: {
        base: 'plm/operacion',
        initForm: 'plm/operacion/initForm',
      },
      operacionImagenes: {
        base: 'plm/operacionesimagenes',
        updatePrincipal: 'plm/operacionesimagenes/updatePrincipal',
      },
      proceso: {
        base: 'plm/procesos',
      },
      producto: {
        base: 'inventario/productos',
        initformcomponent: 'inventario/productos/initformcomponent',
        getAtributosByProducto: 'inventario/productos/getAtributosByProducto',
        autocomplete: 'inventario/productos/autocomplete',
        autocompleteAllProductos: 'inventario/productos/autocompleteAllProductos',
        allProductos: 'inventario/productos/getAllProductos',
        getMantenimientos: 'inventario/productos/getmantenimientosforproducto',
        upload: 'inventario/productos/upload',
        copy: 'inventario/productos/duplicarProductos',
        autocompleteCompuestos: 'inventario/productos/autocompletecompuesto',
        plantillaCargueMasivo: 'reports/plantillaProductoUpload/excel',
        plantillaCargueMasivoComposicion: 'reports/plantillaComposicionUpload/excel',
        productosWithExistencia: 'inventario/productos/getProductosWithExistencia',
        getProductosListaPrecio: 'inventario/productos/getProductosListaPrecio',
        getProductosCirugiaNotInListaPrecio: 'inventario/productos/getProductosCirugiaNotInListaPrecio',
        getKitsNotInListaPrecio: 'inventario/productos/getKitsNotInListaPrecio',
        getProductoByAbreviatura: 'inventario/productos/getProductoByAbreviatura',
        getProductosCirugiaExtraForPrepedido: 'inventario/productos/getProductosCirugiaExtraForPrepedido',
        autocompleteProductosWithExistencia: 'inventario/productos/autocompleteProductosWithExistencia',
        getProductosExtraForPrepedido: 'inventario/productos/getProductosExtraForPrepedido',
        productosExcel: 'reports/inventarios/productosExcel',
        productosComposicionExcel: 'reports/inventarios/productocomposicionexcel',
        getProductosByBodega: 'inventario/productos/getProductosByBodega',
      },
      // Tabs de productos
      atributosComercial: {
        base: 'inventario/productos/atributoscomerciales',
      },
      atributosControl: {
        base: 'inventario/productos/atributoscontrol',
      },
      atributosDescripcion: {
        base: 'inventario/productos/atributosdescriptivos',
      },
      historicoProductos: {
        base: 'inventario/precioshistoricos',
      },
      perfilLogistico: {
        list: 'inventario/perfileslogisticos',
        new: 'inventario/perfileslogisticos/create',
        getId: 'inventario/perfileslogisticos/id',
        update: 'inventario/perfileslogisticos/update',
        delete: 'inventario/perfileslogisticos/delete',
      },
      productoImagenes: {
        base: 'inventario/productosimagenes',
        updatePrincipal: 'inventario/productosimagenes/updatePrincipal',
      },
      productosperfileslogisticosdetalles: {
        base: 'inventario/productosperfileslogisticosdetalles',
        list: 'inventario/productosperfileslogisticosdetalles/byproductogroupbyperfil',
        perfilAlmacenamientoByRecepcion: 'inventario/'
          + 'productosperfileslogisticosdetalles/perfilalmacenamientobyrecepcion',
        updateEstadosPerfiles: 'inventario/productosperfileslogisticosdetalles/updateestadosperfiles',
      },
      proveedorProducto: {
        base: 'inventario/productoproveedores',
        getProveedorNotInProducto: 'inventario/productoproveedores/getProveedorNotInProducto',
      },
      composicion: {
        base: 'inventario/productosterminadoscomposiciones',
        editFieldColumn: 'inventario/productosterminadoscomposiciones/editfieldcolumn',
        upload: 'inventario/productosterminadoscomposiciones/upload',
      },
      productosImpuestos: {
        base: 'inventario/productosimpuestos',
        getImpuestosNotInProducto: 'inventario/productosimpuestos/getImpuestosNotInProducto',
      },
      productosImpuestosCompra: {
        base: 'inventario/productosimpuestoscompra',
        getImpuestosNotInProducto: 'inventario/productosimpuestoscompra/getImpuestosNotInProducto',
      },
      productosImpuestosVenta: {
        base: 'inventario/productosimpuestosVenta',
        getImpuestosNotInProducto: 'inventario/productosimpuestosVenta/getImpuestosNotInProducto',
      },
      productosRetenciones: {
        base: 'inventario/productosretenciones',
        getRetencionesNotInProducto: 'inventario/productosretenciones/getRetencionesNotInProducto',
      },

    },
    procesos: {
      ordenProduccion: {
        base: 'plm/ordenesproduccion',
        initFormcomponent: 'plm/ordenesproduccion/initformcomponent',
        cambioEstado: 'plm/ordenesproduccion/cambioestado',
        productosByOrdenTrabajo: 'plm/ordenesproduccion/productosbyordentrabajo',
        validateCantidad: 'plm/ordenesproduccion/validatecantidad',
        autocomplete: 'plm/ordenesproduccion/autocomplete',
      },
      ordenProduccionMateriales: {
        base: 'plm/ordenesproduccionmateriales',
        existenciasByProducto: 'plm/ordenesproduccionmateriales/existenciasbyproducto',
        existencias: 'plm/ordenesproduccionmateriales/existencias',
      },
      cirugia: {
        base: 'plm/ordenestrabajo',
        initFormcomponent: 'plm/ordenestrabajo/initformcomponent',
        cambioEstado: 'plm/ordenestrabajo/cambioestado',
      },
      fichaTecnica: {
        base: 'plm/fichatecnica',
        initFormComponent: 'plm/fichatecnica/initformcomponent',
        cambioEstado: 'plm/fichatecnica/cambioestado',
        getCaracteristicasByTipoProduct: 'plm/fichatecnica/getcategoriabytipoproducto',
        autocomplete: 'plm/fichatecnica/autocompleteproducto',
      },
      fichaTecnicaConsumo: {
        base: 'plm/fichatecnicamaterial',
        initFormComponent: 'plm/fichatecnicamaterial/initformcomponent',
      },
      fichaTecnicaFlujo: {
        base: 'plm/fichatecnicaproceso',
      },
      fichaTecnicaOperacion: {
        base: 'plm/fichatecnicaoperacion',
        initFormComponent: 'plm/fichatecnicaoperacion/initformcomponent',
      },
      fichaTecnicaSustituto: {
        base: 'plm/fichatecnicasustituto',
        initFormComponent: 'plm/fichatecnicasustituto/initformcomponent',
        autocompleteProductoMateriaPrima: 'plm/fichatecnicasustituto/autocompleteproductomateriaprima',
      },
      ordenTrabajoPedido: {
        base: 'plm/ordenestrabajopedidos',
        initFormcomponent: 'plm/ordenestrabajopedidos/initformcomponent',
        pedidosByEstado: 'plm/ordenestrabajopedidos/pedidosbyestado',
      },
      ordenTrabajoAgrupacion: {
        base: 'plm/ordenestrabajoagrupacion',
        initFormcomponent: 'plm/ordenestrabajoagrupacion/initformcomponent',
        agrupacionByPedidos: 'plm/ordenestrabajoagrupacion/agrupacionbypedidos',
      },
      ordenTrabajoProducto: {
        base: 'plm/ordenestrabajoproducto',
        listProductosPaginados: 'plm/ordenestrabajoproducto/listProductosPaginados',
        initFormcomponent: 'plm/ordenestrabajoproducto/initformcomponent',
        productos: 'plm/ordenestrabajoproducto/productos',
        productosExtra: 'plm/ordenestrabajoproducto/productosExtra',
        listaProductosExtra: 'plm/ordenestrabajoproducto/listaProductosExtra',
      },
    },
  },
  pqrsf: {
    maestros: {
      consecutivos: {
        base: 'pqrsf/consecutivos',
        excel: 'reports/consecutivos/excel',
      },
      documentosPqrsf: {
        base: 'pqrsf/documentopqrsf',
        autocomplete: 'pqrsf/documentopqrsf/autocomplete',
        excel: 'reports/pqrsf/documentoPqrsfExcel',
      },
      tipoSolicitud: {
        base: 'pqrsf/requeststypes',
        autocomplete: 'pqrsf/requeststypes/autocomplete',
        sendExcelToMail: 'pqrsf/requeststypes/sendExcelToMail',
        tiposSolicitudesExcel: 'reports/pqrsf/tiposSolicitudesExcel',
      },
    },
    procesos: {
      solicitud: {
        base: 'pqrsf/requests',
        sendExcelToMail: 'pqrsf/requests/sendExcelToMail',
        initFormComponent: 'pqrsf/requests/initFormComponent',
        cambioEstado: 'pqrsf/requests/cambioEstado',
        historicoAsignacion: 'pqrsf/requests/historicoasignacion',
        solicitudExcel: 'reports/pqrsf/solicitudExcel',
      },
      solicitudComentarios: {
        base: 'pqrsf/requestscomentarios',
      },
    },
  },
  registroAcceso: {
    accesoEmpleado: {
      base: 'hrm/terceroempleadoregistroapp',
    },
  },
  reports: {
    programacion: 'reports/programacion/excel',
    reporteGasto: 'reports/reportegasto/excel',
    ordenCompra: 'reports/ordenesCompras/informe',
    bodega: 'reports/bodegas/excel',
  },
  tesoreria: {
    maestros: {
      banco: {
        base: 'tesoreria/tercerosbancos',
        tercerosBancosExcel: 'reports/tesoreria/tercerosBancosExcel',
        autocomplete: 'tesoreria/tercerosbancos/autocomplete',
        initFormcomponent: 'tesoreria/tercerosbancos/initformcomponent',
        tabs: {
          cuentas: {
            base: 'tesoreria/tercerosbancoscuentas',
            getCuentaByBanco: 'tesoreria/cuentasbancarias/getcuentabybanco',
          },
          conceptosTesoreria: {
            base: 'tesoreria/tercerobancoconceptotesoreria',
            getConceptosNotInBanco: 'tesoreria/tercerobancoconceptotesoreria/getConceptosNotInTerceroBanco'
          },
          impuestos: {
            base: 'tesoreria/tercerobancoimpuesto',
            autocompleteImpuestosNotInTerceroBanco: 'tesoreria/'
              + 'tercerobancoimpuesto/autocompleteImpuestosNotInTerceroBanco'
          },
          retenciones: {
            base: 'tesoreria/tercerobancoretencion',
            autocompleteRetencionesNotInTerceroBanco: 'tesoreria/'
              + 'tercerobancoretencion/autocompleteRetencionesNotInTerceroBanco'
          },
        },
      },
      cajas: {
        base: 'tesoreria/cajas',
        autocomplete: 'tesoreria/cajas/autocomplete',
        cajasExcel: 'reports/tesoreria/cajasExcel',
        tabs: {
          conceptosTesoreria: {
            base: 'tesoreria/cajasconceptostesoreria',
            updateDefectoRecibo: 'tesoreria/cajasconceptostesoreria/updateDefectoRecibo',
            updateDefectoEgreso: 'tesoreria/cajasconceptostesoreria/updateDefectoEgreso',
            getConceptosNotInCaja: 'tesoreria/cajasconceptostesoreria/getConceptosNotInCaja'
          },
        }
      },
      categorizacionGastos: {
        base: 'tesoreria/categorizacionesgastos',
        autocomplete: 'tesoreria/categorizacionesgastos/autocomplete',
        categorizacionGastosExcel: 'reports/tesoreria/categorizacionGastoExcel',
      },
      conceptosTesoreria: {
        base: 'tesoreria/conceptostesoreria',
        autocomplete: 'tesoreria/conceptostesoreria/autocomplete',
        initFormComponent: 'tesoreria/conceptostesoreria/initFormComponent',
        tabs: {
          categorizacionCuentaContables: {
            base: 'tesoreria/conceptotesoreriacategorizacioncuenta',
            initFormComponent: 'tesoreria/conceptotesoreriacategorizacioncuenta/initFormComponent',
            distribucion: {
              base: 'tesoreria/conceptotesoreriadistribucion',
              initFormComponent: 'tesoreria/conceptotesoreriadistribucion/initFormComponent',
            }
          }
        }
      },
      clasificacionBanco: {
        base: 'tesoreria/clasificacionesbancos',
        autocomplete: 'tesoreria/clasificacionesbancos/autocomplete',
        clasificacionesBancosExcel: 'reports/tesoreria/clasificacionesBancosExcel',
      },
      consecutivosTesoreria: {
        base: 'tesoreria/consecutivos',
        consecutivosTesoreriaExcel: 'reports/consecutivos/excel',
      },
      cuentasBancarias: {
        base: 'tesoreria/cuentasbancarias',
        autocomplete: 'tesoreria/cuentasbancarias/autocomplete',
        autocompleteByFiltro: 'tesoreria/cuentasbancarias/autocompletebyfiltro',
        cuentasBancariasExcel: 'reports/tesoreria/cuentasBancariasExcel',
        getDataForSelect: 'tesoreria/cuentasbancarias/getdataforselect',
        tabs: {
          conceptosTesoreria: {
            base: 'tesoreria/cuentasbancariasconceptostesoreria',
            updateDefectoRecibo: 'tesoreria/cuentasbancariasconceptostesoreria/updateDefectoRecibo',
            updateDefectoEgreso: 'tesoreria/cuentasbancariasconceptostesoreria/updateDefectoEgreso',
            getConceptosNotInCaja: 'tesoreria/cuentasbancariasconceptostesoreria/getConceptosNotInCuentaBancaria'
          },
        },
      },
      documentosTesoreria: {
        base: 'tesoreria/documentostesoreria',
        autocomplete: 'tesoreria/documentostesoreria/autocomplete',
        autocompleteExcludingId: 'tesoreria/documentostesoreria/autocompleteExcludingId',
        initFormComponent: 'tesoreria/documentostesoreria/initFormComponent',
        documentosTesoreriaExcel: 'reports/tesoreria/documentosTesoreriaExcel',
        tabs: {
          roles: {
            base: 'tesoreria/documentostesoreriaroles',
            getRolesNotInDocumento: 'tesoreria/documentostesoreriaroles/getRolesNotInDocumentoTesoreria',
          }
        },
      },
      formaPago: {
        base: 'tesoreria/formaspagos',
        formasPagosExcel: 'reports/tesoreria/formasPagosExcel',
        autocomplete: 'tesoreria/formaspagos/autocomplete',
      },
      mediosPago: {
        base: 'general/mediospagos',
        autocomplete: 'general/mediospagos/autocomplete',
        mediosPagosExcel: 'reports/tesoreria/mediosPagosExcel',
      },
      moneda: {
        base: 'general/monedas',
        getTrm: 'general/monedas/trm',
        autocomplete: 'general/monedas/autocomplete',
        monedasExcel: 'reports/tesoreria/monedasExcel',
      },
      tarjetasCredito: {
        base: 'tesoreria/tarjetascredito',
        autocomplete: 'tesoreria/tarjetascredito/autocomplete',
        tarjetasCreditosExcel: 'reports/tesoreria/tarjetasCreditosExcel',
        conceptosTesoreria: {
          base: 'tesoreria/tarjetascreditoconceptostesoreria',
          getNotConceptosInTarjeta: 'tesoreria/tarjetascreditoconceptostesoreria/getconceptosnotintarjeta',
        },
      },
      tiposNotaCredito: {
        base: 'tesoreria/tiposnotacredito',
        autocomplete: 'tesoreria/tiposnotacredito/autocomplete',
        tiposNotasCreditosExcel: 'reports/tesoreria/tiposNotasCreditosExcel',
      },
      tiposNotaDebito: {
        base: 'tesoreria/tiposnotadebito',
        autocomplete: 'tesoreria/tiposnotadebito/autocomplete',
        tiposNotasDebitosExcel: 'reports/tesoreria/tiposNotasDebitosExcel',
      },
      tiposDocElectronicos: {
        base: 'tesoreria/tiposdocumentoselectronicos',
        autocomplete: 'tesoreria/tiposdocumentoselectronicos/autocomplete',
      },
      tipoCuentaBancaria: {
        base: 'tesoreria/tiposcuentasbancarias',
        tiposCuentasBancariasExcel: 'reports/tesoreria/tiposCuentasBancariasExcel',
        autocomplete: 'tesoreria/tiposcuentasbancarias/autocomplete',
      },
      tipoPago: {
        base: 'tesoreria/tipospagos',
        autocomplete: 'tesoreria/tipospagos/autocomplete',
        tiposPagosExcel: 'reports/tesoreria/tiposPagosExcel',
      },
      grupoConceptoTesoreria: {
        base: 'tesoreria/gruposconceptostesoreria',
        autocomplete: 'tesoreria/gruposconceptostesoreria/autocomplete',
        grupoConceptoTesoreria: 'reports/tesoreria/grupoConceptoTesoreriaExcel',
      },
      tipoTasas: {
        base: 'tesoreria/tipostasas',
        autocomplete: 'tesoreria/tipostasas/autocomplete',
        tiposTasasExcel: 'reports/tesoreria/tiposTasasExcel',
      },
      tiposImpuestos: {
        base: 'tesoreria/tiposimpuestos',
        autocomplete: 'tesoreria/tiposimpuestos/autocomplete',
        tiposImpuestosExcel: 'reports/tesoreria/tiposImpuestosExcel',
      },
      tiposRetenciones: {
        base: 'tesoreria/tiposretenciones',
        autocomplete: 'tesoreria/tiposretenciones/autocomplete',
        getActivosOrIdIn: 'tesoreria/tiposretenciones/getActivosOrIdIn',
        tiposRetencionesExcel: 'reports/tesoreria/tiposRetencionesExcel',
      },
    },
    procesos: {
      cajaMenor: {
        base: 'tesoreria/cajamenor',
        cajaMenorMovimiento: 'tesoreria/cajasmenoresmovimientos',
        excel: 'reports/tesoreria/cajasMenoresExcel',
      },
      cajaMenorMovimiento: {
        base: 'tesoreria/cajasmenoresmovimientos',
      },
      gastoTesoreria: {
        base: 'tesoreria/gastotesoreria',
        excel: 'reports/tesoreria/gastosTesoreriaExcel',
        gastoTesoreriaProducto: {
          base: 'tesoreria/gastostesoreriasproductos',
          excel: 'reports/tesoreria/gastosTesoreriaProductoExcel',
          initFormcomponent: 'tesoreria/gastostesoreriasproductos/initFormComponent',
          getGastosTesoreriaProductosNotInGasto: 'tesoreria/'
            + 'gastostesoreriasproductos/getGastosTesoreriaProductosNotInGasto',
          impuesto: {
            base: 'tesoreria/gastostesoreriasproductosimpuestos',
          },
        },
        gastoTesoreriaServicio: {
          base: 'tesoreria/gastostesoreriaservicios',
          excel: 'reports/tesoreria/gastosTesoreriaServicioExcel',
          initFormcomponent: 'tesoreria/gastostesoreriaservicios/initFormComponent',
          getGastosTesoreriaServiciosNotInGasto: 'tesoreria/'
            + 'gastostesoreriaservicios/getGastosTesoreriaServiciosNotInGasto',
          impuesto: {
            base: 'tesoreria/gastostesoreriaserviciosimpuestos',
          },
        },
      },
      gastoRecurrente: {
        base: 'tesoreria/gastorecurrente',
        excel: 'reports/tesoreria/gastosRecurrentesExcel',
      },
      conciliacionBancaria: {
        base: 'tesoreria/conciliacionbancaria',
        excel: 'reports/tesoreria/conciliacionBancariaExcel',
        cambioEstado: 'tesoreria/conciliacionbancaria/cambioEstado',
        getLastConciliacionFromCuenta: 'tesoreria/conciliacionbancaria/getLastConciliacionFromCuenta',
        detalle: {
          base: 'tesoreria/conciliacionbancariadetalle',
          plantillaCargueMasivo: 'reports/plantillaUploadMovimientoPendiente/excel',
          upload: 'tesoreria/conciliacionbancariadetalle/upload',
          getTesoreriasWithValor: 'tesoreria/conciliacionbancariadetalle/gettesoreriawithvalor',
          storeFromTesoreria: 'tesoreria/conciliacionbancariadetalle/storefromtesoreria',
          storeNewTesoreria: 'tesoreria/conciliacionbancariadetalle/storenewtesoreria',
          deleteProcess: 'tesoreria/conciliacionbancariadetalle/deleteprocess',
        },
      },
      contratoTesoreria: {
        base: 'tesoreria/contratostesoreria',
        initFormComponent: 'tesoreria/contratostesoreria/initformcomponent',
        cambioEstado: 'tesoreria/contratostesoreria/cambioestado',
      },
      contratoTesoreriaTipoPago: {
        base: 'tesoreria/contratostesoreriatipospagos',
        initFormComponent: 'tesoreria/contratostesoreriatipospagos/initformcomponent',
      },
      causacionCredito: {
        base: 'tesoreria/causacioncredito',
        initFormComponent: 'tesoreria/causacioncredito/initFormComponent',
        excel: 'reports/tesoreria/causacioncreditoexcel',
      },
      notasCredito: {
        base: 'tesoreria/notascredito',
        indexInforme: 'tesoreria/notascredito/indexInforme',
        sendExcelToMail: 'tesoreria/notascredito/sendExcelToMail',
        initFormComponent: 'tesoreria/notascredito/initFormComponent',
        pdf: 'impresion/pdf/print/notaCredito',
        initFormDevolucionDinero: 'tesoreria/notascredito/initFormDevolucionDinero',
        getNotasCreditoByCliente: 'tesoreria/notascredito/getNotasCreditoByCliente',
        devolucionDinero: 'tesoreria/notascredito/devolucionDinero',
        cambioEstado: 'tesoreria/notascredito/cambioestado',
        creditoVentas: 'tesoreria/notacreditofactura',
        sendToDian: 'tesoreria/notascredito/sendToDian',
        getFacturasNotInNotaCredito: 'tesoreria/notacreditofactura/getFacturasNotInNotaCredito',
        report: 'reports/notascreditos/excel',
        informeNotaCredito: 'reports/notascreditos/informe',
        descuentos: {
          base: 'tesoreria/notascreditodescuentos',
          getDescuentosNotInNotaCredito: 'tesoreria/notascreditodescuentos/getDescuentosNotInNotaCredito',
          initFormComponent: 'tesoreria/notascreditodescuentos/initFormComponent',
        },
        facturaProductos: {
          base: 'tesoreria/notascreditofacturasproductos',
          updateTipoDevolucion: 'tesoreria/notascreditofacturasproductos/updateTipoDevolucion',
          updateValor: 'tesoreria/notascreditofacturasproductos/updateValor',
          updateCantidad: 'tesoreria/notascreditofacturasproductos/updateCantidad',
          getProductosNotInNotaCredito: 'tesoreria/notascreditofacturasproductos/getProductosNotInNotaCredito',
          impuestos: {
            base: 'tesoreria/notacreditofacturaproductoimpuesto',
            notIn: 'tesoreria/notacreditofacturaproductoimpuesto/getimpuestosnotinnotacreditofacturaproducto',
          },
          retenciones: {
            base: 'tesoreria/notacreditofacturaproductoretencion',
          },
        },
        facturaServicios: {
          base: 'tesoreria/notascreditofacturasservicios',
          getServiciosNotInNotaCredito: 'tesoreria/notascreditofacturasservicios/getServiciosNotInNotaCredito',
          impuestos: {
            base: 'tesoreria/notascreditoserviciosimpuestos',
            notIn: 'tesoreria/notascreditoserviciosimpuestos/getImpuestosNotInNotaCreditoServicio',
          },
        },
      },
      notasDebito: {
        base: 'tesoreria/notasdebito',
        indexInforme: 'tesoreria/notasdebito/indexInforme',
        pdf: 'impresion/pdf/print/notaDebito',
        sendExcelToMail: 'tesoreria/notasdebito/sendExcelToMail',
        getNotasDebitoByCliente: 'tesoreria/notasdebito/getNotasDebitoByTercero',
        initFormComponent: 'tesoreria/notasdebito/initFormComponent',
        cambioEstado: 'tesoreria/notasdebito/cambioestado',
        report: 'reports/notasdebitos/excel',
        informeNotaDebito: 'reports/notasdebitos/informe',
        impuestos: {
          base: 'tesoreria/notadebitoproductoimpuesto',
          getImpuestosNotInNotaDebitoProducto: 'tesoreria/'
            + 'notadebitoproductoimpuesto/getimpuestosnotinnotadebitoproducto',
        },
        retenciones: {
          base: 'tesoreria/notadebitoproductoretencion',
          getRetencionesNotInNotaDebitoProducto: 'tesoreria/'
            + 'notadebitoproductoretencion/getretencionesnotinnotadebitoproducto',
        },
        sendToDian: 'tesoreria/notasdebito/sendToDian',
      },
      notasDebitoFacturas: {
        base: 'tesoreria/notadebitofactura',
        getFacturasNotInNotaDebito: 'tesoreria/notadebitofactura/getFacturasNotInNotaDebito',
      },
      notasDebitoDescuentos: {
        base: 'tesoreria/notasdebitodescuentos',
        getDescuentosNotInNotaDebito: 'tesoreria/notasdebitodescuentos/getDescuentosNotInNotaDebito',
        initFormComponent: 'tesoreria/notasdebitodescuentos/initFormComponent',
      },
      notasDebitoProductos: {
        base: 'tesoreria/notasdebitoproductos',
        initFormComponent: 'tesoreria/notasdebitoproductos/initFormComponent',
        getProductosNotInNotaDebito: 'tesoreria/notasdebitoproductos/getProductosNotInNotaDebito',
      },
      notasDebitoServicios: {
        base: 'tesoreria/notasdebitoservicios',
        initFormComponent: 'tesoreria/notasdebitoservicios/initFormComponent',
        getServiciosNotInNotaDebito: 'tesoreria/notasdebitoservicios/getServiciosNotInNotaDebito',
        impuestos: {
          base: 'tesoreria/notadebitoservicioimpuesto',
          getImpuestosNotInNotaDebitoServicio: 'tesoreria/'
            + 'notadebitoservicioimpuesto/getimpuestosnotinnotadebitoservicio',
        },
        retenciones: {
          base: 'tesoreria/notadebitoservicioretencion',
          getRetencionesNotInNotaDebitoProducto: 'tesoreria/'
            + 'notadebitoservicioretencion/getretencionesnotinnotadebitoservicio',
        },
      },
      documentoFinanciero: {
        base: 'tesoreria/documentosfinancieros',
        initFormComponent: 'tesoreria/documentosfinancieros/initformcomponent',
        cambioEstado: 'tesoreria/documentosfinancieros/cambioestado',
        report: 'reports/documentosfinancieros/excel',
      },
      documentoFinancieroProrroga: {
        base: 'tesoreria/documentofinancieroprorroga',
        initFormComponent: 'tesoreria/documentofinancieroprorroga/initform',
      },
      egresos: {
        base: 'tesoreria/egreso',
        initFormComponent: 'tesoreria/egreso/initformegresocomponent',
        cambioEstado: 'tesoreria/egreso/cambioestado',
        report: 'reports/egresos/excel',
      },
      pagos: {
        base: 'tesoreria/pagos',
        initFormComponent: 'tesoreria/pagos/initFormComponent',
        cambioEstado: 'tesoreria/pagos/cambioestado',
        report: 'reports/pagos/excel',
      },
      conciliacionCompra: {
        base: 'tesoreria/conciliacioncompras',
        initFormComponent: 'tesoreria/conciliacioncompras/initformcomponent',
        cambioEstado: 'tesoreria/conciliacioncompras/cambioestado',
        getRecepcionesByConciliacion: 'tesoreria/conciliacioncompras/getRecepcionesByConciliacion',
        getOrdenesCompraByConciliacion: 'tesoreria/conciliacioncompras/getOrdenesCompraByConciliacion',
        getProductosByConciliacion: 'tesoreria/conciliacioncompras/getProductosByConciliacion',

      },
      pagosRecurrentes: {
        base: 'tesoreria/pagosrecurrentes',
      },
      consignacionesPorIdentificar: {
        base: 'tesoreria/consignacionesporidentificar',
        initFormComponent: 'tesoreria/consignacionesporidentificar/initFormComponent',
        cambioEstado: 'tesoreria/consignacionesporidentificar/cambioestado',
        excel: 'reports/tesoreria/consignacionIdentificarExcel',
      },
      movimientosSinIdentificar: {
        base: 'tesoreria/movimientossinidentificar',
        initFormComponent: 'tesoreria/movimientossinidentificar/initFormComponent',
        cambioEstado: 'tesoreria/movimientossinidentificar/cambioestado',
        report: 'reports/movimientossinidentificar/excel',
      },
      egresosCompras: {
        base: 'tesoreria/tesoreriaegresos',
        notInTesoreria: 'tesoreria/tesoreriaegresos/comprasnotindetalles',
      },
      forward: {
        base: 'tesoreria/forwards',
        initFormComponent: 'tesoreria/forwards/initformcomponent',
        cambioEstado: 'tesoreria/forwards/cambioestado',
        report: 'reports/forwards/excel',
      },
      forwardTemporada: {
        base: 'tesoreria/forwardtemporadas',
        notInForward: 'tesoreria/forwardtemporadas/temporadanotinforward',
      },
      recibosCaja: {
        base: 'tesoreria/reciboscaja',
        getTesoreriasByClienteAndUuid: 'tesoreria/reciboscaja/getTesoreriasByClienteAndUuid',
        initFormComponentFactura: 'tesoreria/reciboscaja/initFormComponentTesoreria',
        cambioEstado: 'tesoreria/reciboscaja/cambioestado',
        report: 'reports/reciboscaja/excel',
      },
      detalleRecibosCaja: {
        base: 'tesoreria/reciboscajadetalle',
        initFormComponent: 'tesoreria/reciboscajadetalle/initFormComponent',
        notInTesoreria: 'tesoreria/reciboscajadetalle/facturasnotindetalles',
        impuestos: {
          base: 'tesoreria/tesoreriadetalleimpuestos',
          getImpuestosNotInDetalle: 'tesoreria/tesoreriadetalleimpuestos/getImpuestosNotInDetalle',
        },
        retenciones: {
          base: 'tesoreria/tesoreriadetalleretenciones',
          getRetencionesNotInDetalle: 'tesoreria/tesoreriadetalleretenciones/getRetencionesNotInDetalle',
        },
      },
      comentarioReciboCaja: {
        base: 'tesoreria/tesoreriacomentario',
      },
      facturasPendientes: {
        base: 'tesoreria/facturaspendientes',
        notInFacturas: 'tesoreria/facturaspendientes/facturasnolistbycliente',
        editFieldColumn: 'tesoreria/facturaspendientes/editfieldcolumn',
      },
      programacionPago: {
        base: 'tesoreria/programacionpago',
        initFormComponent: 'tesoreria/programacionpago/initformcomponent',
        cambioEstado: 'tesoreria/programacionpago/cambioestado',
        report: 'reports/programacionespagos/excel',
      },
      otrosIngresos: {
        base: 'tesoreria/otroingreso',
        report: 'reports/otrosingresos/excel',
        cambioEstado: 'tesoreria/otroingreso/cambioestado',
      },
      comentarioOtroIngreso: {
        base: 'tesoreria/otroingresocomentario',
      },
    },
    consultas: {
      cuentasPorCobrar: {
        base: 'tesoreria/cuentasporcobrar',
        cambioEstado: 'tesoreria/cuentasporcobrar/cambioestado',
        excel: 'reports/tesoreria/cuentasPorCobrarExcel',
        getCodigosOperacion: 'tesoreria/cuentasporcobrar/getCodigosOperacion',
      },
      cuentasPorPagar: {
        base: 'tesoreria/cuentasporpagar',
        cambioEstado: 'tesoreria/cuentasporpagar/cambioestado',
        getCodigosOperacion: 'tesoreria/cuentasporpagar/getCodigosOperacion',
        excel: 'reports/tesoreria/cuentasPorPagarExcel',
      },
    },
  },
  rpdental: {
    informes: {
      programacion: 'rpdental/informes/programacion',
      reporteGasto: 'rpdental/informes/reportesgastos',
    },
    maestros: {
      especialidades: {
        base: 'rpdental/general/especialidades',
        sendExcelToMail: 'rpdental/general/especialidades/sendExcelToMail',
        especialidadesExcel: 'reports/rpdental/especialidadesExcel',
      },
      linea: {
        base: 'rpdental/general/lineas',
        indexActivos: 'rpdental/general/lineas/indexActivos',
        getLineasByGrupo: 'rpdental/general/lineas/getLineasByGrupo',
        lineasExcel: 'reports/rpdental/lineasExcel',
        sendExcelToMail: 'rpdental/general/lineas/sendExcelToMail',
      },
      grupo: {
        base: 'rpdental/general/grupos',
        gruposExcel: 'reports/rpdental/gruposExcel',
        indexActivos: 'rpdental/general/grupos/indexActivos',
        sendExcelToMail: 'rpdental/general/grupos/sendExcelToMail',
      },
      sistema: {
        base: 'rpdental/general/sistemas',
        initFormComponent: 'rpdental/general/sistemas/initFormComponent',
        getSistemasByGrupo: 'rpdental/general/sistemas/getSistemasByLinea',
        sistemasExcel: 'reports/rpdental/sistemasExcel',
        sendExcelToMail: 'rpdental/general/sistemas/sendExcelToMail',
      },
      diagnostico: {
        base: 'rpdental/general/diagnosticos',
        autocomplete: 'rpdental/general/diagnosticos/autocomplete',
        productos: 'rpdental/general/diagnosticosproductos',
        productosNotInDiagnostico: 'rpdental/general/diagnosticosproductos/productosNotInDiagnostico',
        diagnosticosExcel: 'reports/rpdental/diagnosticosExcel',
        sendExcelToMail: 'rpdental/general/diagnosticos/sendExcelToMail',
      },
      diagnosticoProductos: {
        base: 'rpdental/general/diagnosticosproductos',
      },
      terceros: {
        instituciones: {
          base: 'rpdental/general/instituciones',
          autocomplete: 'rpdental/general/instituciones/autocomplete',
          initFormComponent: 'rpdental/general/instituciones/initFormComponent',
          institucionesExcel: 'reports/rpdental/institucionesExcel',
          sendExcelToMail: 'rpdental/general/instituciones/sendExcelToMail',
        },
        pacientes: {
          base: 'rpdental/general/pacientes',
          autocomplete: 'rpdental/general/pacientes/autocomplete',
          initFormComponent: 'rpdental/general/pacientes/initFormComponent',
          atributosDescriptivos: 'rpdental/general/pacientes/atributosdescriptivos',
          eventos: 'rpdental/general/pacientes/eventos',
          tercerosPacientesExcel: 'reports/rpdental/tercerosPacientesExcel',
          sendExcelToMail: 'rpdental/general/pacientes/sendExcelToMail',
        },
        profesional: {
          base: 'rpdental/general/profesionales',
          indexcirujanos: 'rpdental/general/profesionales/indexcirujanos',
          autocomplete: 'rpdental/general/profesionales/autocomplete',
          initFormComponent: 'rpdental/general/profesionales/initFormComponent',
          atributosDescriptivos: 'rpdental/general/profesionales/atributosdescriptivos',
          tercerosProfesionalesExcel: 'reports/rpdental/tercerosProfesionalesExcel',
          sendExcelToMail: 'rpdental/general/profesionales/sendExcelToMail',
          profesionalEmpleado: {
            base: 'rpdental/terceroprofesionalempleado',
            initFormComponent: 'rpdental/terceroprofesionalempleado/initFormComponent',
          },
        },
      },
    },
    procesos: {
      ordenProduccionCirugia: {
        base: 'rpdental/plm/ordenesproduccioncirugia',
        autoComplete: 'rpdental/plm/ordenesproduccioncirugia/autocomplete',
      },
      programacion: {
        base: 'rpdental/programacion',
        sendExcelToMail: 'rpdental/programacion/sendExcelToMail',
        initFormComponent: 'general/programaciones/initFormComponent?programacionId=',
        cambioEstado: 'rpdental/programacion/cambioestado',
        reportProgramacionesExcel: 'reports/programacion/programacionesExcel',
        ordenesProduccionCirugias: {
          base: 'rpdental/programacion/listopc',
          cambioEstado: 'rpdental/plm/ordenesproduccioncirugia/cambioestado',
        },
        getOrdenproduccionCirugiaByPedido: {
          base: 'rpdental/programacion/getordenproduccioncirugiabypedido',
        },
        getCirugiasByEmpleadoId: 'rpdental/programacion/getcirugiasbyempleadoid',
      },
      reporteGastos: {
        base: 'rpdental/reportegastos',
        listGeneral: 'rpdental/reportegastos/listgeneral',
        cambioEstado: 'rpdental/reportegastos/cambioestado',
        getContenedoresByReporteGastoId: 'rpdental/reportegastos/getcontenedoresbyreportegasto',
        reportesGastosExcel: 'reports/reportegasto/reportesGastosExcel',
        pdf: 'impresion/pdf/print/repogasto',
        servicios: 'rpdental/reportegastosservicios',
        getServiciosNotInRepGasto: 'rpdental/reportegastosservicios/getServiciosNotInReporteGasto',
        verifyDespacho: 'rpdental/reportegastos/verifyDespacho',
        pqrsf: {
          base: 'rpdental/reportegastospqrsf',
        },
        firmas: {
          getFirmas: 'rpdental/reportegastos/firmas/getFirmasByReporteGasto',
        },
        imagenes: {
          base: 'rpdental/reportesgastosimagenes',
        },
      },
      reporteGastosBioseguridad: {
        base: 'rpdental/bioseguridadreportegasto',
      },
      reporteGastosMaterial: {
        base: 'rpdental/materialreportegasto',
        getTotal: 'rpdental/materialreportegasto/getTotalMateriales',
        editfieldColumnUso: 'rpdental/materialreportegasto/editfieldcolumnuso',
        editfieldColumnKit: 'rpdental/materialreportegasto/editfieldcolumnkit',
        editfieldColumnConsumo: 'rpdental/materialreportegasto/editfieldcolumnconsumo',
        getKitsByReporteGasto: 'rpdental/materialreportegasto/getKitsByReporteGasto',
      },
      cirugiasOrdenProduccion: {
        base: 'rpdental/cirugiasordenesproduccion/indexMaterialesFromOrdenProduccionCirugia',
      },
    },
  },
  scm: {
    compra: {
      informes: {
        ordenCompra: {
          base: 'compra/informes/ordencompra',
          pdf: 'impresion/pdf/print/ordencompra',
        },
        productos: {
          base: 'compra/ordenescomprasproductos/getProductosWithOrdenCompra',
          excel: 'reports/compraProductoReport/excel',
        },
      },
      maestros: {
        acuerdosComerciales: {
          base: 'scm/acuerdoscomerciales',
          initFormComponent: 'scm/acuerdoscomerciales/initFormComponent',
          autocomplete: 'scm/acuerdoscomerciales/autocomplete',
          acuerdosComercialesExcel: 'reports/compra/acuerdosComercialesExcel',
          sendExcelToMail: 'scm/acuerdoscomerciales/sendExcelToMail',
          acuerdosComercialesPaises: {
            base: 'scm/acuerdoscomercialespaises',
            getPaisesNotInAcuerdoComercial: 'scm/acuerdoscomercialespaises/getPaisesNotInAcuerdoComercial',
          },
        },
        agenteAduanero: {
          base: 'scm/agentesaduaneros',
          initForm: 'scm/agentesaduaneros/initForm',
          autocomplete: 'scm/agentesaduaneros/autocomplete',
          sendExcelToMail: 'scm/agentesaduaneros/sendExcelToMail',
          tercerosAgentesAduanerosExcel: 'reports/compra/tercerosAgentesAduanerosExcel',
        },
        requisitoProveedor: {
          base: 'compra/requisitosproveedores',
          excel: 'reports/compra/requisitosProveedores',
        },
        criterioEvaluacion: {
          base: 'compra/criteriosevaluaciones',
          excel: 'reports/compra/criteriosEvaluaciones',
        },
        clasificacionGasto: {
          base: 'compra/clasificacionesgastos',
          clasificacionesGastosExcel: 'reports/compra/clasificacionesGastosExcel',
          autocomplete: 'compra/clasificacionesgastos/autocomplete',
        },
        clasificacionProveedor: {
          base: 'compra/clasificacionesproveedores',
          autocomplete: 'compra/clasificacionesproveedores/autocomplete',
          sendExcelToMail: 'compra/clasificacionesproveedores/sendExcelToMail',
          clasificacionesProveedoresExcel: 'reports/compra/clasificacionesProveedoresExcel',
        },
        consecutivoCompra: {
          base: 'compra/consecutivos',
          consecutivosCompraExcel: 'reports/consecutivos/excel',
        },
        documentoCompra: {
          base: 'compra/documentoscompras',
          initFormComponent: 'compra/documentoscompras/initFormComponent',
          autocomplete: 'compra/documentoscompras/autocomplete',
          documentosCompraExcel: 'reports/compra/documentosCompraExcel',
        },
        gastos: {
          base: 'compra/gastos',
          initformcomponent: 'compra/gastos/initcrudcomponent',
          getgastoimportacion: 'compra/gastos/getgastoimportacion',
          indexGastoOrdenCompra: 'compra/gastos/indexgastoordencompra',
          autocomplete: 'compra/gastos/autocomplete',
          gastosExcel: 'reports/compra/gastosExcel',
          impuestos: {
            base: 'compra/gastosimpuestos',
            getImpuestosNotInGastos: 'compra/gastosimpuestos/getImpuestosNotInGasto',
          },
          retenciones: {
            base: 'compra/gastosretenciones',
            getRetencionesNotInGastos: 'compra/gastosretenciones/getRetencionesNotInGasto',
          },
          procesos: {
            base: 'compra/gastoproceso',
            getProcesosNotInGasto: 'compra/gastoproceso/getprocesosnotingasto',
          },
        },
        gastoscentrocostos: {
          base: 'compra/gastoscentrocostos',
        },
        gastoEntidad: {
          base: 'compra/gastoentidad',
          getGastosNotInEntidad: 'compra/gastoentidad/getgastosnotinentidad',
        },
        incoterms: {
          base: 'compra/incoterms',
          autocomplete: 'compra/incoterms/autocomplete',
          incotermsExcel: 'reports/compra/incotermsExcel',
          sendExcelToMail: 'compra/incoterms/sendExcelToMail',
        },
        proveedor: {
          base: 'compra/proveedores',
          initformcomponent: 'compra/proveedores/initCrudComponent',
          new: 'compra/proveedores/create',
          getId: 'compra/proveedores/id',
          update: 'compra/proveedores/update',
          delete: 'compra/proveedores/delete',
          autocomplete: 'compra/proveedores/autocomplete',
          autocompleteByClasification: 'compra/proveedores/autocompleteByClasification',
          tercerosAtributosComerciales: 'compra/proveedores/atributoscomerciales',
          getCuentaCorrienteInfo: 'compra/proveedores/getCuentaCorrienteInfo',
          sendExcelToMail: 'compra/proveedores/sendExcelToMail',
          plantillaCargueMasivo: 'reports/plantillaProveedoresUpload/excel',
          tercerosProveedoresExcel: 'reports/compra/tercerosProveedoresExcel',
          proveedorAcuerdoComercial: {
            base: 'compra/proveedoresacuerdoscomerciales',
            getAcuerdosComercialesNotInProveedor: 'compra/'
              + 'proveedoresacuerdoscomerciales/getAcuerdosComercialesNotInProveedor',
          },
          impuestos: {
            base: 'compra/terceroproveedorimpuesto',
          },
          retenciones: {
            base: 'compra/terceroproveedorretencion',
          },
          calendarioTrabajo: {
            base: 'compra/proveedorcalendariotrabajo',
            autocomplete: 'compra/proveedorcalendariotrabajo/autocomplete',
            excepcion: {
              base: 'compra/proveedorcalendariotrabajoexcepcion',
            },
            horario: {
              base: 'compra/proveedorcalendariotrabajohorario',
            },
          },
        },
        registroInvima: {
          base: 'compra/registrosinvima',
          autocomplete: 'compra/registrosinvima/autocomplete',
          cambioEstado: 'compra/registrosinvima/cambioestado',
          sendExcelToMail: 'compra/registrosinvima/sendExcelToMail',
          registrosInvimasExcel: 'reports/compra/registrosInvimasExcel',
        },
        costos: {
          base: 'general/costos',
          autocomplete: 'general/costos/autocomplete',
          initFormComponent: 'general/costos/initformcomponent',
          costosExcel: 'reports/generalmaestros/costosExcel',
          sendExcelToMail: 'general/costos/sendExcelToMail',
        },
        registroInvimaProductos: {
          base: 'compra/registrosinvimaproductos',
          cargueMasivo: 'compra/registrosinvimaproductos/upload',
          getProductosNotInRegistroInvima: 'compra/registrosinvimaproductos/getProductosNotInRegistroInvima',
          plantillaCargueMasivo: 'reports/plantillaRegistroInvimaProductoUpload/excel',
        },
        requisitosAduaneros: {
          base: 'compra/requisitosaduaneros',
          autocomplete: 'compra/requisitosaduaneros/autocomplete',
          sendExcelToMail: 'compra/requisitosaduaneros/sendExcelToMail',
          requisitosAduanerosExcel: 'reports/compra/requisitosAduanerosExcel',
        },
        requisitosImportacion: {
          base: 'compra/requisitosimportacion',
          autocomplete: 'compra/requisitosimportacion/autocomplete',
          sendExcelToMail: 'compra/requisitosimportacion/sendExcelToMail',
          requisitosImportacionesExcel: 'reports/compra/requisitosImportacionesExcel',
        },
        unidadEmpaque: {
          base: 'compra/unidadesempaques',
          autocomplete: 'compra/unidadesempaques/autocomplete',
          sendExcelToMail: 'compra/unidadesempaques/sendExcelToMail',
          unidadesEmpaquesExcel: 'reports/compra/unidadesEmpaquesExcel',
        },
      },
      procesos: {
        compra: {
          base: 'compra/ordenescompras',
          sendExcelToMail: 'compra/ordenescompras/sendExcelToMail',
          autocomplete: 'compra/ordenescompras/autocomplete',
          autocompleteOCNotInRecepcion: 'compra/ordenescompras/autocompleteOCNotInRecepcion',
          initFormcomponent: 'compra/ordenescompras/initFormComponent',
          initImpuestoscomponent: 'compra/ordenescompras/initImpuestosComponent',
          cambioEstado: 'compra/ordenescompras/cambioestado',
          report: 'reports/ordenesCompras/excel',
          getOrdenCompraNotInRecepcion: 'compra/ordenescompras/getOrdenCompraNotInRecepcion',
          getOrdenesComprasByProveedor: 'compra/ordenescompras/getOrdenesComprasByProveedor',
          getOrdenesComprasByEstado: 'compra/ordenescompras/getOrdenesComprasByEstado',
          getOrdenesCompraAprobadas: 'compra/ordenescompras/getOrdenesCompraAprobadas',
          sendPdfToProveedor: 'compra/ordenescompras/sendPdfToProveedor',
          encuesta: {
            base: 'compra/ordencompraencuesta',
          },
          ordenCompraRequisitoAduanero: {
            base: 'compra/ordencomprarequisitoaduanero',
            getRequisitosNotInOrdenCompra: 'compra/ordencomprarequisitoaduanero/getRequisitosNotInOrdenCompra',
          },
          ordenCompraRequisitoImportacion: {
            base: 'compra/ordencomprarequisitoimportacion',
            getRequisitoImportacionNotInOrdenCompra: 'compra/'
              + 'ordencomprarequisitoimportacion/getrequisitosimportacionnotinordencompra',
          },
          ordenCompraProducto: {
            impuestos: {
              base: 'compra/ordencompraproductoimpuesto',
            },
            retenciones: {
              base: 'compra/ordencompraproductoretencion',
            },
          },
        },
        compraProductos: {
          base: 'compra/ordenescomprasproductos',
          baseTributo: 'compra/ordenescomprasproductos/tributo',
          excel: 'reports/ordencompraproducto/excel',
          initFormcomponent: 'compra/ordenescomprasproductos/initFormComponent',
          getProductoByOrdenCompraId: 'compra/ordenescomprasproductos/getProductosByOrdenCompraSir',
          getOrdenesComprasProductosNotInRecepcion: 'compra/'
            + 'ordenescomprasproductos/getOrdenesComprasProductosNotInRecepcion',
        },
        compraGastos: {
          base: 'compra/ordenescomprasgastos',
          getGastosNotInOrdenCompra: 'compra/ordenescomprasgastos/getGastosNotInOrdenCompra',
          baseTributo: 'compra/ordenescomprasgastos/tributo',
          initFormcomponent: 'compra/ordenescomprasgastos/initFormComponent',
        },
        compraTributos: {
          base: 'compra/ordenescomprastributos',
          initFormcomponent: 'compra/ordenescomprastributos/initFormComponent',
        },
        ordenCompraSir: {
          base: 'compra/ordencomprasirs',
          getSirByOrdenCompraProveedor: 'compra/ordencomprasirs/getSirsByOrdenCompraProveedor',
        },
        ordenCompraTipoPagoProveedor: {
          base: 'compra/ordenescomprastipospagosproveedores',
          autocomplete: 'compra/ordenescomprastipospagosproveedores/autocomplete',
          initForm: 'compra/ordenescomprastipospagosproveedores/initform',
        },
        ordenCompraServicio: {
          base: 'compra/ordenescomprasservicios',
          getServiciosNotInOrdenCompra: 'compra/ordenescomprasservicios/getServiciosNotInOrdenCompra',
        },
        sir: {
          base: 'compra/sirs',
          sendExcelToMail: 'compra/sirs/sendExcelToMail',
          initFormcomponent: 'compra/sirs/initFormComponent',
          cambioEstado: 'compra/sirs/cambioestado',
          report: 'reports/sirs/excel',
          getPorcentajeSir: 'compra/sirproductos/getPorcentajeSir',
          createSirFromContenedor: 'compra/sirs/createsirfromcontenedor',
        },
        sirsProductos: {
          base: 'compra/sirproductos',
          cargueMasivo: 'compra/sirproductos/upload',
          notInOrdenCompra: 'compra/sirproductos/productosNotInSir',
          excel: 'reports/sirs/sirProductosExcel',
          plantillaCargueMasivo: 'reports/plantillaSirProductosUpload/excel',
        },
        sirsServicios: {
          base: 'compra/sirsservicios',
          getServiciosNotInSir: 'compra/sirsservicios/getServiciosNotInSir',
        },
        sirImagenes: {
          base: 'compra/sirimagenes',
        },
        gastoImportacion: {
          base: 'compra/gastosimportacion',
          initForm: 'compra/gastosimportacion/initform',
          cambioEstado: 'compra/gastosimportacion/cambioestado',
          report: 'reports/gastosimportacion/excel',
        },
        gastoImportacionGastos: {
          base: 'compra/gastosimportaciongastos',
          baseTributo: 'compra/gastosimportaciongastos/tributo',
          initFormComponent: 'compra/gastosimportaciongastos/initform',
        },
        gastoImportacionEmbarque: {
          base: 'compra/gastosimportacionembarque',
          initForm: 'compra/gastosimportacionembarque/initform',
          comprasByEmbarque: 'compra/gastosimportacionembarque/comprasByEmbarque',
          productosByOrdenCompra: 'compra/gastosimportacionembarque/productosByOrdenCompra',
        },
        gastoImportacionTributos: {
          base: 'compra/gastosimportacionconceptotributario',
          initForm: 'compra/gastosimportacionconceptotributario/initform',
        },
        cargueMasivo: {
          base: 'compra/proveedores/upload',
        },
        ordenCompraImagenes: {
          base: 'compra/ordencompraimagenes',
        },
      },
    },
    inventario: {
      almacenUbicaciones: {
        autocomplete: 'inventario/almacenesubicaciones/autocomplete',
        byAlmacenId: 'inventario/almacenesubicaciones/findbyalmacenid',
        ubicacionByAlmacen: 'inventario/almacenesubicaciones/findUbicacionByAlmacen',
        base: 'inventario/almacenesubicaciones',
        getContenedoresByUbicacion: 'inventario/almacenesubicaciones/getcontenedoresbyubicacion',
        autocompleteAlmacenesEmpleados: 'inventario/almacenes/autocompletealmacenesempleado',
      },
      bodegaAlmacenes: {
        base: 'inventario/bodegasalmacenes',
        almacenesByBodega: 'inventario/bodegasalmacenes/almacenesbybodega',
      },
      generales: {
        almacen: {
          base: 'inventario/almacenes',
          autocomplete: 'inventario/almacenes/autocomplete',
          indexbodega: 'inventario/almacenes/indexbodega',
          sendExcelToMail: 'inventario/almacenes/sendExcelToMail',
          almacenesExcel: 'reports/inventarios/almacenesExcel',
        },
        almacenZonas: {
          base: 'inventario/almaceneszonas',
        },
        almacenEmpleados: {
          base: 'inventario/almacenesempleados',
          notInAlmacen: 'inventario/almacenesempleados/empleadosnotinalmacen',
        },
        almacenUbicaciones: {
          byAlmacenId: 'inventario/almacenesubicaciones/findbyalmacenid',
          ubicacionByAlmacen: 'inventario/almacenesubicaciones/findUbicacionByAlmacen',
          base: 'inventario/almacenesubicaciones',
        },
        bloqueAlmacenes: {
          base: 'inventario/bloquesalmacenes',
          notInBloque: 'inventario/bloquesalmacenes/almacenesnotinbloque',
          findByAlmacen: 'inventario/bloquesalmacenes/findbyalmacen',
        },
        bloque: {
          base: 'inventario/bloques',
        },
        bloqueEmpleados: {
          base: 'inventario/bloquesempleados',
          notInBloque: 'inventario/bloquesempleados/empleadosnotinbloque',
        },
        bodega: {
          base: 'inventario/bodegas',
          getBodegasForForm: 'inventario/bodegas/getBodegasForForm',
          initFormComponent: 'inventario/bodegas/initFormComponent',
          autocomplete: 'inventario/bodegas/autocomplete',
          sendExcelToMail: 'inventario/bodegas/sendExcelToMail',
          autocompleteBodegaWithCentroDistribucion: 'inventario/bodegas/autocompletebodegawithcentrodistribucion',
          bodegasExcel: 'reports/inventarios/bodegasExcel',
        },
        bodegaAlmacenes: {
          base: 'inventario/bodegasalmacenes',
          almacenesByBodega: 'inventario/bodegasalmacenes/almacenesbybodega',
        },
        codigoEan: {
          autocomplete: 'inventario/codigosean/autocomplete',
          autocompleteByTipoCodigoBarras: 'inventario/codigosean/autocompletebytipocodigobarras',
          base: 'inventario/codigosean',
          codigosEanExcel: 'reports/inventarios/codigosEanExcel',
        },
        centrosDistribucion: {
          autocomplete: 'inventario/centrosdistribucion/autocomplete',
          base: 'inventario/centrosdistribucion',
          initFormComponent: 'inventario/centrosdistribucion/initformcomponent',
          sendExcelToMail: 'inventario/centrosdistribucion/sendExcelToMail',
          centrosDistribucionesExcel: 'reports/inventarios/centrosDistribucionesExcel',
          centroDistribucionCiudad: {
            base: 'inventario/centrodistribucionciudades',
            getCiudadesNotInCentroDistribucion: 'inventario/'
              + 'centrodistribucionciudades/getCiudadesNotInCentroDistribucion',
          },
        },
        clasificacionesInventario: {
          base: 'inventario/clasificacionesinventario',
        },
        clasificacion1: {
          base: 'inventario/clasificaciones1',
          autocomplete: 'inventario/clasificaciones1/autocomplete',
          indexActivos: 'inventario/clasificaciones1/indexActivos',
          clasificacionExcel: 'reports/clasificacion1/excel',
        },
        clasificacion2: {
          base: 'inventario/clasificaciones2',
          autocomplete: 'inventario/clasificaciones2/autocomplete',
          indexActivos: 'inventario/clasificaciones2/indexActivos',
          getClasificaciones2Byclasificacion1Id: 'inventario/clasificaciones2/getClasificaciones2Byclasificacion1Id',
          clasificacionExcel: 'reports/clasificacion2/excel',
        },
        clasificacion3: {
          base: 'inventario/clasificaciones3',
          autocomplete: 'inventario/clasificaciones3/autocomplete',
          initFormComponent: 'inventario/clasificaciones3/initFormComponent',
          getClasificaciones3ByClasificaciones2: 'inventario/clasificaciones3/getClasificaciones3ByClasificaciones2',
          clasificacionExcel: 'reports/clasificacion3/excel',
        },
        existencias: {
          getInfo: 'venta/pedidos/viewInfoNotDespachable',
          generateTrasladoToContenedor: 'inventario/existencias/generateTrasladoToContenedor',
          generateTrasladoFromContenedor: 'inventario/existencias/generateTrasladoFromContenedor',
          getExistenciasCloseToExpire: 'inventario/existencias/getExistenciasCloseToExpire',
          getExistenciasByContenedorAndProducto: 'inventario/existencias/getexistenciasbycontenedorandproducto',
          reports: 'reports/productosavencer/excel',
        },
        informes: {
          bodegasContenedores: 'wms/informebodegas',
          tomaAjusteInventario: {
            historicoAjuste: {
              base: 'inventario/historicoajusteinventario',
              reports: 'reports/historicoajusteinventario/excel',
            },
          },
        },
        perfilLogistico: {
          base: 'inventario/perfileslogisticos',
          initFormComponent: 'inventario/perfileslogisticos/initformcomponent',
          perfilesLogisticosExcel: 'reports/inventarios/perfilesLogisticosExcel',
        },
        perfilLogisticoDetalle: {
          base: 'inventario/perfileslogisticosdetalles',
          findByProductoPerfiles: 'inventario/perfileslogisticosdetalles/findbyproductoperfiles',
        },
        procesos: {
          tomaInventario: {
            base: 'inventario/tomasinventarios',
            cambioEstado: 'inventario/tomasinventarios/cambioEstado',
            cambioEstadoAjuste: 'inventario/tomasinventarios/cambioEstadoAjuste',
            cambioEstadoEjecucion: 'inventario/tomasinventarios/cambioEstadoEjecucion',
            initFormComponent: 'inventario/tomasinventarios/initFormComponent',
            sendExcelToMailEjecucion: 'inventario/tomasinventarios/sendExcelToMailEjecucion',
            report: 'reports/tomainventarios/exceltomaInventario',
            detalle: {
              base: 'inventario/tomasinventariosdetalle',
              tomaParcialExistencia: 'inventario/tomasinventariosdetalle/getExistenciaForTomaParcial',
              updateFieldRealizado: 'inventario/tomasinventariosdetalle/updateFieldRealizado',
              cambioEstado: 'inventario/tomasinventariosdetalle/cambioEstado',
              updateAtributos: 'inventario/tomasinventariosdetalle/updateAtributos',
            },
            getTomasByUser: {
              base: 'inventario/tomasinventarios/gettomasinventariobyuser',
              report: 'reports/tomainventarios/excelEjecucionTomaInventario',
            },
            makeNewTomaWithItem: 'inventario/tomasinventarios/makeNewTomaInventarioWithItem',
          },
        },
        tipoInventario: {
          base: 'inventario/tiposinventarios',
          autocomplete: 'inventario/tiposinventarios/autocomplete',
          sendExcelToMail: 'inventario/tiposinventarios/sendExcelToMail',
          tiposInventariosExcel: 'reports/inventarios/tiposInventariosExcel',
        },
        unidadMedida: {
          base: 'inventario/unidadesmedidas',
          autocomplete: 'inventario/unidadesmedidas/autocomplete',
          sendExcelToMail: 'inventario/unidadesmedidas/sendExcelToMail',
          unidadesMedidasExcel: 'reports/inventarios/unidadesMedidasExcel',
        },
      },
      inventario: {
        base: 'inventario/inventarios',
        initformcomponent: 'inventario/inventarios/initcrudcomponent',
        filtrosForTrazabilidad: 'inventario/inventarios/filtrosForTrazabilidad',
        getEntradaExistenciasByProducto: 'inventario/inventarios/getEntradaExistenciasByProducto',
        getTrazabilidadByExistencia: 'inventario/inventarios/getTrazabilidadByExistencia',
        getCodigosOperacion: 'inventario/inventarios/getCodigosOperacion',
        list: 'inventario/inventarios',
        new: 'inventario/inventarios/create',
        getId: 'inventario/inventarios/id',
        update: 'inventario/inventarios/update',
        delete: 'inventario/inventarios/delete',
        report: 'reports/entradassalidas/excel',
        cambioEstado: 'inventario/inventarios/cambioEstado',
      },
      inventarioGeneral: {
        consultas: {
          existencia: {
            base: 'inventario/existencias',
            existenciasForAbreviatura: 'inventario/existencias/existenciasforabreviatura',
            excel: 'reports/existencias/excel',
            initFormcomponent: 'inventario/existencias/initcrudcomponent',
          },
          activoFijo: {
            base: 'inventario/productos/getProductosActivoFijo',
            sendExcelToMail: 'inventario/productos/sendExcelToMailActivoFijo',
            report: 'reports/activosFijos/excel',
          },
        },
        maestros: {
          consecutivoInventarioGeneral: {
            base: 'inventario/consecutivos',
            consecutivosIGExcel: 'reports/consecutivos/excel',
          },
          tipoMantenimiento: {
            base: 'inventario/tiposmantenimientos',
            autocomplete: 'inventario/tiposmantenimientos/autocomplete',
            sendExcelToMail: 'inventario/tiposmantenimientos/sendExcelToMail',
            tiposMantenimientosExcel: 'reports/inventarios/tiposMantenimientosExcel',
          },
        },
        procesos: {
          mantenimiento: {
            base: 'inventario/mantenimientos',
            sendExcelToMail: 'inventario/mantenimientos/sendExcelToMail',
            autocomplete: 'inventario/mantenimientos/autocomplete',
            initFormComponent: 'inventario/mantenimientos/initFormComponent',
            cambioEstado: 'inventario/mantenimientos/cambioestado',
            report: 'reports/mantenimientos/excel',
            historicoMantenimientos: 'inventario/mantenimientohistoricoasignacion',
            comentarios: 'inventario/mantenimientocomentario',
          },
          mantenimientoExistencia: {
            base: 'inventario/mantenimientosexistencias',
            cambioEstado: 'inventario/mantenimientosexistencias/cambioEstado',
          },
          traslados: {
            base: 'inventario/traslados',
            initformcomponent: 'inventario/traslados/initformcomponent',
            autocompleteNotInRecepcion: 'inventario/traslados/autocompleteNotInRecepcion',
            report: 'reports/traslados/excel',
            cambioEstado: 'inventario/traslados/cambioestado',
          },
          trasladosProductos: {
            base: 'inventario/trasladosproductos',
            initListComponent: 'inventario/trasladosproductos/initlistcomponent/',
            initFormComponent: 'inventario/trasladosproductos/initformcomponent/',
          },
        },
      },
      inventarioProductos: {
        base: 'inventario/inventariosproductos',
        excel: 'reports/entradassalidas/entradasSalidasProductosExcel',
        upload: 'inventario/inventariosproductos/upload',
        plantillaProductoE: 'reports/plantillaProductoEntradaUpload/excel',
        plantillaCargueMasivo: 'reports/plantillaListaPrecioUpload/excel',
        productoEntradaSalidaExcel: 'reports/inventarios/productoentradasalidaexcel',
        initListComponent: 'inventario/inventariosproductos/initlistcomponent',
        initFormComponent: 'inventario/inventariosproductos/initformcomponent',
        list: 'inventario/inventariosproductos',
        getId: 'inventario/inventariosproductos/id',
        update: 'inventario/inventariosproductos',
        existencias: 'inventario/inventariosproductos/salida',
        getExistenciasByInventarioProducto: 'inventario/inventariosproductos/getExistenciasByInventarioProducto',
        existenciasSalidas: 'inventario/productos/getProductosWithExistencia',
        getProductosWithExistenciaForContenedor: 'inventario/productos/getProductosWithExistenciaForContenedor',
      },
      materiaPrima: {
        consultas: {
          existencia: {
            base: 'inventario/existencias',
            excel: 'reports/existencias/excel',
            initFormcomponent: 'inventario/existencias/initcrudcomponent',
          },
        },
        maestros: {
          consecutivoMateriaPrima: {
            base: 'inventario/consecutivos',
          },
        },
        procesos: {
          traslados: {
            base: 'inventario/traslados',
            initformcomponent: 'inventario/traslados/initformcomponent',
          },
          trasladosProductos: {
            base: 'inventario/trasladosproductos',
            initListComponent: 'inventario/trasladosproductos/initlistcomponent/',
            initFormComponent: 'inventario/trasladosproductos/initformcomponent/',
          },
        },
      },
      productoTerminado: {
        consultas: {
          existencia: {
            base: 'inventario/existencias',
            sendExcelToMailReporteUso: 'inventario/existencias/sendExcelToMail',
            sendExcelToMailMercanciaTransito: 'inventario/existencias/sendExcelToMailMercanciaTransito',
            historicoCostos: 'inventario/historicocostosrecepcion',
            sendExcelToMail: 'inventario/historicocostosrecepcion/sendExcelToMail',
            historicoCostosExcel: 'reports/inventarios/inventarioValorizable',
            getUsosExistencias: 'inventario/existencias/getUsosExistencias',
            report: 'reports/reportesuso/excel',
            reservas: 'inventario/existencias/indexReservas',
            mercanciaEnTransito: 'inventario/existencias/indexMercanciaEnTransito',
            excel: 'reports/existencias/excel',
            reservasExcel: 'reports/existencias/reservasExcel',
            mercanciaTransito: 'reports/existencias/mercanciaTransito',
            initFormcomponent: 'inventario/existencias/initcrudcomponent',
          },
          semaforo: {
            base: 'inventario/semaforo',
            initFormComponent: 'inventario/semaforo/initcrudcomponent',
            excel: 'reports/semaforo/excel',
          },
          kardex: {
            base: 'inventario/kardex',
            excel: 'reports/kardex/excel',
          },
          trazabilidad: {
            base: 'inventario/productostrazabilidad',
            autoComplete: 'inventario/productostrazabilidad/autocomplete',
            filtros: 'inventario/productostrazabilidad/filtros',
          },
        },
        maestros: {
          agrupacion: {
            base: 'inventario/agrupaciones',
            initformcomponent: 'inventario/agrupaciones/initformcomponent',
          },
          categoria: {
            base: 'inventario/categorias',
            categoriasExcel: 'reports/inventarios/categoriasExcel',
          },
          categoriaComposicion: {
            base: 'inventario/categoriascomposiciones',
            autocomplete: 'inventario/categoriascomposiciones/autocomplete',
            sendExcelToMail: 'inventario/categoriascomposiciones/sendExcelToMail',
            getActivas: 'inventario/categoriascomposiciones/getCategoriasActivas',
            categoriasComposicionesExcel: 'reports/inventarios/categoriasComposicionesExcel',
          },
          consecutivoProductoTerminado: {
            base: 'inventario/consecutivos',
            consecutivosPTExcel: 'reports/consecutivos/excel',
          },
          documentoInventario: {
            base: 'inventario/documentosinventarios',
            autocomplete: 'inventario/documentosinventarios/autocomplete',
            initFormComponent: 'inventario/documentosinventarios/initformcomponent',
            documentosInventarioExcel: 'reports/inventarios/documentosInventarioExcel',
          },
          agrupacionImagenes: {
            base: 'inventario/agrupacionesimagenes',
            updatePrincipal: 'inventario/agrupacionesimagenes/updatePrincipal',
          },
          agrupacionComposiciones: {
            base: 'inventario/agrupacionescomposiciones',
            notInAgrupacion: 'inventario/agrupacionescomposiciones/productosnotinagrupacion',
          },
          agrupacionesperfileslogisticosdetalles: {
            base: 'inventario/agrupacionesperfileslogisticosdetalles',
            list: 'inventario/agrupacionesperfileslogisticosdetalles/byagrupaciongroupbyperfil',
            updateEstadosPerfiles: 'inventario/agrupacionesperfileslogisticosdetalles/updateestadosperfiles',
          },
          tipoProducto: {
            base: 'inventario/tiposproductos',
            autocomplete: 'inventario/tiposproductos/autocomplete',
            listAbreviatura: 'inventario/tiposproductos/selectAbreviaturas',
            tiposProductosExcel: 'reports/inventarios/tiposProductosExcel',
          },
        },
        procesos: {
          traslados: {
            base: 'inventario/traslados',
            initformcomponent: 'inventario/traslados/initformcomponent',
            report: 'reports/traslados/excel',
            solicitudTraslado: {
              base: 'inventario/solicitudestraslados',
              sendExcelToMail: 'inventario/solicitudestraslados/sendExcelToMail',
              cambioEstado: 'inventario/solicitudestraslados/cambioEstado',
              updateReposicion: 'inventario/solicitudestraslados/updateReposicion',
              excel: 'reports/solicitudtraslado/excel',
              initformcomponent: 'inventario/solicitudestraslados/initFormComponent',
              pdf: 'impresion/pdf/print/solicitudTraslado',
              productos: {
                base: 'inventario/solicitudestrasladosproductos',
                updateCantidad: 'inventario/solicitudestrasladosproductos/updateCantidad',
                getExistenciasNotInSolicitudTraslado: 'inventario/'
                  + 'solicitudestrasladosproductos/getExistenciasNotInSolicitudTraslado',
              },
              contenedores: {
                base: 'inventario/solicitudestrasladoscontenedores',
                getContenedoresNotInSolicitud: 'inventario/'
                  + 'solicitudestrasladoscontenedores/getContenedoresNotInSolicitud',
              },
            },
          },
          trasladosProductos: {
            base: 'inventario/trasladosproductos',
            initListComponent: 'inventario/trasladosproductos/initlistcomponent/',
            initFormComponent: 'inventario/trasladosproductos/initformcomponent/',
          },
        },
      },
      unidadMedida: {
        base: 'inventario/unidadesmedidas',
        autocomplete: 'inventario/unidadesmedidas/autocomplete',
      },
    },
    wms: {
      maestros: {
        clasificacionTransportador: {
          base: 'wms/clasificacionestransportadores',
          autocomplete: 'wms/clasificacionestransportadores/autocomplete',
          sendExcelToMail: 'wms/clasificacionestransportadores/sendExcelToMail',
          clasificacionesTransportadoresExcel: 'reports/wms/clasificacionesTransportadoresExcel',
        },
        consecutivosWms: {
          base: 'wms/consecutivos',
          consecutivosWmsExcel: 'reports/consecutivos/excel',
        },
        contenedores: {
          base: 'wms/contenedores',
          initFormComponent: 'wms/contenedores/initFormComponent',
          autocompleteCodigoEan: 'wms/contenedores/autocompletecodigoean',
          autocompleteByCodigo: 'wms/contenedores/autocompletebycodigo',
          autocompleteUbicacion: 'wms/contenedores/autocompleteubicacion',
          sendExcelToMail: 'wms/contenedores/sendExcelToMail',
          contenedoresExcel: 'reports/wms/contenedoresExcel',
          getByEmpleadoIdAndProductoId: 'wms/contenedores/getContenedoresByEmpleadoAndProducto',
          historicoUsos: 'wms/contenedores/historicoUsos',
          getExistenciasWithComposicionInfo: 'wms/contenedores/getExistenciasWithComposicionInfo',
          compararCompuesto: 'wms/contenedores/compararCompuesto',
          getExistenciasWithComposicionInfoNotInReporteGasto: 'wms/'
            + 'contenedores/getExistenciasWithComposicionInfoNotInReporteGasto',
          contenedoresComposicionesExcel: 'reports/wms/contenedoresComposicionesExcel',
          contenedoresByAlmacenInfo: 'wms/contenedores/contenedoresByAlmacenInfo',
          getContenedoresByUbicacionId: 'wms/contenedores/getContenedoresByUbicacionId',
          getContenedoresByUbicacionIdAndProducto: 'wms/contenedores/getContenedoresByUbicacionIdAndProducto',
          getContenedoresByUbicacionIdExcludeOwn: 'wms/contenedores/getContenedoresByUbicacionIdExcludeOwn',
          getContenedoresByBodegaId: 'wms/contenedores/getContenedoresByBodegaId',
          getContenedoresByBodegaIdForReporteGasto: 'wms/contenedores/getContenedoresByBodegaIdForReporteGasto',
          duplicateContenedor: 'wms/contenedores/duplicateContenedor',
          generateTrasladoContenedor: 'wms/contenedores/generateTrasladoContenedor',
          historicoTrasladosContenedores: 'wms/historicotrasladoscontenedor',
          pdf: 'impresion/pdf/print/compoptimaeq',
        },
        contenedorImagenes: {
          base: 'wms/contenedoresimagenes',
        },
        documentosWms: {
          base: 'wms/documentoswms',
          autocomplete: 'wms/documentoswms/autocomplete',
          initFormComponent: 'wms/documentoswms/initformcomponent',
          documentosWmsExcel: 'reports/wms/documentosWmsExcel',
        },
        puertos: {
          base: 'wms/puertos',
          getPuertoExcludeSelected: 'wms/puertos/getPuertosExcludeSelect',
          puertosExcel: 'reports/wms/puertosExcel',
        },
        tiposCodigosBarras: {
          base: 'wms/tiposcodigosbarras',
          tiposCodigosBarrasExcel: 'reports/wms/tiposCodigosBarrasExcel',
        },
        tiposContenedores: {
          base: 'wms/tiposcontenedores',
          autocomplete: 'wms/tiposcontenedores/autocomplete',
          sendExcelToMail: 'wms/tiposcontenedores/sendExcelToMail',
          tiposContenedoresExcel: 'reports/wms/tiposContenedoresExcel',
        },
        tipoTransportes: {
          base: 'wms/tipostransportes',
          autocomplete: 'wms/tipostransportes/autocomplete',
          sendExcelToMail: 'wms/tipostransportes/sendExcelToMail',
          tiposTransportesExcel: 'reports/wms/tiposTransportesExcel',
          getTiposTransportesNotInTransportador: 'wms/tipostransportes/gettipostransportesnotintransportador',
        },
        transportador: {
          base: 'wms/transportadores',
          initCrudComponent: 'wms/transportadores/initCrudComponent',
          autocomplete: 'wms/transportadores/autocomplete',
          sendExcelToMail: 'wms/transportadores/sendExcelToMail',
          tercerosTransportadoresExcel: 'reports/wms/tercerosTransportadoresExcel',
          tipoTransporte: {
            base: 'wms/transportadortipotransporte',
          },
        },
        zonas: {
          base: 'wms/zonas',
          autocomplete: 'wms/zonas/autocomplete',
          sendExcelToMail: 'wms/zonas/sendExcelToMail',
          getZonasNotInAlmacen: 'wms/zonas/getZonasNotInAlmacen',
          zonasExcel: 'reports/wms/zonasExcel',
        },
      },
      procesos: {
        alistamiento: {
          base: 'wms/alistamiento',
          initFormComponent: 'wms/alistamiento/initformcomponent',
          cambioEstado: 'wms/alistamiento/cambioestado',
          pdf: 'reports/alistamiento',
          excel: 'reports/alistamiento/excel',
        },
        despacho: {
          base: 'wms/despachos',
          autocompleteByIdProceso: 'wms/despachos/autocompletebyidproceso',
          sendExcelToMail: 'wms/despachos/sendExcelToMail',
          getDespachosByCliente: 'wms/despachos/getDespachosByCliente',
          initFormComponent: 'wms/despachos/initformcomponent',
          initFormComponentDespachoFromRecoleccion: 'wms/despachos/initFormComponentDespachoFromRecoleccion',
          cambioEstado: 'wms/despachos/cambioestado',
          pdfCirugia: 'impresion/pdf/print/remisiont',
          pdfVentaDirecta: 'impresion/pdf/print/remisionvd',
          pdfSolicitudLogistica: 'impresion/pdf/print/remisionsl',
          excel: 'reports/despacho/excel',
          excelSizfra: 'reports/despacho/excelSizfra',
          wizardDespacho: 'rpdental/wmsMasterWizard',
          getProductosPedidoByDespacho: 'wms/despachos/getproductosByPedido',
          report: 'reports/despachos/excel',
        },
        hojaRuta: {
          base: 'wms/hojaderuta',
          sendExcelToMail: 'wms/hojaderuta/sendExcelToMail',
          autocompleteByIdProceso: 'wms/hojaderuta/autocompletebyidproceso',
          getDespachoByEmpleadoNotHojaRuta: 'wms/hojaderuta/getDespachosByEmpleadoNotHojaRuta',
          getDespachoByEmpleadoHojaRuta: 'wms/hojaderuta/getDespachosByEmpleadoHojaRuta',
          cambioEstado: 'wms/hojaderuta/cambioestado',
          report: 'reports/hojasrutas/excel',
          pdf: 'impresion/pdf/print/hojaderuta',
          despachos: {
            base: 'wms/hojasrutadespachos',
            getDespachosNotInHojaRuta: 'wms/hojasrutadespachos/getDespachosByEmpleadoNotHojaRuta',
          },
          solicitudLogistica: {
            base: 'wms/hojasrutassolicitudeslogisticas',
            getSolicitudesNotInHojasRuta: 'wms/hojasrutassolicitudeslogisticas/getSolicitudesNotInHojasRuta',
          },
          prioridades: {
            base: 'wms/hojasrutasprioridades',
            cambioEstado: 'wms/hojasrutasprioridades/cambioEstado',
          },
        },
        despachoEmpleados: {
          base: 'wms/despachosempleados',
          notInDespacho: 'wms/despachosempleados/empleadosnotindespacho',
        },
        despachoGastos: {
          base: 'wms/despachosgastos',
          initFormComponent: 'wms/despachosgastos/initformcomponent',
        },
        despachoImagenes: {
          base: 'wms/despachosimagenes',
        },
        despachoEncuestas: {
          base: 'wms/despachosencuestas',
        },
        despachoPedidos: {
          base: 'wms/despachospedidos',
          notInDespacho: 'wms/despachospedidos/pedidosnotindespacho',
        },
        despachoPickingAlistamiento: {
          base: 'wms/despachospickingalistamiento',
          notInDespacho: 'wms/despachospickingalistamiento/despachopickingnotindespacho',
        },
        despachoVehiculos: {
          base: 'wms/despachosvehiculos',
          initFormCompornent: 'wms/despachosvehiculos/initformcomponent',
          notInVehiculo: 'wms/despachosvehiculos/contenedornotinvehiculo',
          contenedor: 'wms/despachosvehiculos/contenedor',
        },
        despachoTransportador: {
          base: 'wms/despachostransportadores',
          getGuiaByTransportadorId: 'wms/despachostransportadores/getGuiasByTransportadorId',
          getGuias: 'wms/despachostransportadores/getGuias',
          cambioEstado: 'wms/despachostransportadores/cambioestado',
        },
        embarque: {
          base: 'wms/embarques',
          initFormComponent: 'wms/embarques/initformcomponent',
          cambioEstado: 'wms/embarques/cambioestado',
          autocomplete: 'wms/embarques/autocomplete',
          report: 'reports/embarques/excel',
        },
        embarqueContenedor: {
          base: 'wms/embarquescontenedores',
          initFormComponent: 'wms/embarquescontenedores/initformcomponent',
        },
        embarqueDocumento: {
          base: 'wms/embarquesdocumentos',
          initFormComponent: 'wms/embarquesdocumentos/initformcomponent',
          documentoCompra: 'wms/embarquesdocumentos/documentoscompras',
          documentoOrdenCompraByEmbarque: 'wms/embarquesdocumentos/documentosordenescomprasbyembarque',
        },
        listaEmpaque: {
          base: 'wms/listasempaques',
          initFormComponent: 'wms/listasempaques/initformcomponent',
          cambioEstado: 'wms/listasempaques/cambioestado',
          createNewListEmbarque: 'wms/listasempaques/storeFromEmbarque',
          report: 'reports/listasempaques/excel',
        },
        listaEmpaqueProductos: {
          base: 'wms/listasempaquesproductos',
          getProductos: 'wms/listasempaquesproductos/productosbyembarque',
          getContenedores: 'wms/listasempaquesproductos/productosbyalmacenamiento',
          initFormComponent: 'wms/listasempaquesproductos/initformcomponent',
          perfilAlmacenamientoByRecepcion: 'wms/listasempaquesproductos/perfilalmacenamientobyrecepcion',
        },
        packing: {
          base: 'wms/packing',
          initFormComponent: 'wms/packing/initformcomponent',
          cambioEstado: 'wms/packing/cambioestado',
        },
        programacionPickingExistenciasContenedores: {
          base: 'wms/programacionpickingexistenciascontenedores',
        },
        programacionPickingExistencias: {
          base: 'wms/programacionpickingexistencias',
          sendExcelToMail: 'wms/programacionpickingexistencias/sendExcelToMail',
          storeFromSolicitudTraslado: 'wms/programacionpickingexistencias/storeFromSolicitudTraslado',
          cambioEstado: 'wms/programacionpickingexistencias/cambioestado',
          report: 'reports/programacionpickingexistencias/excel',
        },
        recoleccion: {
          base: 'wms/recolecciones',
          initFormComponent: 'wms/recolecciones/initFormComponent',
          cambioEstado: 'wms/recolecciones/cambioestado',
          sendExcelToMail: 'wms/recolecciones/sendExcelToMail',
          report: 'reports/recolecciones/excel',
          getInfoForImprimible: 'wms/recolecciones/getInfoForImprimible',
        },
        recoleccionProductos: {
          base: 'wms/recoleccionesproductos',
          getPorcentaje: 'wms/recoleccionesproductos/getporcentaje',
        },
        recoleccionContenedores: {
          base: 'wms/recoleccionescontenedores',
        },
        programacionPickingExistenciasProductos: {
          base: 'wms/programacionpickingexistenciasproductos',
          anularReserva: 'wms/programacionpickingexistenciasproductos/anularReserva',
          getExistenciaByProductoAndProgramacionId: 'wms/'
            + 'programacionpickingexistenciasproductos/getExistenciasByProductoAndProgramacionId',
        },
        picking: {
          base: 'wms/picking',
          initFormComponent: 'wms/picking/initformcomponent',
          cambioEstado: 'wms/picking/cambioestado',
          byProgramacion: 'wms/picking/byprogramacion',
        },
        preinspeccion: {
          base: 'compra/preinspecciones',
          initForm: 'compra/preinspecciones/initForm',
          cambioEstado: 'compra/preinspecciones/cambioestado',
          getOrdenCompra: 'compra/preinspecciones/getordencompra',
          getProductos: 'compra/preinspecciones/getproductos',
          report: 'reports/preinspecciones/excel',
        },
        preinspeccionProductos: {
          base: 'compra/preinspeccionesproductos',
          initForm: 'compra/preinspeccionesproductos/initform',
          imagenes: 'compra/preinspeccionesproductos/imagenes',
          getAcuerdoByPais: 'compra/preinspeccionesproductos/getacuerdobypais',
          atributosControl: 'compra/preinspeccionesproductos/atributoscontrol',
        },
        preinspeccionGastos: {
          base: 'compra/preinspecciones/gastos',
        },
        preinspeccionEncuestas: {
          base: 'compra/preinspeccionesencuestas',
        },
        programacionPicking: {
          base: 'wms/programacionpicking',
          cambioEstado: 'wms/programacionpicking/cambioestado',
        },
        programacionPickingDetalles: {
          base: 'wms/programacionpickingdetalles',
          pedidosComponent: 'wms/programacionpickingdetalles/initpedidoscomponent',
          availablePedidos: 'wms/programacionpickingdetalles/availablepedidos',
        },
        feedbackProgramacionPickig: {
          base: 'wms/feedbackprogramacionpicking',
          reprogramar: 'wms/feedbackprogramacionpicking/reprogramar',
        },
        pickingRutas: {
          etiquetaContenedorMasivo: 'reports/contenedoretiquetamasivo',
        },
        recepcion: {
          base: 'wms/recepciones',
          sendExcelToMail: 'wms/recepciones/sendExcelToMail',
          initFormComponent: 'wms/recepciones/initformcomponent',
          cambioEstado: 'wms/recepciones/cambioestado',
          pdf: 'reports/recepcion',
          pdfRecepcion: 'impresion/pdf/print/recepcionOC',
          getCodigosOperacion: 'wms/recepciones/getCodigosOperacion',
          excel: 'reports/recepcion/excel',
          report: 'reports/recepciones/excel',
          recepcionOrdenCompra: {
            base: 'wms/recepcionesordenescompras',
          },
          recepcionTraslado: {
            base: 'wms/recepcionestraslados',
            getTrasladoNotInRecepcion: 'inventario/traslados/getTrasladosNotInRecepcion',
          },
          recepcionInventario: {
            base: 'wms/recepcionesinventarios',
          },
          recepcionProductoOC: {
            base: 'wms/recepcionesproductosoc',
            excel: 'reports/recepcion/recepcionProductoOCExcel',
            cargueMasivo: 'wms/recepcionesproductosoc/upload',
            updateActualizaCosto: 'wms/recepcionesproductosoc/updateFieldActualizaCosto',
            updateFieldAfectaCosto: 'wms/recepcionesproductosoc/updateFieldAfectaCosto',
            plantilla: 'reports/recepciones/excelPlantilla',
            impuestos: {
              base: 'wms/recepcionproductosocimpuestos',
            },
          },
          recepcionProductoTraslado: {
            base: 'wms/recepcionesproductostraslados',
            getTrasladoProductoNotInRecepcion: 'inventario/trasladosproductos/getTrasladosProductosNotInRecepcion',
          },
        },
        recepcionProductos: {
          base: 'wms/recepcionesproductos',
          updateCantidadOrValor: 'wms/recepcionesproductos/updateCantidadOrValor',
          updateActualizaCosto: 'wms/recepcionesproductos/updateFieldActualizaCosto',
          updateFieldAfectaCosto: 'wms/recepcionesproductos/updateFieldAfectaCosto',
          getProductosForRecepcion: 'wms/recepcionesproductos/getProductosForRecepcion',
        },
        recepcionNovedades: {
          base: 'wms/recepcionesnovedades',
        },
        recepcionEmpleados: {
          base: 'wms/recepcionesempleados',
          notInRecepcion: 'wms/recepcionesempleados/empleadosnotinrecepcion',
        },
        recepcionEncuestas: {
          base: 'wms/recepcionesencuestas',
        },
        recepcionImagenes: {
          base: 'wms/recepcionesimagenes',
        },
        recepcionCierreContenedor: {
          base: 'wms/recepcioncierrecontenedor',
        },
        recepcionResumen: {
          base: 'wms/recepcionresumen',
        },
        recepcionListaEmpaqueEan: {
          base: 'wms/recepcioneslistasempaquescodigosean',
          initForm: 'wms/recepcioneslistasempaquescodigosean/initformcomponent',
          autocomplete: 'wms/recepcioneslistasempaquescodigosean/autocomplete',
          contenedorAlmacenamiento: 'wms/recepcioneslistasempaquescodigosean/contenedorAlmacenamiento',
          createContenedorAlmacenamiento: 'wms/recepcioneslistasempaquescodigosean/createcontenedoralmacenamiento',
          getDispositivosImpresion: 'wms/recepcioneslistasempaquescodigosean/getdispositivosimpresion',
          etiquetaRecepcion: 'reports/etiquetalistaempaque',
          getDataPrintMasivo: 'wms/recepcioneslistasempaquescodigosean/getdataprintmasivo',
          etiquetaRecepcionMasivo: 'reports/etiquetarecepcionmasivo',
          productosPendientesRecibo: 'wms/recepcioneslistasempaquescodigosean/productospendientesrecibo',
          autocompleteProductosPendientes: 'wms/recepcioneslistasempaquescodigosean/autocompleteproductospendientes',
        },
        solicitudesLogisticas: {
          base: 'wms/solicitudeslogisticas',
          sendExcelToMail: 'wms/solicitudeslogisticas/sendExcelToMail',
          initFormComponent: 'wms/solicitudeslogisticas/initFormComponent',
          cambioEstado: 'wms/solicitudeslogisticas/cambioEstado',
          report: 'reports/solicitudeslogicticas/excel',
          contenedores: {
            base: 'wms/solicitudeslogisticascontenedores',
            contenedoresNotSolicitud: 'wms/solicitudeslogisticascontenedores/getcontenedoresnotinsolicitud',
            createSolicitudWithContenedor: 'wms/'
              + 'solicitudeslogisticascontenedores/createsolicitudlogisticawithcontenedor',
          },
          existencias: {
            base: 'wms/solicitudeslogisticasexistencias',
            getExistenciasByProductoAndSolicitudLogisticaId: 'wms/'
              + 'solicitudeslogisticasexistencias/getExistenciasByProductoAndSolicitudLogisticaId',
          },
          otros: {
            base: 'wms/solicitudeslogisticasdetalles',
          },
          cambios: {
            base: 'wms/solicitudeslogisticascambios',
            getContenedoresForCambio: 'wms/solicitudeslogisticascambios/getContenedoresForCambio',
            getExistenciasForCambio: 'wms/solicitudeslogisticascambios/getExistenciasForCambio',
            contenedores: 'wms/solicitudeslogisticascambios/contenedores',
            existencias: 'wms/solicitudeslogisticascambios/existencias',
            otros: 'wms/solicitudeslogisticascambios/otros',
          },
          transportadores: {
            base: 'wms/solicitudeslogisticastransportadores',
            getTransportadoresNotInSolicitud: 'wms/'
              + 'solicitudeslogisticastransportadores/getTransportadoresNotInSolicitud',
            getGuias: 'wms/solicitudeslogisticastransportadores/getGuias',
            cambioEstado: 'wms/solicitudeslogisticastransportadores/cambioestado',
          },
        },
        transporteTercero: {
          excel: 'reports/transportetercero/excel',
        },
        transaccion: {
          base: 'wms/transaccion',
        },
      },
    },
  },
  centroAyuda: {
    maestros: {
      ayudasGraficas: {
        base: 'centroayuda/ayudasgraficas',
        autocomplete: 'centroayuda/ayudasgraficas/autocomplete',
      },
    },
    procesos: {
      sugerencias: {
        base: 'centroayuda/sugerencias',
        sendExcelToMail: 'centroayuda/sugerencias/sendExcelToMail',
        excel: 'reports/centroayuda/sugerenciaExcel',
      },
      agendarDemostracion: {
        base: 'centroayuda/agendardemostraciones',
        sendExcelToMail: 'centroayuda/agendardemostraciones/sendExcelToMail',
        excel: 'reports/centroayuda/agendarDemostracionExcel',
      },
      enviarPreguntas: {
        base: 'centroayuda/enviarpreguntas',
        cambioEstado: 'centroayuda/enviarpreguntas/cambioEstado',
        sendExcelToMail: 'centroayuda/enviarpreguntas/sendExcelToMail',
        excel: 'reports/centroayuda/enviarPreguntaExcel',
      },
      preguntasFrecuentes: {
        base: 'centroayuda/preguntasfrecuentes',
        autocomplete: 'centroayuda/preguntasfrecuentes/autocomplete',
        sendExcelToMail: 'centroayuda/preguntasfrecuentes/sendExcelToMail',
        excel: 'reports/centroayuda/preguntaFrecuenteExcel',
        ayudasGraficas: {
          base: 'centroayuda/preguntasfrecuentesayudasgraficas',
        },
      },
    },
    consultas: {
      preguntasFrecuentes: {
        base: 'centroayuda/preguntasfrecuentes/getpreguntasfrecuentesactivas',
      },
    },
  },
  seguridad: {
    usuarios: {
      base: 'seguridad/usuarios',
      autocomplete: 'seguridad/usuarios/autocomplete',
      autocompleteByCargo: 'seguridad/usuarios/autocompleteByCargo',
      initFormComponent: 'seguridad/usuarios/initForm',
      sendExcelToMail: 'seguridad/usuarios/sendExcelToMail',
      initFormPerfil: 'seguridad/usuarios/perfil/initform',
      basePerfil: 'seguridad/usuarios/perfil',
      permisos: 'seguridad/usuarios/permisos',
      permisosDirectos: 'seguridad/usuarios/permisos/directos',
      password: 'seguridad/usuarios/perfil/password',
      usuariosExcel: 'reports/seguridad/usuariosExcel',
      conectTelegram: 'seguridad/usuarios/conecttelegramchat',
      getRolByUser: 'seguridad/usuarios/getRolByUser',
      roles: {
        base: 'seguridad/userroles',
        assignRoles: 'seguridad/userroles/asignarRoles',
        getRolesNotInUser: 'seguridad/userroles/getRolesNotInUser',
      },
    },
    usuarioImagen: {
      base: 'seguridad/usuarioimagen',
    },
    roles: {
      base: 'seguridad/roles',
      autocomplete: 'seguridad/roles/autocomplete',
      permisos: 'seguridad/roles/permisos',
      sendExcelToMail: 'seguridad/roles/sendExcelToMail',
      storePermissionsByGrupo: 'seguridad/roles/storePermissionsByGrupo',
      rolesExcel: 'reports/seguridad/rolesExcel',
    },
    permisos: {
      byRole: 'seguridad/permisos/byRole',
      byUser: 'seguridad/permisos/byUser',
      autocomplete: 'seguridad/permisos/autocomplete',
    },
    grupoPermisos: {
      base: 'seguridad/grupopermisos',
      autocomplete: 'seguridad/grupopermisos/autocomplete',
      sendExcelToMail: 'seguridad/grupopermisos/sendExcelToMail',
      initFormComponent: 'seguridad/grupopermisos/initFormComponent',
      gruposPermisosExcel: 'reports/seguridad/gruposPermisosExcel',
    },
    grupoPermisosPermisos: {
      base: 'seguridad/grupopermisospermisos',
      getPermissionsNotInGrupo: 'seguridad/grupopermisospermisos/getPermissionsNotInGrupo',
    },
  },
  venta: {
    consultas: {
      base: 'venta/graficasventa',
      facturas: {
        excel: 'reports/facturas/consultafacturaexcel',
        productosFacturas: {
          base: 'venta/facturasproductos/getproductosfacturados',
        },
      },
      pedidosPendientes: {
        base: 'venta/pedidosconsulta',
        excel: 'reports/pedidosconsulta/excel',
      },
      pedidos: {
        excel: 'reports/pedidosconsulta/pedidosventadirectaexcel',
      },
      ventasProductos: {
        base: 'venta/facturasproductos/ventaproductoconsulta',
        excel: 'reports/ventaproducto/excel',
      },
    },
    maestros: {
      cajasRegistradoras: {
        base: 'venta/cajasregistradoras',
        init: 'venta/cajasregistradoras/initformcomponent',
        empleados: {
          base: 'venta/cajaregistradoraempleado',
          notIn: 'venta/cajaregistradoraempleado/empleadosnotincaja',
        },
      },
      clasificacionServicio: {
        base: 'venta/clasificacionesservicios',
        autocomplete: 'venta/clasificacionesservicios/autocomplete',
        sendExcelToMail: 'venta/clasificacionesservicios/sendExcelToMail',
        clasifiacionesServiciosExcel: 'reports/venta/clasifiacionesServiciosExcel',
      },
      consecutivoVenta: {
        base: 'venta/consecutivos',
        consecutivosVentasExcel: 'reports/consecutivos/excel',
      },
      documentoVenta: {
        base: 'venta/documentosventas',
        initFormComponent: 'venta/documentosventas/initFormComponent',
        autocomplete: 'venta/documentosventas/autocomplete',
        documentosVentaExcel: 'reports/venta/documentosVentaExcel',
      },
      operacionesDian: {
        base: 'venta/operacionesDian',
        autocomplete: 'venta/operacionesDian/autocomplete',
        sendExcelToMail: 'venta/operacionesDian/sendExcelToMail',
        operacionesDianExcel: 'reports/venta/operacionesDianExcel',
      },
      servicio: {
        base: 'venta/servicios',
        autocomplete: 'venta/servicios/autocomplete',
        sendExcelToMail: 'venta/servicios/sendExcelToMail',
        initFormComponent: 'venta/servicios/initformcomponent',
        empleado: 'venta/serviciosempleados',
        getServicioEmpleado: 'venta/serviciosempleados/getservicioempleado',
        costo: 'venta/servicioscostos',
        getServicioCosto: 'venta/servicioscostos/getserviciocosto',
        editFieldColumn: 'venta/servicioscostos/editfieldcolumn',
        serviciosExcel: 'reports/venta/serviciosExcel',
      },
      serviciosRetenciones: {
        base: 'venta/serviciosretenciones',
        getRetencionesNotInServicio: 'venta/serviciosretenciones/getRetencionesNotInServicio',
      },
      serviciosImpuestos: {
        base: 'venta/serviciosimpuestos',
        getImpuestosNotInServicio: 'venta/serviciosimpuestos/getImpuestosNotInServicio',
      },
      descuentos: {
        base: 'venta/descuentos',
        excel: 'reports/venta/descuentoexcel',
        autocomplete: 'venta/descuentos/autocomplete',
      },
      puntosVentas: {
        base: 'venta/puntoventa',
        init: 'venta/puntoventa/initformcomponent',
        cajasRegistradoras: {
          base: 'venta/puntoventacajaregistradora',
          notIn: 'venta/puntoventacajaregistradora/getcajasnotinpunto',
        },
        empleados: {
          base: 'venta/puntoventaempleado',
          notIn: 'venta/puntoventaempleado/getempleadosnotinpunto',
        },
      },
    },
    procesos: {
      apiMisFacturas: {
        getDocumentStatus: 'venta/ApiMisFacturas/getDocumentStatus'
      },
      factura: {
        base: 'venta/facturas',
        sendExcelToMail: 'venta/facturas/sendExcelToMail',
        sendExcelToMailPteFactura: 'venta/facturas/sendExcelToMailPteFactura',
        getFacturasByCliente: 'venta/facturas/getFacturasByCliente',
        generateFactura: 'venta/facturas/generateFactura',
        getTotalesByCliente: 'venta/facturas/gettotalesbycliente',
        initFormComponent: 'venta/facturas/initFormComponent',
        initFormFacturaPendiente: 'venta/facturas/initFormFacturaPendiente',
        cambioEstado: 'venta/facturas/cambioestado',
        pdf: 'impresion/pdf/print/factura',
        report: 'reports/facturas/excel',
        getCodigosOperacion: 'venta/facturas/getCodigosOperacion',
      },
      facturaProductos: {
        base: 'venta/facturasproductos',
        excel: 'reports/facturas/facturaProductoExcel',
        getProductosNotInFactura: 'venta/facturasproductos/getProductoNotInFacturaByCliente',
        getInfoPedidoCirugia: 'venta/facturasproductos/getInfoPedidoCirugia',
        getInfoPedidoCirugiaExcel: 'reports/facturas/facturaReporteGastoExcel',
        getInfoPedidoCirugiaUsos: 'venta/facturasproductos/getInfoPedidoCirugiaUsos',
        getInfoPedidoCirugiaUsoExcel: 'reports/facturas/facturaReporteUsoExcel',
        baseTributo: 'venta/facturasproductos/tributo',
        initFormcomponent: 'venta/facturasproductos/initFormComponent',
      },
      facturaProductosExistencias: {
        base: 'venta/facturasproductosexistencias',
        getExistenciasNotInFacturaProducto: 'venta/facturasproductosexistencias/getExistenciasNotInFacturaProducto',
      },
      facturaPedidos: {
        base: 'venta/facturaspedidos',
      },
      facturaImpuestos: {
        base: 'venta/facturaimpuestos',
        getImpuestosNotInFactura: 'venta/facturaimpuestos/getImpuestosNotInFactura',
      },
      facturaRetenciones: {
        base: 'venta/facturasretenciones',
        getRetencionesNotInFactura: 'venta/facturasretenciones/getRetencionesNotInFactura',
      },
      facturaRetencionesSugeridas: {
        base: 'venta/facturasretencionessugeridas',
      },
      facturaProductosImpuestos: {
        base: 'venta/facturasproductosimpuestos',
        getImpuestosNotInFacturaProducto: 'venta/facturasproductosimpuestos/getImpuestosNotInFacturaProducto',
      },
      facturaProductosRetenciones: {
        base: 'venta/facturasproductosretenciones',
        getRetencionesNotInFacturaProducto: 'venta/facturasproductosretenciones/getRetencionesNotInFacturaProducto',
      },
      facturaServiciosImpuestos: {
        base: 'venta/facturasserviciosimpuestos',
        getImpuestosNotInFacturaServicio: 'venta/facturasserviciosimpuestos/getImpuestosNotInFacturaServicio',
      },
      facturaServiciosRetenciones: {
        base: 'venta/facturasserviciosretenciones',
        getRetencionesNotInFacturaServicio: 'venta/facturasserviciosretenciones/getRetencionesNotInFacturaServicio',
      },
      facturaAsesores: {
        base: 'venta/facturasasesores',
      },
      facturaServicios: {
        base: 'venta/facturasservicios',
        excel: 'reports/facturas/facturaServicioExcel',
        getServiciosNotInFacturaByCliente: 'venta/facturasservicios/getServiciosNotInFacturaByCliente',
        baseTributo: 'venta/facturasservicios/tributo',
        initFormComponent: 'venta/facturasservicios/initFormComponent',
      },
      // facturaImpuestos: {
      //   base: 'venta/facturasimpuestos',
      //   initFormComponent: 'venta/facturasimpuestos/initFormComponent',
      // },
      generacionFactura: {
        // base: 'venta/generacionfacturas',
        pedidosPendientesFacturacion: 'venta/generacionfacturas/pedidospendientesfacturacion',
        alistamientosPendientesFacturacion: 'venta/generacionfacturas/alistamientospendientesfacturacion',
        vehiculosPendientesFacturacion: 'venta/generacionfacturas/vehiculospendientesfacturacion',
        generarFacturas: 'venta/generacionfacturas/generarfacturas',
      },
      pedido: {
        base: 'venta/pedidos',
        ventaDirecta: 'venta/pedidos/ventaDirecta',
        initFormComponent: 'venta/pedidos/initformcomponent',
        getPedidosByCliente: 'venta/pedidos/pedidosByCliente',
        getPedidosPorFacturar: 'venta/pedidos/getPedidosPorFacturar',
        getPedidosAprobadosByCliente: 'venta/pedidos/getPedidosAprobadosByCliente',
        cambioEstado: 'venta/pedidos/cambioestado',
        cambioEstadoMasivo: 'venta/pedidos/cambioestadomasivo',
        autocomplete: 'venta/pedidos/autocompletePedidos',
        autocompletePedidosByConsecutivo: 'venta/pedidos/autocompletePedidosByConsecutivo',
        getProductosCirugiaByPedidoId: 'venta/pedidos/productoscirugiabypedidoid',
        pdf: 'impresion/pdf/print/pedido',
        wizardPedido: 'rpdental/masterWizard',
        getNoDespachable: 'venta/pedidos/viewInfoNotDespachable',
        sendExcelToMail: 'venta/pedidos/sendExcelToMail',
        sendExcelToMailVentaDirecta: 'venta/pedidos/sendExcelToMailVentaDirecta',
        reportPedidos: 'reports/pedidos/excel',
        reportVentaDirecta: 'reports/ventasdirectas/excel',
        reportPendienteFacturar: 'reports/pedidos/excelpendiente',
        pedidosComposiciones: {
          base: 'venta/pedidoscomposiciones',
        },
      },
      pedidoPredistribuidos: {
        base: 'venta/pedidospredistribuidos',
        initFormPredistribuido: 'venta/pedidospredistribuidos/initformcomponent',
      },
      pedidoProductos: {
        base: 'venta/pedidosproductos',
        excel: 'reports/pedidosproductos/pedidoproductoexcel',
        upload: 'venta/pedidosproductos/upload',
        plantillaCargueMasivo: 'reports/pedidosproductos/plantillaproductopedido',
        initFormProducto: 'venta/pedidosproductos/initformcomponent',
        notInPedido: 'venta/pedidosproductos/productosnotinpedido',
        editFieldColumn: 'venta/pedidosproductos/editfieldcolumn',
        getProductosExtra: 'venta/pedidosproductos/getProductosExtrasByPedidoId',
      },
      pedidoServicios: {
        base: 'venta/pedidoservicios',
        getServiciosNotInPedido: 'venta/pedidoservicios/getServiciosNotInPedido',
      },
      prePedidos: {
        base: 'rpdental/prepedidos',
        cambioEstado: 'rpdental/prepedidos/cambioEstado',
        sendExcelToMail: 'rpdental/prepedidos/sendExcelToMail',
        updatePrepedidoProducto: 'rpdental/prepedidos/updatePrepedidoProducto',
        report: 'reports/prepedidos/excel',
      },
    },
    informes: {
      venta: 'venta/informes/venta',
      factura: 'venta/informes/factura',
      ventaInit: 'venta/informes/informeVentaInitForm',
    },
  },
};

export default endPoints;
